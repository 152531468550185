import useSWR from 'swr'

import {
    ShiftInstance,
    ScheduleService,
    TimekeepingShiftInstance,
    ShiftSplit,
    ShiftInstanceUpdate,
    ShiftInstanceStatus,
    ScheduleOfficer,
    ScheduleShiftInstance,
    ApiError,
} from '../generated'
import { format } from 'date-fns'
import { errorReasonToString } from 'src/utils/errorUtils'
import { toast } from 'react-toastify'

type LoadScheduleFunc = (
    v4ScheduleFlag: boolean,
    startDate?: string,
    endDate?: string,
    officerId?: string | null
) => {
    shiftInstances: TimekeepingShiftInstance[]
    isLoading: boolean
    isError: boolean
}
/*
 * TODO: @atulenko: Leaving called_off_shifts as part of the schedule object until async migration is complete, then will remove
 */
export const useLoadSchedule: LoadScheduleFunc = (
    v4ScheduleFlag,
    startDate,
    endDate,
    officerId
) => {
    const { data, error } = useSWR(
        startDate && endDate && v4ScheduleFlag
            ? officerId
                ? ['schedule', startDate, endDate, officerId]
                : ['schedule', startDate, endDate]
            : null,
        async () => {
            const scheduleData =
                await ScheduleService.getScheduleForDateRangeApiV4ScheduleGet(
                    startDate!,
                    endDate!,
                    officerId
                )
            return scheduleData
        }
    )
    return {
        shiftInstances: data ?? [],
        isLoading: !error && !data,
        isError: error,
    }
}

type RefreshScheduleFunc = (
    startDate: string,
    endDate: string,
    mutate: any
) => Promise<any>
export const refreshSchedule: RefreshScheduleFunc = (
    startDate,
    endDate,
    mutate
) => {
    return mutate(
        ['schedule', startDate, endDate],
        async () => {
            const updatedSchedule =
                await ScheduleService.getScheduleForDateRangeApiV4ScheduleGet(
                    startDate!,
                    endDate!
                )
            return updatedSchedule
        },
        true
    )
}
type AssignOfficerToShiftFunc = (
    officer: Pick<ScheduleOfficer, 'id'> | undefined,
    shiftInstance: ShiftInstance,
    dateRangeStart: Date | undefined,
    dateRangeEnd: Date | undefined,
    mutate: any,
    isShiftOfferAssignment?: boolean
) => Promise<[any, void]> | undefined
export const assignOfficerToShift: AssignOfficerToShiftFunc = (
    officer,
    shiftInstance,
    dateRangeStart,
    dateRangeEnd,
    mutate,
    isShiftOfferAssignment = false
) => {
    if (dateRangeStart === undefined || dateRangeEnd === undefined) {
        return
    }
    const startDate = format(dateRangeStart!, 'yyyy-MM-dd')
    const endDate = format(dateRangeEnd!, 'yyyy-MM-dd')
    return mutate(
        ['schedule', startDate, endDate],
        async (schedule: TimekeepingShiftInstance[]) => {
            let status
            if (officer) {
                // Publish the shift if it is being accepted from an offer
                status = isShiftOfferAssignment
                    ? ShiftInstanceStatus.PUBLISHED
                    : ShiftInstanceStatus.UNPUBLISHED
            } else {
                status = ShiftInstanceStatus.OPEN
            }
            const updatedSchedule =
                await ScheduleService.setShiftInstanceApiV4ScheduleInstancePut(
                    startDate,
                    endDate,
                    {
                        ...shiftInstance,
                        officer_id: officer?.id,
                        status,
                    }
                )

            // if this is a shift offer assignment, we need to invalidate the shift marketplace cache
            if (isShiftOfferAssignment) {
                mutate(
                    [
                        '/marketplace/shift-offer',
                        format(dateRangeStart, 'yyyy-MM-dd'),
                        format(dateRangeEnd, 'yyyy-MM-dd'),
                    ],
                    undefined,
                    true
                )
                mutate(
                    [
                        '/marketplace/pending-assignment-count',
                        format(dateRangeStart, 'yyyy-MM-dd'),
                        format(dateRangeEnd, 'yyyy-MM-dd'),
                    ],
                    undefined,
                    true
                )
            }
            // XXX hacky escape hatch for if we haven't loaded the schedule- all of this should be refactored
            if (!schedule) {
                return []
            }
            // Update the cache for the shift instances updated in this request
            mutate(
                [
                    'schedule-short-details',
                    updatedSchedule.updated_shift_instance.id,
                ],
                updatedSchedule.updated_shift_instance
            )

            const updatedScheduleIds = updatedSchedule.updated_timekeeping.map(
                (shiftInstance) => shiftInstance.id
            )
            const filteredSchedule = schedule.filter(
                (shiftInstance: TimekeepingShiftInstance) =>
                    !updatedScheduleIds.includes(shiftInstance.id)
            )
            return [...updatedSchedule.updated_timekeeping, ...filteredSchedule]
        }
    )
}

type UpdateShiftInstanceFunc = (
    shiftInstance: ShiftInstanceUpdate,
    dateRangeStart: Date | undefined,
    dateRangeEnd: Date | undefined,
    mutate: any
) => Promise<[any, void]> | undefined
export const updateShiftInstance: UpdateShiftInstanceFunc = (
    shiftInstance,
    dateRangeStart,
    dateRangeEnd,
    mutate
) => {
    if (dateRangeStart === undefined || dateRangeEnd === undefined) {
        return
    }
    const startDate = format(dateRangeStart!, 'yyyy-MM-dd')
    const endDate = format(dateRangeEnd!, 'yyyy-MM-dd')
    return mutate(
        ['schedule', startDate, endDate],
        async (schedule: TimekeepingShiftInstance[]) => {
            const updatedSchedule =
                await ScheduleService.setShiftInstanceApiV4ScheduleInstancePut(
                    startDate,
                    endDate,
                    shiftInstance
                )

            // XXX hacky escape hatch for if we haven't loaded the schedule- all of this should be refactored
            if (!schedule) {
                return []
            }

            // Update the cache for the shift instances updated in this request
            mutate(
                [
                    'schedule-short-details',
                    updatedSchedule.updated_shift_instance.id,
                ],
                updatedSchedule.updated_shift_instance
            )

            const updatedScheduleIds = updatedSchedule.updated_timekeeping.map(
                (shiftInstance) => shiftInstance.id
            )
            const filteredSchedule = schedule.filter(
                (shiftInstance: TimekeepingShiftInstance) =>
                    !updatedScheduleIds.includes(shiftInstance.id)
            )
            return [...updatedSchedule.updated_timekeeping, ...filteredSchedule]
        }
    )
}

type DeleteShiftInstanceFunc = (
    shiftInstanceIds: string[],
    dateRangeStart: Date | undefined,
    dateRangeEnd: Date | undefined,
    mutate: any
) => Promise<any>
export const deleteShiftInstancesByIds: DeleteShiftInstanceFunc = (
    shiftInstanceIds,
    dateRangeStart,
    dateRangeEnd,
    mutate
) => {
    if (dateRangeStart === undefined || dateRangeEnd === undefined) {
        return
    }
    const startDate = format(dateRangeStart!, 'yyyy-MM-dd')
    const endDate = format(dateRangeEnd!, 'yyyy-MM-dd')
    // actually delete and mutate the schedule asset
    return mutate(
        ['schedule', startDate, endDate],
        async (schedule: TimekeepingShiftInstance[]) => {
            await ScheduleService.deleteShiftInstanceIdsApiV4ScheduleInstancesDelete(
                startDate,
                endDate,
                shiftInstanceIds
            )
            // invalidate the tk period view asset where this is also used
            mutate(
                [`/timecards/`, startDate, endDate, startDate, endDate],
                undefined,
                true
            )
            // XXX hacky escape hatch for if we haven't loaded the schedule- all of this should be refactored
            if (!schedule) {
                return []
            }
            const filteredSchedule = schedule.filter(
                (shiftInstance: TimekeepingShiftInstance) =>
                    !shiftInstanceIds.includes(shiftInstance.id)
            )
            return filteredSchedule
        }
    )
}

type UpdateScheduleFunc = (
    shiftInstances: ScheduleShiftInstance[],
    startDate: string,
    endDate: string,
    mutate: any
) => Promise<TimekeepingShiftInstance[]>
export const updateSchedule: UpdateScheduleFunc = (
    shiftInstances,
    startDate,
    endDate,
    mutate
): Promise<TimekeepingShiftInstance[]> => {
    const shiftInstanceUpdates = shiftInstances.map((shiftInstance) => {
        return {
            ...shiftInstance,
            officer_id: shiftInstance.officer
                ? shiftInstance.officer.id
                : undefined,
        }
    })
    return mutate(
        ['schedule', startDate, endDate],
        async (schedule: TimekeepingShiftInstance[]) => {
            const updatedSchedule =
                await ScheduleService.setScheduledOfficersApiV4SchedulePut(
                    startDate,
                    endDate,
                    shiftInstanceUpdates
                )

            // XXX hacky escape hatch for if we haven't loaded the schedule- all of this should be refactored
            if (!schedule) {
                return []
            }
            const updatedScheduleIds = updatedSchedule.map(
                (shiftInstance) => shiftInstance.id
            )
            const filteredSchedule = schedule.filter(
                (shiftInstance: TimekeepingShiftInstance) =>
                    !updatedScheduleIds.includes(shiftInstance.id)
            )
            return [...updatedSchedule, ...filteredSchedule]
        }
    )
}

type BulkEditTypeFunc = (
    shiftInstances: ShiftInstanceUpdate[],
    startDate: string,
    endDate: string,
    mutate: any
) => Promise<ShiftInstanceUpdate[]>
export const bulkEditSchedule: BulkEditTypeFunc = (
    shiftInstances,
    startDate,
    endDate,
    mutate
): Promise<ShiftInstanceUpdate[]> => {
    return mutate(
        ['schedule', startDate, endDate],
        async (schedule: TimekeepingShiftInstance[]) => {
            const updatedSchedule =
                await ScheduleService.bulkSetInstanceDetailsApiV4BulkSchedulePut(
                    startDate,
                    endDate,
                    shiftInstances
                )
            const updatedScheduleIds = updatedSchedule.map(
                (shiftInstance) => shiftInstance.id
            )
            const filteredSchedule =
                schedule?.filter(
                    (shiftInstance: TimekeepingShiftInstance) =>
                        !updatedScheduleIds.includes(shiftInstance.id)
                ) ?? []
            mutate(
                ['/marketplace/shift-offer', startDate, endDate],
                undefined,
                true
            )
            return [...updatedSchedule, ...filteredSchedule]
        }
    )
}

type SplitShiftFunc = (
    shiftInstance: ShiftInstance,
    dateRangeStart: Date | undefined,
    dateRangeEnd: Date | undefined,
    shiftSplits: ShiftSplit[],
    mutate: any
) => Promise<any>
export const splitShift: SplitShiftFunc = (
    shiftInstance,
    dateRangeStart,
    dateRangeEnd,
    shiftSplits,
    mutate
) => {
    const startDate = format(dateRangeStart!, 'yyyy-MM-dd')
    const endDate = format(dateRangeEnd!, 'yyyy-MM-dd')

    return mutate(
        ['schedule', startDate, endDate],
        async (schedule: TimekeepingShiftInstance[]) => {
            try {
                const updatedSchedule =
                    await ScheduleService.splitShiftApiV4ScheduleInstanceShiftIdTargetDateIndexPut(
                        shiftInstance.shift_id,
                        shiftInstance.date,
                        shiftInstance.index ?? 0,
                        startDate,
                        endDate,
                        shiftSplits
                    )
                const updatedScheduleIds =
                    updatedSchedule.updated_timekeeping.map(
                        (shiftInstance) => shiftInstance.id
                    )

                // Update the cache for the shift instances updated in this request
                mutate(
                    [
                        'schedule-short-details',
                        updatedSchedule.updated_shift_instance.id,
                    ],
                    updatedSchedule.updated_shift_instance
                )

                const filteredSchedule = schedule.filter(
                    (shiftInstance: TimekeepingShiftInstance) =>
                        !updatedScheduleIds.includes(shiftInstance.id)
                )
                return [
                    ...updatedSchedule.updated_timekeeping,
                    ...filteredSchedule,
                ]
            } catch (error) {
                if (error instanceof ApiError) {
                    toast.error(errorReasonToString(error))
                } else {
                    toast.error('Error splitting shift')
                }
                return schedule
            }
        }
    )
}
