/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuditRecordCheckpoint } from '../models/AuditRecordCheckpoint';
import type { Checkpoint } from '../models/Checkpoint';
import type { CheckpointCreate } from '../models/CheckpointCreate';
import type { CheckpointLocationUpdate } from '../models/CheckpointLocationUpdate';
import type { CheckpointUpdate } from '../models/CheckpointUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CheckpointService {

    /**
     * Read Checkpoint Audit Record
     * Return the checkpoint for a given checkpoint_id.
     * @param checkpointId
     * @returns AuditRecordCheckpoint Successful Response
     * @throws ApiError
     */
    public static readCheckpointAuditRecordApiV1CheckpointAuditRecordCheckpointIdGet(
        checkpointId: string,
    ): CancelablePromise<AuditRecordCheckpoint> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/checkpoint-audit-record/{checkpoint_id}',
            path: {
                'checkpoint_id': checkpointId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Checkpoints
     * Return the checkpoints for a customer.
     * @param customerId
     * @returns Checkpoint Successful Response
     * @throws ApiError
     */
    public static readCheckpointsApiV1CheckpointsCustomerIdGet(
        customerId: string,
    ): CancelablePromise<Array<Checkpoint>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/checkpoints/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Checkpoint
     * @param customerId
     * @param requestBody
     * @returns Checkpoint Successful Response
     * @throws ApiError
     */
    public static createCheckpointApiV1CheckpointsCustomerIdPost(
        customerId: string,
        requestBody: CheckpointCreate,
    ): CancelablePromise<Checkpoint> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/checkpoints/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Checkpoint
     * Return the checkpoints for a customer.
     * @param customerId
     * @param checkpointId
     * @returns Checkpoint Successful Response
     * @throws ApiError
     */
    public static readCheckpointApiV1CheckpointsCustomerIdCheckpointIdGet(
        customerId: string,
        checkpointId: string,
    ): CancelablePromise<Checkpoint> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/checkpoints/{customer_id}/{checkpoint_id}',
            path: {
                'customer_id': customerId,
                'checkpoint_id': checkpointId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Checkpoint
     * @param customerId
     * @param checkpointId
     * @param requestBody
     * @returns Checkpoint Successful Response
     * @throws ApiError
     */
    public static updateCheckpointApiV1CheckpointsCustomerIdCheckpointIdPut(
        customerId: string,
        checkpointId: string,
        requestBody: CheckpointUpdate,
    ): CancelablePromise<Checkpoint> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/checkpoints/{customer_id}/{checkpoint_id}',
            path: {
                'customer_id': customerId,
                'checkpoint_id': checkpointId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Checkpoint
     * @param customerId
     * @param checkpointId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteCheckpointApiV1CheckpointsCustomerIdCheckpointIdDelete(
        customerId: string,
        checkpointId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/checkpoints/{customer_id}/{checkpoint_id}',
            path: {
                'customer_id': customerId,
                'checkpoint_id': checkpointId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Checkpoint Location And Tag Id
     * @param checkpointId
     * @param requestBody
     * @returns Checkpoint Successful Response
     * @throws ApiError
     */
    public static updateCheckpointLocationAndTagIdApiV1CheckpointsCheckpointIdPut(
        checkpointId: string,
        requestBody: CheckpointLocationUpdate,
    ): CancelablePromise<Checkpoint> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/checkpoints/{checkpoint_id}',
            path: {
                'checkpoint_id': checkpointId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
