/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Checkpoint } from './Checkpoint';
import type { Contact } from './Contact';
import type { Contract } from './Contract';
import type { CustomerStatus } from './CustomerStatus';
import type { HighLevelOfficer } from './HighLevelOfficer';
import type { PostalAddress } from './PostalAddress';
import type { PostOrder } from './PostOrder';
import type { Shift } from './Shift';
import type { Tour } from './Tour';

export type Customer = {
    name: string;
    address?: string;
    postal_address?: PostalAddress;
    billing_name?: string;
    billing_address?: PostalAddress;
    us_state?: string;
    phone_number?: string;
    region_id?: string;
    status?: CustomerStatus;
    is_archived?: boolean;
    checkhq_id?: string;
    parent_id?: string;
    parent_billing_enabled?: boolean;
    type?: Customer.type;
    block_mobile_clock_in?: boolean;
    id: string;
    firm_id: string;
    contacts?: Array<Contact>;
    contract?: Contract;
    shifts?: Array<Shift>;
    post_order?: PostOrder;
    checkpoints?: Array<Checkpoint>;
    tours?: Array<Tour>;
    supervisors?: Array<HighLevelOfficer>;
    iana_timezone?: string;
};

export namespace Customer {

    export enum type {
        SITE = 'SITE',
        PATROL = 'PATROL',
    }


}

