/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type BreakTimingUserRuleRelativeTime = {
    shift_start_or_shift_end: BreakTimingUserRuleRelativeTime.shift_start_or_shift_end;
    before_or_after: BreakTimingUserRuleRelativeTime.before_or_after;
    comparison_operator: BreakTimingUserRuleRelativeTime.comparison_operator;
    hours: number;
    logical_operator: BreakTimingUserRuleRelativeTime.logical_operator;
};

export namespace BreakTimingUserRuleRelativeTime {

    export enum shift_start_or_shift_end {
        SHIFT_START = 'SHIFT_START',
        SHIFT_END = 'SHIFT_END',
    }

    export enum before_or_after {
        BEFORE = 'BEFORE',
        AFTER = 'AFTER',
    }

    export enum comparison_operator {
        GREATER = 'GREATER',
        GREATER_OR_EQUAL = 'GREATER_OR_EQUAL',
        LESS = 'LESS',
        LESS_OR_EQUAL = 'LESS_OR_EQUAL',
    }

    export enum logical_operator {
        AND = 'AND',
        OR = 'OR',
    }


}

