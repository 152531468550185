/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PaymentAccountUpdate = {
    name: string;
    description?: string;
    pass_through_fees_enabled?: boolean;
    is_payments_acceptance_enabled?: boolean;
    status?: PaymentAccountUpdate.status;
};

export namespace PaymentAccountUpdate {

    export enum status {
        ACTIVE = 'Active',
        PENDING = 'Pending',
        ARCHIVED = 'Archived',
    }


}

