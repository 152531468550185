/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TimeDifferential } from '../models/TimeDifferential';
import type { TimeDifferentialCreate } from '../models/TimeDifferentialCreate';
import type { TimeDifferentialUpdate } from '../models/TimeDifferentialUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TimeDifferentialService {

    /**
     * Create Time Differential
     * @param referenceId
     * @param requestBody
     * @returns TimeDifferential Successful Response
     * @throws ApiError
     */
    public static createTimeDifferentialApiV1TimeDifferentialReferenceIdPost(
        referenceId: string,
        requestBody: TimeDifferentialCreate,
    ): CancelablePromise<TimeDifferential> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/time_differential/{reference_id}',
            path: {
                'reference_id': referenceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Time Differentials
     * @param referenceId
     * @returns TimeDifferential Successful Response
     * @throws ApiError
     */
    public static getTimeDifferentialsApiV1TimeDifferentialReferenceIdGet(
        referenceId: string,
    ): CancelablePromise<Array<TimeDifferential>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/time_differential/{reference_id}',
            path: {
                'reference_id': referenceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Time Differential
     * @param timeDifferentialId
     * @param requestBody
     * @returns TimeDifferential Successful Response
     * @throws ApiError
     */
    public static updateTimeDifferentialApiV1TimeDifferentialTimeDifferentialIdUpdatePut(
        timeDifferentialId: string,
        requestBody: TimeDifferentialUpdate,
    ): CancelablePromise<TimeDifferential> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/time_differential/{time_differential_id}/update',
            path: {
                'time_differential_id': timeDifferentialId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Time Differential
     * @param timeDifferentialId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteTimeDifferentialApiV1TimeDifferentialTimeDifferentialIdDelete(
        timeDifferentialId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/time_differential/{time_differential_id}',
            path: {
                'time_differential_id': timeDifferentialId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
