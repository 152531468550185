/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EmployeeProfile } from './EmployeeProfile';

export type EmployeeActionEEO1 = {
    status?: EmployeeActionEEO1.status;
    completed_ts?: string;
    form_type?: EmployeeActionEEO1.form_type;
    profile: (EmployeeProfile | null);
    id: string;
};

export namespace EmployeeActionEEO1 {

    export enum status {
        NOT_STARTED = 'NOT_STARTED',
        IN_PROGRESS = 'IN_PROGRESS',
        SUBMITTED = 'SUBMITTED',
        COMPLETE = 'COMPLETE',
        CANCELLED = 'CANCELLED',
    }

    export enum form_type {
        I9 = 'I9',
        W4 = 'W4',
        WOTC = 'WOTC',
        PROFILE = 'PROFILE',
        AVAILABILITY = 'AVAILABILITY',
        EEO1 = 'EEO1',
    }


}

