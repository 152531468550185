/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountsPayableInvoice } from '../models/AccountsPayableInvoice';
import type { AccountsPayableInvoiceCreateWithLineItems } from '../models/AccountsPayableInvoiceCreateWithLineItems';
import type { AccountsPayableInvoiceUpdate } from '../models/AccountsPayableInvoiceUpdate';
import type { AccountsPayableInvoiceWithLineItemsAndPayments } from '../models/AccountsPayableInvoiceWithLineItemsAndPayments';
import type { FileCreate } from '../models/FileCreate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AccountsPayableInvoiceService {

    /**
     * Create Invoice
     * Create a new invoice.
     *
     * Args:
     * invoice_create (schemas.AccountsPayableInvoiceCreate): Invoice details to create.
     * db (AsyncSession): Database session.
     * admin_user (schemas.AdminUser): Admin user.
     * Returns:
     * schemas.AccountsPayableInvoice: The created invoice object.
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createInvoiceApiV1AccountspayableInvoicePost(
        requestBody: AccountsPayableInvoiceCreateWithLineItems,
    ): CancelablePromise<(AccountsPayableInvoice | null)> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/accountspayable/invoice',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Invoice
     * Get invoice by ID.
     *
     * Args:
     * invoice_id (UUID): Unique identifier of the invoice.
     * db (AsyncSession): Database session.
     *
     * Returns:
     * schemas.AccountsPayableInvoice: The invoice object.
     *
     * Raises:
     * HTTPException: If the invoice is not found (404).
     * @param invoiceId
     * @returns AccountsPayableInvoiceWithLineItemsAndPayments Successful Response
     * @throws ApiError
     */
    public static readInvoiceApiV1AccountspayableInvoiceInvoiceIdGet(
        invoiceId: string,
    ): CancelablePromise<AccountsPayableInvoiceWithLineItemsAndPayments> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/accountspayable/invoice/{invoice_id}',
            path: {
                'invoice_id': invoiceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Invoice
     * Update invoice by ID.
     *
     * Args:
     * invoice_id (UUID): Unique identifier of the invoice.
     * invoice_update (schemas.AccountsPayableInvoiceUpdate): Updated invoice details.
     * db (AsyncSession): Database session.
     *
     * Returns:
     * schemas.AccountsPayableInvoice: The updated invoice object.
     *
     * Raises:
     * HTTPException: If the invoice is not found (404) or line item not found (404). If there is a lack of updating officer ID (403).
     * @param invoiceId
     * @param requestBody
     * @returns AccountsPayableInvoice Successful Response
     * @throws ApiError
     */
    public static updateInvoiceApiV1AccountspayableInvoiceInvoiceIdPut(
        invoiceId: string,
        requestBody: AccountsPayableInvoiceUpdate,
    ): CancelablePromise<AccountsPayableInvoice> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/accountspayable/invoice/{invoice_id}',
            path: {
                'invoice_id': invoiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Invoice
     * Delete invoice by ID.
     *
     * Args:
     * invoice_id (UUID): Unique identifier of the invoice.
     * db (AsyncSession): Database session.
     *
     * Returns:
     * UUID: The invoice ID.
     *
     * Raises:
     * HTTPException: If the invoice is not found (404).
     * @param invoiceId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static deleteInvoiceApiV1AccountspayableInvoiceInvoiceIdDelete(
        invoiceId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/accountspayable/invoice/{invoice_id}',
            path: {
                'invoice_id': invoiceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Post Invoice
     * Post invoice by ID.
     *
     * Args:
     * invoice_id (UUID): Unique identifier of the invoice.
     * db (AsyncSession): Database session.
     *
     * Returns:
     * UUID: The invoice ID.
     * @param invoiceId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static postInvoiceApiV1AccountspayableInvoiceInvoiceIdPostPost(
        invoiceId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/accountspayable/invoice/{invoice_id}/post',
            path: {
                'invoice_id': invoiceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Bulk Post Invoices
     * @param requestBody
     * @returns string Successful Response
     * @throws ApiError
     */
    public static bulkPostInvoicesApiV1AccountspayableInvoicePostPost(
        requestBody: Array<string>,
    ): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/accountspayable/invoice/post',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Bulk Mark As Paid
     * @param requestBody
     * @returns string Successful Response
     * @throws ApiError
     */
    public static bulkMarkAsPaidApiV1AccountspayableInvoicePaidPost(
        requestBody: Array<string>,
    ): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/accountspayable/invoice/paid',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add File To Invoice
     * Add the Uploadcare file to the invoice.
     *
     * Args:
     * invoice_id (UUID): Unique identifier of the invoice.
     * file_in (schemas.FileCreate): File details.
     * payroll_user (schemas.AdminUser): Payroll user.
     *
     * Returns:
     * UUID: The file ID.
     * @param invoiceId
     * @param requestBody
     * @returns string Successful Response
     * @throws ApiError
     */
    public static addFileToInvoiceApiV1AccountspayableInvoiceInvoiceIdFilePost(
        invoiceId: string,
        requestBody: FileCreate,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/accountspayable/invoice/{invoice_id}/file',
            path: {
                'invoice_id': invoiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete File From Invoice
     * @param invoiceId
     * @param fileId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static deleteFileFromInvoiceApiV1AccountspayableInvoiceInvoiceIdFileFileIdDelete(
        invoiceId: string,
        fileId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/accountspayable/invoice/{invoice_id}/file/{file_id}',
            path: {
                'invoice_id': invoiceId,
                'file_id': fileId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Void Invoice
     * Void invoice by ID.
     *
     * Args:
     * invoice_id (UUID): Unique identifier of the invoice.
     * db (AsyncSession): Database session.
     *
     * Returns:
     * UUID. The invoice ID.
     * @param invoiceId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static voidInvoiceApiV1AccountspayableInvoiceInvoiceIdVoidPost(
        invoiceId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/accountspayable/invoice/{invoice_id}/void',
            path: {
                'invoice_id': invoiceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Invoices
     * List all invoices.
     *
     * Args:
     * db (AsyncSession): Database session.
     *
     * Returns:
     * Sequence[AccountsPayableInvoice]: A list of invoice objects.
     * @returns AccountsPayableInvoice Successful Response
     * @throws ApiError
     */
    public static readInvoicesApiV1AccountspayableInvoicesGet(): CancelablePromise<Array<AccountsPayableInvoice>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/accountspayable/invoices',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
