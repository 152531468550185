/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { File } from './File';
import type { MassAnnouncementRecipientInDB } from './MassAnnouncementRecipientInDB';

export type MassAnnouncement = {
    send_email: boolean;
    send_push: boolean;
    send_sms: boolean;
    subject: string;
    content: string;
    group_recipient_type?: MassAnnouncement.group_recipient_type;
    id: string;
    file_id?: string;
    sent_time: string;
    firm_id: string;
    site_ids?: Array<string>;
    role_ids?: Array<string>;
    file?: File;
    recipients: Array<MassAnnouncementRecipientInDB>;
};

export namespace MassAnnouncement {

    export enum group_recipient_type {
        ROLE = 'ROLE',
        SITE = 'SITE',
    }


}

