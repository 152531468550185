/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Holiday } from '../models/Holiday';
import type { HolidayCreate } from '../models/HolidayCreate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class HolidayService {

    /**
     * Read Contract Holidays
     * Return the holidays for a contract
     * @param contractId
     * @returns Holiday Successful Response
     * @throws ApiError
     */
    public static readContractHolidaysApiV1HolidayContractIdGet(
        contractId: string,
    ): CancelablePromise<Array<Holiday>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/holiday/{contract_id}',
            path: {
                'contract_id': contractId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Contract Holiday
     * @param contractId
     * @param requestBody
     * @returns Holiday Successful Response
     * @throws ApiError
     */
    public static createContractHolidayApiV1HolidayContractIdPost(
        contractId: string,
        requestBody: HolidayCreate,
    ): CancelablePromise<Array<Holiday>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/holiday/{contract_id}',
            path: {
                'contract_id': contractId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Contract Standard Holidays
     * @param contractId
     * @param requestBody
     * @returns Holiday Successful Response
     * @throws ApiError
     */
    public static createContractStandardHolidaysApiV1HolidaysMultiContractIdPost(
        contractId: string,
        requestBody: Array<HolidayCreate>,
    ): CancelablePromise<Array<Holiday>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/holidays_multi/{contract_id}',
            path: {
                'contract_id': contractId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Contract Holiday
     * @param contractId
     * @param holidayId
     * @returns Holiday Successful Response
     * @throws ApiError
     */
    public static deleteContractHolidayApiV1HolidayContractIdHolidayIdDelete(
        contractId: string,
        holidayId: string,
    ): CancelablePromise<Array<Holiday>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/holiday/{contract_id}/{holiday_id}',
            path: {
                'contract_id': contractId,
                'holiday_id': holidayId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
