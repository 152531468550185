/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { HighLevelOfficer } from './HighLevelOfficer';
import type { OnboardingAction } from './OnboardingAction';
import type { OnboardingTemplate } from './OnboardingTemplate';

export type OnboardingStatusWithEmployee = {
    employee_id: string;
    status: OnboardingStatusWithEmployee.status;
    onboarding_template_id: string;
    actions?: Array<OnboardingAction>;
    id: string;
    onboarding_template?: OnboardingTemplate;
    completed_ts?: string;
    employee: HighLevelOfficer;
};

export namespace OnboardingStatusWithEmployee {

    export enum status {
        NOT_STARTED = 'NOT_STARTED',
        IN_PROGRESS = 'IN_PROGRESS',
        SUBMITTED = 'SUBMITTED',
        COMPLETE = 'COMPLETE',
        CANCELLED = 'CANCELLED',
    }


}

