/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RunsheetTaskUpdate = {
    type: RunsheetTaskUpdate.type;
    estimated_duration?: number;
    customer_id?: string;
    custom_report_template_id?: string;
    instructions?: string;
    task_details?: string;
    officer_summary?: string;
    reason?: string;
    active?: boolean;
    started_at?: string;
    completed_at?: string;
    id: string;
};

export namespace RunsheetTaskUpdate {

    export enum type {
        REPORT = 'Report',
        SITE_VISIT = 'Site Visit',
        ADMINISTRATIVE = 'Administrative',
    }


}

