/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SupplementalPayBenefit } from '../models/SupplementalPayBenefit';
import type { SupplementalPayBenefitCreate } from '../models/SupplementalPayBenefitCreate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SpbService {

    /**
     * Get Supp Pay Benefits For Customer
     * @param customerId
     * @returns SupplementalPayBenefit Successful Response
     * @throws ApiError
     */
    public static getSuppPayBenefitsForCustomerApiV1SpbGet(
        customerId: string,
    ): CancelablePromise<Array<SupplementalPayBenefit>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/spb',
            query: {
                'customer_id': customerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Supp Pay Benefit For Customer
     * @param customerId
     * @param requestBody
     * @returns SupplementalPayBenefit Successful Response
     * @throws ApiError
     */
    public static createSuppPayBenefitForCustomerApiV1SpbPost(
        customerId: string,
        requestBody: SupplementalPayBenefitCreate,
    ): CancelablePromise<Array<SupplementalPayBenefit>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/spb',
            query: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Supp Pay Benefit For Customer
     * @param customerId
     * @param spbId
     * @param isArchived
     * @returns SupplementalPayBenefit Successful Response
     * @throws ApiError
     */
    public static updateSuppPayBenefitForCustomerApiV1SpbPut(
        customerId: string,
        spbId: string,
        isArchived: boolean,
    ): CancelablePromise<Array<SupplementalPayBenefit>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/spb',
            query: {
                'customer_id': customerId,
                'spb_id': spbId,
                'is_archived': isArchived,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
