/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuditRecordShift } from '../models/AuditRecordShift';
import type { MasterScheduleShiftCreate } from '../models/MasterScheduleShiftCreate';
import type { MasterScheduleShiftUpdate } from '../models/MasterScheduleShiftUpdate';
import type { OneOffShiftCreate } from '../models/OneOffShiftCreate';
import type { ScheduleUpdatePreview } from '../models/ScheduleUpdatePreview';
import type { Shift } from '../models/Shift';
import type { ShiftCreate } from '../models/ShiftCreate';
import type { ShiftListing } from '../models/ShiftListing';
import type { ShiftMutationResponse } from '../models/ShiftMutationResponse';
import type { ShiftPositionAssignment } from '../models/ShiftPositionAssignment';
import type { ShiftUpdate } from '../models/ShiftUpdate';
import type { ShiftWithCustomerName } from '../models/ShiftWithCustomerName';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ShiftService {

    /**
     * Read Shifts Audit Record
     * Return the shifts by given ID.
     * @param shiftId
     * @returns AuditRecordShift Successful Response
     * @throws ApiError
     */
    public static readShiftsAuditRecordApiV1ShiftAuditRecordShiftIdGet(
        shiftId: string,
    ): CancelablePromise<AuditRecordShift> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/shift-audit-record/{shift_id}',
            path: {
                'shift_id': shiftId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Shifts
     * Return the shifts for a customer.
     *
     * If include_past_shifts is True, return all shifts for the customer, else only
     * returns the shifts whose end_date is in the future.
     * @param customerId
     * @param includePastShifts
     * @returns Shift Successful Response
     * @throws ApiError
     */
    public static readShiftsApiV1ShiftCustomerIdGet(
        customerId: string,
        includePastShifts: boolean = false,
    ): CancelablePromise<Array<Shift>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/shift/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            query: {
                'include_past_shifts': includePastShifts,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Shift
     * Creates a shift for a customer.
     *
     * one_off_shift is used to denote a shift that is not part of a master schedule
     * is_training_shift is used to denote a shift that is to be marked as a training shift
     * @param customerId
     * @param requestBody
     * @param oneOffShift
     * @param isTrainingShift
     * @returns Shift Successful Response
     * @throws ApiError
     */
    public static createShiftApiV1ShiftCustomerIdPost(
        customerId: string,
        requestBody: ShiftCreate,
        oneOffShift: boolean = false,
        isTrainingShift: boolean = false,
    ): CancelablePromise<Shift> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/shift/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            query: {
                'one_off_shift': oneOffShift,
                'is_training_shift': isTrainingShift,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read All Shifts
     * Return all the shifts for a firm.
     * @returns ShiftWithCustomerName Successful Response
     * @throws ApiError
     */
    public static readAllShiftsApiV1ShiftsGet(): CancelablePromise<Array<ShiftWithCustomerName>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/shifts',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Shifts With No Runsheet Template For Patrol Route
     * Return shifts with no runsheet template assigned for a patrol route. Also accepts a list of shift ids to include (to handle currently assigned shifts)
     * @param customerId
     * @param requestBody
     * @returns ShiftWithCustomerName Successful Response
     * @throws ApiError
     */
    public static readShiftsWithNoRunsheetTemplateForPatrolRouteApiV1CustomerCustomerIdShiftsNoRunsheetTemplatePost(
        customerId: string,
        requestBody: Array<string>,
    ): CancelablePromise<Array<ShiftWithCustomerName>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/customer/{customer_id}/shifts-no-runsheet-template',
            path: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Shift
     * Returns a specific shift
     * @param customerId
     * @param shiftId
     * @returns Shift Successful Response
     * @throws ApiError
     */
    public static readShiftApiV1ShiftCustomerIdShiftIdGet(
        customerId: string,
        shiftId: string,
    ): CancelablePromise<Shift> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/shift/{customer_id}/{shift_id}',
            path: {
                'customer_id': customerId,
                'shift_id': shiftId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Shift
     * @param customerId
     * @param shiftId
     * @param requestBody
     * @param effectiveDate
     * @param applyHistorically
     * @returns Shift Successful Response
     * @throws ApiError
     */
    public static updateShiftApiV1ShiftCustomerIdShiftIdPut(
        customerId: string,
        shiftId: string,
        requestBody: ShiftUpdate,
        effectiveDate?: (string | null),
        applyHistorically: boolean = false,
    ): CancelablePromise<Shift> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/shift/{customer_id}/{shift_id}',
            path: {
                'customer_id': customerId,
                'shift_id': shiftId,
            },
            query: {
                'effective_date': effectiveDate,
                'apply_historically': applyHistorically,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Shift
     * This is a soft delete endpoint. We want to preserve historical records of shifts so we don't actually delete them.
     * Instead, we set the end_date of the shift to the current date. We also remove all related shift instances that are in the future.
     * @param customerId
     * @param shiftId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteShiftApiV1ShiftCustomerIdShiftIdDelete(
        customerId: string,
        shiftId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/shift/{customer_id}/{shift_id}',
            path: {
                'customer_id': customerId,
                'shift_id': shiftId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Master Schedule Shift
     * @param requestBody
     * @returns ShiftListing Successful Response
     * @throws ApiError
     */
    public static createMasterScheduleShiftApiV1MasterScheduleShiftPost(
        requestBody: MasterScheduleShiftCreate,
    ): CancelablePromise<ShiftListing> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/master_schedule_shift',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Master Schedule Shift
     * @param shiftId
     * @param requestBody
     * @returns ShiftListing Successful Response
     * @throws ApiError
     */
    public static updateMasterScheduleShiftApiV1MasterScheduleShiftShiftIdPatch(
        shiftId: string,
        requestBody: MasterScheduleShiftUpdate,
    ): CancelablePromise<ShiftListing> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/master_schedule_shift/{shift_id}',
            path: {
                'shift_id': shiftId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Master Schedule Shift
     * This is a soft delete endpoint. We want to preserve historical records of shifts so we don't actually delete them.
     * Instead, we set the end_date of the shift to the current date. We also remove all related shift instances that are in the future.
     * @param shiftId
     * @returns ShiftMutationResponse Successful Response
     * @throws ApiError
     */
    public static deleteMasterScheduleShiftApiV1MasterScheduleShiftShiftIdDelete(
        shiftId: string,
    ): CancelablePromise<ShiftMutationResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/master_schedule_shift/{shift_id}',
            path: {
                'shift_id': shiftId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Preview Master Schedule Update
     * @param shiftId
     * @param requestBody
     * @returns ScheduleUpdatePreview Successful Response
     * @throws ApiError
     */
    public static previewMasterScheduleUpdateApiV1MasterScheduleShiftPreviewUpdateShiftIdPost(
        shiftId: string,
        requestBody: MasterScheduleShiftUpdate,
    ): CancelablePromise<ScheduleUpdatePreview> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/master_schedule_shift/preview_update/{shift_id}',
            path: {
                'shift_id': shiftId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Shifts
     * @param customerId
     * @param asOfDate
     * @returns ShiftListing Successful Response
     * @throws ApiError
     */
    public static readShiftsApiV2ShiftGet(
        customerId: string,
        asOfDate?: (string | null),
    ): CancelablePromise<Array<ShiftListing>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/shift',
            query: {
                'customer_id': customerId,
                'as_of_date': asOfDate,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create One Off Shift
     * Creates a one-off shift with specific dates shift for a customer.
     * For shifts on days of week, see POST /master_schedule_shift
     * @param requestBody
     * @returns ShiftMutationResponse Successful Response
     * @throws ApiError
     */
    public static createOneOffShiftApiV2OneOffShiftPost(
        requestBody: OneOffShiftCreate,
    ): CancelablePromise<ShiftMutationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/one_off_shift',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Shift
     * Returns a specific shift
     * @param shiftId
     * @returns ShiftListing Successful Response
     * @throws ApiError
     */
    public static readShiftApiV2ShiftShiftIdGet(
        shiftId: string,
    ): CancelablePromise<ShiftListing> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/shift/{shift_id}',
            path: {
                'shift_id': shiftId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Shift
     * This is a soft delete endpoint. We want to preserve historical records of shifts so we don't actually delete them.
     * Instead, we set the end_date of the shift to the current date. We also remove all related shift instances that are in the future.
     * @param shiftId
     * @returns ShiftMutationResponse Successful Response
     * @throws ApiError
     */
    public static deleteShiftApiV2ShiftShiftIdDelete(
        shiftId: string,
    ): CancelablePromise<ShiftMutationResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v2/shift/{shift_id}',
            path: {
                'shift_id': shiftId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Position
     * @param shiftId
     * @param requestBody
     * @returns ShiftMutationResponse Successful Response
     * @throws ApiError
     */
    public static setPositionApiV2ShiftShiftIdSetPositionPost(
        shiftId: string,
        requestBody: ShiftPositionAssignment,
    ): CancelablePromise<ShiftMutationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/shift/{shift_id}/set_position',
            path: {
                'shift_id': shiftId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
