/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Severity {
    HIGH = 'HIGH',
    MEDIUM = 'MEDIUM',
    LOW = 'LOW',
    NOT_APPLICABLE = 'NOT_APPLICABLE',
}
