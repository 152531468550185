/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ImageQuality } from '../models/ImageQuality';
import type { ImageSize } from '../models/ImageSize';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SigningService {

    /**
     * Sign Url
     * Signs the passed in resource url and redirects to the uploadcare cdn.
     * @param url
     * @param quality
     * @returns any Successful Response
     * @throws ApiError
     */
    public static signUrlApiV1SignGet(
        url: string,
        quality?: (ImageQuality | null),
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/sign',
            query: {
                'url': url,
                'quality': quality,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Signed Url
     * @param url
     * @param quality
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getSignedUrlApiV1SignUrlGet(
        url: string,
        quality?: (ImageQuality | null),
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/sign_url',
            query: {
                'url': url,
                'quality': quality,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Sign Url Custom
     * Signs the passed in resource url and redirects to the uploadcare cdn.
     *
     * Takes in the JWT via a query param to support uploadcares format
     * @param url
     * @param token
     * @returns any Successful Response
     * @throws ApiError
     */
    public static signUrlCustomApiV1SignCustomGet(
        url: string,
        token: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/sign/custom',
            query: {
                'url': url,
                'token': token,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Sign Zendesk Jwt
     * @returns string Successful Response
     * @throws ApiError
     */
    public static signZendeskJwtApiV1SignZendeskGet(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/sign/zendesk',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Signed Url
     * @param url
     * @param size
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getSignedUrlApiV2SignGet(
        url: string,
        size?: (ImageSize | null),
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/sign',
            query: {
                'url': url,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Signed Url Custom
     * Signs the passed in resource url and redirects to the uploadcare cdn.
     *
     * Takes in the JWT via a query param to support uploadcares format
     * @param url
     * @param token
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getSignedUrlCustomApiV2SignCustomGet(
        url: string,
        token: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/sign/custom',
            query: {
                'url': url,
                'token': token,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
