/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_sms_delivery_status_callback_api_v1_sms_delivery_status_callback_post } from '../models/Body_sms_delivery_status_callback_api_v1_sms_delivery_status_callback_post';
import type { Body_sms_hook_api_v1_sms_hook_post } from '../models/Body_sms_hook_api_v1_sms_hook_post';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SmsHookService {

    /**
     * Sms Hook
     * Twilio webhook
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    public static smsHookApiV1SmsHookPost(
        formData: Body_sms_hook_api_v1_sms_hook_post,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/sms_hook',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Sms Delivery Status Callback
     * Twilio webhook for determining if an sms delivery failed
     * @param formData
     * @param officerId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static smsDeliveryStatusCallbackApiV1SmsDeliveryStatusCallbackPost(
        formData: Body_sms_delivery_status_callback_api_v1_sms_delivery_status_callback_post,
        officerId?: (string | null),
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/sms_delivery_status_callback',
            query: {
                'officer_id': officerId,
            },
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
