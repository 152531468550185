/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Item } from '../models/Item';
import type { ItemCreate } from '../models/ItemCreate';
import type { ItemInDB } from '../models/ItemInDB';
import type { ItemUpdate } from '../models/ItemUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ItemService {

    /**
     * Read Items
     * List all items.
     *
     * Args:
     * db (AsyncSession): Database session.
     *
     * Returns:
     * Sequence[Item]: A sequence of item objects.
     * @returns Item Successful Response
     * @throws ApiError
     */
    public static readItemsApiV1InventoryItemsGet(): CancelablePromise<Array<Item>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/inventory/items',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Items Endpoint
     * Create multiple items in one request.
     *
     * Args:
     * items_data (Sequence[schemas.ItemCreate]): Item details to create.
     * db (AsyncSession): Database session.
     *
     * Returns:
     * Sequence[schemas.Item]: The created item objects.
     * @param requestBody
     * @returns ItemInDB Successful Response
     * @throws ApiError
     */
    public static createItemsEndpointApiV1InventoryItemsPost(
        requestBody: Array<ItemCreate>,
    ): CancelablePromise<Array<ItemInDB>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/inventory/items',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Item
     * Get item by ID. This will return the most up-to-date version of the item, no matter where in the version chain it is.
     *
     * Args:
     * item_id (UUID): Unique identifier of the item.
     * db (AsyncSession): Database session.
     *
     * Returns:
     * schemas.Item | None: The item object, or None if not found.
     * @param itemId
     * @returns ItemInDB Successful Response
     * @throws ApiError
     */
    public static readItemApiV1InventoryItemItemIdGet(
        itemId: string,
    ): CancelablePromise<ItemInDB> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/inventory/item/{item_id}',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Item Endpoint
     * Update item by ID. Under the hood, this may create a new version of the item, abstracted away from the user.
     *
     * Args:
     * item_id (UUID): Unique identifier of the item.
     * item_update (schemas.ItemUpdate): Updated item details.
     * db (AsyncSession): Database session.
     * admin_user (schemas.AdminUser): Admin user object.
     *
     * Returns:
     * schemas.Item: The updated item object.
     *
     * Raises:
     * HTTPException: If the item is not found (404).
     * @param itemId
     * @param requestBody
     * @returns ItemInDB Successful Response
     * @throws ApiError
     */
    public static updateItemEndpointApiV1InventoryItemItemIdPatch(
        itemId: string,
        requestBody: ItemUpdate,
    ): CancelablePromise<ItemInDB> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/inventory/item/{item_id}',
            path: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Item Versions
     * Get all versions of an item.
     *
     * Args:
     * item_id (UUID): Unique identifier of the item.
     * db (AsyncSession): Database session.
     *
     * Returns:
     * Sequence[schemas.Item]: A sequence of item objects.
     * @param itemId
     * @returns ItemInDB Successful Response
     * @throws ApiError
     */
    public static readItemVersionsApiV1InventoryItemItemIdVersionsGet(
        itemId: string,
    ): CancelablePromise<Array<ItemInDB>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/inventory/item/{item_id}/versions',
            path: {
                'item_id': itemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
