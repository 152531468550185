/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmployeeProfile } from '../models/EmployeeProfile';
import type { EmployeeProfileCreate } from '../models/EmployeeProfileCreate';
import type { EmployeeProfileUpdate } from '../models/EmployeeProfileUpdate';
import type { OfficerProfile } from '../models/OfficerProfile';
import type { SettingsForEmployeePortal } from '../models/SettingsForEmployeePortal';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EmployeePortalService {

    /**
     * Load Employee Profile
     * @param id
     * @returns OfficerProfile Successful Response
     * @throws ApiError
     */
    public static loadEmployeeProfileApiV1EmployeePortalOfficerProfileIdGet(
        id: string,
    ): CancelablePromise<OfficerProfile> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/employee_portal/officer/profile/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Employee Self Update
     * @param id
     * @param requestBody
     * @returns OfficerProfile Successful Response
     * @throws ApiError
     */
    public static employeeSelfUpdateApiV1EmployeePortalOfficerProfileIdPut(
        id: string,
        requestBody: OfficerProfile,
    ): CancelablePromise<OfficerProfile> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/employee_portal/officer/profile/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Load Employee Self Profile
     * @returns OfficerProfile Successful Response
     * @throws ApiError
     */
    public static loadEmployeeSelfProfileApiV1EmployeePortalOfficerProfileGet(): CancelablePromise<OfficerProfile> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/employee_portal/officer/profile',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Employee Profile
     * Creates the Employee EEO1 Profile for the given employee
     *
     * Employees can be assigned a 'Complete EEO1 Form' task by the back office. This endpoint
     * is hit by employees after creating their EEO1 profile.
     *
     * Query Parameters
     * ----------
     * finish_later : bool
     * if true, signifies that the action should not yet be completed.
     * @param employeeId
     * @param requestBody
     * @param finishLater
     * @returns EmployeeProfile Successful Response
     * @throws ApiError
     */
    public static createEmployeeProfileApiV1EmployeePortalEmployeeEeo1EmployeeIdPost(
        employeeId: string,
        requestBody: EmployeeProfileCreate,
        finishLater: boolean = false,
    ): CancelablePromise<EmployeeProfile> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/employee_portal/employee/eeo1/{employee_id}',
            path: {
                'employee_id': employeeId,
            },
            query: {
                'finish_later': finishLater,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Employee Profile
     * Updates the Employee EEO1 Profile for the given employee
     *
     * Employees can be assigned a 'Complete EEO1 Form' task by the back office. This endpoint
     * is hit by employees to update their existing EEO1 profile.
     *
     * Query Parameters
     * ----------
     * finish_later : bool
     * if true, signifies that the action should not yet be completed.
     * @param employeeId
     * @param requestBody
     * @param finishLater
     * @returns EmployeeProfile Successful Response
     * @throws ApiError
     */
    public static updateEmployeeProfileApiV1EmployeePortalEmployeeEeo1EmployeeIdPut(
        employeeId: string,
        requestBody: EmployeeProfileUpdate,
        finishLater: boolean = false,
    ): CancelablePromise<EmployeeProfile> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/employee_portal/employee/eeo1/{employee_id}',
            path: {
                'employee_id': employeeId,
            },
            query: {
                'finish_later': finishLater,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Load Firm Settings For Employee Portal
     * @returns SettingsForEmployeePortal Successful Response
     * @throws ApiError
     */
    public static loadFirmSettingsForEmployeePortalApiV1EmployeePortalSettingsGet(): CancelablePromise<SettingsForEmployeePortal> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/employee_portal/settings',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
