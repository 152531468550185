/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AssignmentStatus {
    NONE = 'None',
    UPCOMING = 'Upcoming',
    ACTIVE = 'Active',
    COMPLETED = 'Completed',
}
