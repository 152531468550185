/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WageRule } from '../models/WageRule';
import type { WageRuleCreate } from '../models/WageRuleCreate';
import type { WageRuleUpdate } from '../models/WageRuleUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WageRuleService {

    /**
     * Get Wage Rules
     * @returns WageRule Successful Response
     * @throws ApiError
     */
    public static getWageRulesApiV1WageRuleGet(): CancelablePromise<Array<WageRule>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/wage_rule',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Wage Rule
     * @param requestBody
     * @returns WageRule Successful Response
     * @throws ApiError
     */
    public static createWageRuleApiV1WageRulePost(
        requestBody: WageRuleCreate,
    ): CancelablePromise<WageRule> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/wage_rule',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Wage Rule
     * @param wageRuleId
     * @param requestBody
     * @returns WageRule Successful Response
     * @throws ApiError
     */
    public static editWageRuleApiV1WageRuleWageRuleIdPut(
        wageRuleId: string,
        requestBody: WageRuleUpdate,
    ): CancelablePromise<WageRule> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/wage_rule/{wage_rule_id}',
            path: {
                'wage_rule_id': wageRuleId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
