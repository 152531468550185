/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SimpleShiftOfferForOfficer = {
    id: string;
    shift_offer_status: SimpleShiftOfferForOfficer.shift_offer_status;
    shift_instance_id?: string;
};

export namespace SimpleShiftOfferForOfficer {

    export enum shift_offer_status {
        OFFERED = 'OFFERED',
        REOFFERED = 'REOFFERED',
        QUEUED = 'QUEUED',
        CONFIRMED = 'CONFIRMED',
    }


}

