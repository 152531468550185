/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PaymentResponseStatus {
    SUCCESS_WITH_RECEIPT = 'Success With Receipt',
    SUCCESS_NO_RECEIPT = 'Success No Receipt',
    DECLINED_BY_PROCESSOR = 'Declined by Processor',
    DECLINED_TECHNICAL = 'Declined Technical',
    USER_ERROR = 'User Error',
    TECHNICAL_ERROR = 'Technical Error',
    TIMEOUT_ERROR = 'Timeout Error',
    ERROR = 'Error',
}
