/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum BelfryRole {
    ROLE_ADMIN = 'role:admin',
    ROLE_MANAGER = 'role:manager',
    ROLE_SUPERVISOR = 'role:supervisor',
    ROLE_HR = 'role:hr',
    ROLE_FINANCE = 'role:finance',
    ROLE_OFFICER = 'role:officer',
    ROLE_CLIENT = 'role:client',
}
