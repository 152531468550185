/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_send_email_api_v1_email_post } from '../models/Body_send_email_api_v1_email_post';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EmailService {

    /**
     * Send Email
     * Email API to send just email or with attachments
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static sendEmailApiV1EmailPost(
        requestBody: Body_send_email_api_v1_email_post,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/email',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
