/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CheckHQBankAccount = {
    name?: string;
    institution_name?: string;
    account_number_last_four?: string;
    routing_number?: string;
    type: string;
    subtype: CheckHQBankAccount.subtype;
};

export namespace CheckHQBankAccount {

    export enum subtype {
        CHECKING = 'checking',
        SAVINGS = 'savings',
    }


}

