import { ApiError } from '../generated'

export function errorReasonToString(reason: ApiError) {
    try {
        // is it pydantic unprocessable entity error?
        if (
            reason?.status == 422 &&
            reason?.body?.errors &&
            !reason?.body?.detail
        ) {
            const first = reason.body.errors[0]
            return `${first.type}: ${first.loc.join('.')}`
        }

        const { detail } = reason.body
        try {
            // this case is for validation errors
            const { msg } = detail[0]
            // whereas the straight detail case is for other api errors we raise on the backend
            return msg ? msg : detail
        } catch (e) {
            if (detail?.message) {
                return detail.message
            }
            return detail
        }
    } catch (error) {
        return `Error ${reason.toString()}`
    }
}
