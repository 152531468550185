/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CheckHQBankAccount } from './CheckHQBankAccount';

export type CheckHQEmployeeOrContractorBankAccount = {
    id: string;
    employee?: string;
    company?: string;
    contractor?: string;
    status: CheckHQEmployeeOrContractorBankAccount.status;
    raw_bank_account?: CheckHQBankAccount;
    plaid_bank_account?: CheckHQBankAccount;
};

export namespace CheckHQEmployeeOrContractorBankAccount {

    export enum status {
        VALIDATION_PENDING = 'validation_pending',
        VALIDATED = 'validated',
        OWNERSHIP_VERIFIED = 'ownership_verified',
        DISABLED_RECOVERABLE = 'disabled_recoverable',
        DISABLED_IRRECOVERABLE = 'disabled_irrecoverable',
    }


}

