/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SuggestResult } from '../models/SuggestResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SuggestService {

    /**
     * Suggest For Shift
     * Suggest officers for a given shift_instance_id
     * @param shiftInstanceId
     * @returns SuggestResult Successful Response
     * @throws ApiError
     */
    public static suggestForShiftApiV1SuggestForShiftInstanceShiftInstanceIdGet(
        shiftInstanceId: string,
    ): CancelablePromise<Array<SuggestResult>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/suggest_for_shift_instance/{shift_instance_id}',
            path: {
                'shift_instance_id': shiftInstanceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
