/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum WageCategory {
    REG = 'REG',
    OT = 'OT',
    DT = 'DT',
    HOL = 'HOL',
}
