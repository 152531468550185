/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TimeOffRequestPolicy = {
    /**
     * Policy ID
     */
    id: string;
    /**
     * Id of the employee associated with this specific policy mapping
     */
    employee_id: string;
    /**
     * Employer defined name for this policy.
     */
    policy_name: string;
    /**
     * Amount of time off available this period, in seconds.
     */
    balance_per_period: number;
    /**
     * Type of policy: PTO, SICK, or UNPAID.
     */
    policy_type: TimeOffRequestPolicy.policy_type;
    /**
     * Does this policy apply to hourly or salaried workers
     */
    applied_to_hourly: boolean;
};

export namespace TimeOffRequestPolicy {

    /**
     * Type of policy: PTO, SICK, or UNPAID.
     */
    export enum policy_type {
        PTO = 'PTO',
        SICK = 'SICK',
        UNPAID = 'UNPAID',
    }


}

