/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { OfficerForEverifyCaseWithI9 } from './OfficerForEverifyCaseWithI9';

export type EverifyCase = {
    case_number: string;
    case_status?: EverifyCase.case_status;
    case_close_ts?: string;
    id: string;
    officer_id: string;
    created_ts?: string;
    last_update_ts?: string;
    officer: OfficerForEverifyCaseWithI9;
};

export namespace EverifyCase {

    export enum case_status {
        OPEN = 'OPEN',
        CLOSED = 'CLOSED',
    }


}

