/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Region } from '../models/Region';
import type { RegionCreate } from '../models/RegionCreate';
import type { RegionUpdate } from '../models/RegionUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RegionService {

    /**
     * Read User Regions
     * Returns the active users assigned regions.
     * @returns Region Successful Response
     * @throws ApiError
     */
    public static readUserRegionsApiV1RegionUserGet(): CancelablePromise<Array<Region>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/region/user',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Regions
     * Return the regions for a users firm.
     * @returns Region Successful Response
     * @throws ApiError
     */
    public static readRegionsApiV1RegionGet(): CancelablePromise<Array<Region>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/region',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Region
     * @param requestBody
     * @returns Region Successful Response
     * @throws ApiError
     */
    public static createRegionApiV1RegionPost(
        requestBody: RegionCreate,
    ): CancelablePromise<Region> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/region',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Region
     * @param id
     * @returns Region Successful Response
     * @throws ApiError
     */
    public static readRegionApiV1RegionIdGet(
        id: string,
    ): CancelablePromise<Region> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/region/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Region
     * @param id
     * @param requestBody
     * @returns Region Successful Response
     * @throws ApiError
     */
    public static updateRegionApiV1RegionIdPut(
        id: string,
        requestBody: RegionUpdate,
    ): CancelablePromise<Region> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/region/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Region
     * @param id
     * @returns Region Successful Response
     * @throws ApiError
     */
    public static deleteRegionApiV1RegionIdDelete(
        id: string,
    ): CancelablePromise<Region> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/region/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
