/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum EmailType {
    TEST = 'TEST',
    TEST_NO_ATTACHMENT = 'TEST_NO_ATTACHMENT',
    CUSTOM = 'CUSTOM',
    CAR = 'CAR',
    CAR_V2 = 'CAR_V2',
    INCIDENT = 'INCIDENT',
    INVOICE = 'INVOICE',
    OFFICER_CHECKHQ_LINK = 'OFFICER_CHECKHQ_LINK',
    MASS_ANNOUNCEMENT = 'MASS_ANNOUNCEMENT',
    NOTIFICATION = 'NOTIFICATION',
}
