/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { HighLevelOfficerWithQualifications } from './HighLevelOfficerWithQualifications';
import type { ScheduleOfficer } from './ScheduleOfficer';

export type ShiftOfferWithQualifications = {
    filters?: Array<'AVAILABLE' | 'CERTIFIED' | 'NO_OVERTIME' | 'SITE_EXPERIENCE' | 'LOWER_OR_EQUAL_PAY_RATE' | 'BELOW_HOURLY_THRESHOLD' | 'WITHIN_RADIUS'>;
    offer_details?: string;
    offer_reminder?: number;
    sm_recipient_radius_for_offer?: number;
    offer_all_slots?: boolean;
    num_slots_offered?: number;
    id: string;
    shift_offer_status: ShiftOfferWithQualifications.shift_offer_status;
    last_offered_at?: string;
    last_offered_by?: ScheduleOfficer;
    times_offered?: number;
    created_at: string;
    shift_instance_id?: string;
    shift_id?: string;
    shift_start_date_time?: string;
    shift_end_date_time?: string;
    interested_officers?: Array<HighLevelOfficerWithQualifications>;
    offered_officers_from_filter?: Array<HighLevelOfficerWithQualifications>;
    offered_officers_from_manual?: Array<HighLevelOfficerWithQualifications>;
};

export namespace ShiftOfferWithQualifications {

    export enum shift_offer_status {
        OFFERED = 'OFFERED',
        REOFFERED = 'REOFFERED',
        QUEUED = 'QUEUED',
        CONFIRMED = 'CONFIRMED',
    }


}

