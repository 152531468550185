/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Certification } from '../models/Certification';
import type { CertificationCreate } from '../models/CertificationCreate';
import type { CertificationType } from '../models/CertificationType';
import type { CertificationTypeCreate } from '../models/CertificationTypeCreate';
import type { CertificationUpdate } from '../models/CertificationUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CertificationService {

    /**
     * Read Certifications
     * Return the certifications for a users firm.
     * @returns Certification Successful Response
     * @throws ApiError
     */
    public static readCertificationsApiV1CertificationGet(): CancelablePromise<Array<Certification>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/certification',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Certification
     * @param requestBody
     * @returns Certification Successful Response
     * @throws ApiError
     */
    public static createCertificationApiV1CertificationPost(
        requestBody: CertificationCreate,
    ): CancelablePromise<Certification> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/certification',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Certification Types
     * Return the certification types for a users firm.
     * @returns CertificationType Successful Response
     * @throws ApiError
     */
    public static readCertificationTypesApiV1CertificationTypesGet(): CancelablePromise<Array<CertificationType>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/certification/types',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Certification Type
     * @param requestBody
     * @returns CertificationType Successful Response
     * @throws ApiError
     */
    public static createCertificationTypeApiV1CertificationTypesPost(
        requestBody: CertificationTypeCreate,
    ): CancelablePromise<Array<CertificationType>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/certification/types',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Certification
     * @param id
     * @returns Certification Successful Response
     * @throws ApiError
     */
    public static readCertificationApiV1CertificationIdGet(
        id: string,
    ): CancelablePromise<Certification> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/certification/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Certification
     * @param id
     * @param requestBody
     * @returns Certification Successful Response
     * @throws ApiError
     */
    public static updateCertificationApiV1CertificationIdPut(
        id: string,
        requestBody: CertificationUpdate,
    ): CancelablePromise<Certification> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/certification/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Certification
     * @param id
     * @returns Certification Successful Response
     * @throws ApiError
     */
    public static deleteCertificationApiV1CertificationIdDelete(
        id: string,
    ): CancelablePromise<Certification> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/certification/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
