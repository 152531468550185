/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CallOff } from '../models/CallOff';
import type { CallOffCreate } from '../models/CallOffCreate';
import type { CallOffUpdate } from '../models/CallOffUpdate';
import type { CallOffWithCustomerName } from '../models/CallOffWithCustomerName';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CallOffService {

    /**
     * Read Officer Call Offs
     * Return the call_offs for an officer
     * @param officerId
     * @returns CallOffWithCustomerName Successful Response
     * @throws ApiError
     */
    public static readOfficerCallOffsApiV1OfficerCallOffsOfficerIdGet(
        officerId: string,
    ): CancelablePromise<Array<CallOffWithCustomerName>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/officer_call_offs/{officer_id}',
            path: {
                'officer_id': officerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Call Off
     * @param requestBody
     * @returns CallOff Successful Response
     * @throws ApiError
     */
    public static createCallOffApiV1OfficerCallOffPost(
        requestBody: CallOffCreate,
    ): CancelablePromise<CallOff> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/officer_call_off',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Call Off
     * @param id
     * @param requestBody
     * @returns CallOff Successful Response
     * @throws ApiError
     */
    public static editCallOffApiV1OfficerCallOffIdPut(
        id: string,
        requestBody: CallOffUpdate,
    ): CancelablePromise<CallOff> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/officer_call_off/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Call Off
     * @param id
     * @returns CallOff Successful Response
     * @throws ApiError
     */
    public static deleteCallOffApiV1OfficerCallOffIdDelete(
        id: string,
    ): CancelablePromise<CallOff> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/officer_call_off/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
