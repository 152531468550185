/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrganizationDetails } from '../models/OrganizationDetails';
import type { OrganizationRole } from '../models/OrganizationRole';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrganizationService {

    /**
     * Get Organization Roles
     * @returns OrganizationRole Successful Response
     * @throws ApiError
     */
    public static getOrganizationRolesApiV1OrganizationRolesGet(): CancelablePromise<Array<OrganizationRole>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/organization/roles',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Organizations For Employee
     * @returns OrganizationDetails Successful Response
     * @throws ApiError
     */
    public static getOrganizationsForEmployeeApiV1OrganizationsMembersGet(): CancelablePromise<Array<OrganizationDetails>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/organizations/members',
        });
    }

}
