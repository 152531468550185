/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type FirmCreateWithId = {
    name: string;
    org_id: string;
    checkhq_id?: string;
    checkhq_default_sig_id?: string;
    checkhq_processing_period?: string;
    payment_entrypoint?: string;
    payment_onboarding_status?: FirmCreateWithId.payment_onboarding_status;
    hubspot_id?: string;
    billing_email?: string;
    billing_phone_number?: string;
    website?: string;
    billing_address?: string;
    logo_url?: string;
    id: string;
    states_of_operation?: (Array<string> | null);
};

export namespace FirmCreateWithId {

    export enum payment_onboarding_status {
        CREATED = 'Created',
        SUBMITTED = 'Submitted',
        HOLDING = 'Holding',
        UNDER_WRITING = 'UnderWriting',
        BOARDING = 'Boarding',
        DECLINED = 'Declined',
        ACTIVATED = 'Activated',
    }


}

