/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuditRecordContact } from '../models/AuditRecordContact';
import type { Contact } from '../models/Contact';
import type { ContactCreate } from '../models/ContactCreate';
import type { ContactUpdate } from '../models/ContactUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ContactService {

    /**
     * Read Contacts
     * Return the contacts for a customer.
     * @param customerId
     * @param isParent
     * @returns Contact Successful Response
     * @throws ApiError
     */
    public static readContactsApiV2ContactCustomerIdGet(
        customerId: string,
        isParent: boolean = false,
    ): CancelablePromise<Array<Contact>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/contact/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            query: {
                'is_parent': isParent,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Contact
     * @param customerId
     * @param requestBody
     * @param isParent
     * @returns Contact Successful Response
     * @throws ApiError
     */
    public static createContactApiV2ContactCustomerIdPost(
        customerId: string,
        requestBody: ContactCreate,
        isParent: boolean = false,
    ): CancelablePromise<Contact> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/contact/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            query: {
                'is_parent': isParent,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Contact
     * Return the contacts for a customer.
     * @param contactId
     * @returns Contact Successful Response
     * @throws ApiError
     */
    public static readContactApiV2ContactContactIdGet(
        contactId: string,
    ): CancelablePromise<Contact> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/contact/{contact_id}',
            path: {
                'contact_id': contactId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Contact
     * @param contactId
     * @param requestBody
     * @returns Contact Successful Response
     * @throws ApiError
     */
    public static updateContactApiV2ContactContactIdPut(
        contactId: string,
        requestBody: ContactUpdate,
    ): CancelablePromise<Contact> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v2/contact/{contact_id}',
            path: {
                'contact_id': contactId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Contact
     * @param parentOrCustomerId
     * @param contactId
     * @param isParent
     * @returns Contact Successful Response
     * @throws ApiError
     */
    public static deleteContactApiV2ContactParentOrCustomerIdContactIdDelete(
        parentOrCustomerId: string,
        contactId: string,
        isParent: boolean = false,
    ): CancelablePromise<Contact> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v2/contact/{parent_or_customer_id}/{contact_id}',
            path: {
                'parent_or_customer_id': parentOrCustomerId,
                'contact_id': contactId,
            },
            query: {
                'is_parent': isParent,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Contact Audit Record
     * Return the audit record for a given contact.
     * @param contactId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static readContactAuditRecordApiV2ContactAuditRecordContactIdGet(
        contactId: string,
    ): CancelablePromise<(AuditRecordContact | null)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/contact-audit-record/{contact_id}',
            path: {
                'contact_id': contactId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
