/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { License } from '../models/License';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LicenseService {

    /**
     * Read Licenses
     * Return all possible officer licenses
     * @returns License Successful Response
     * @throws ApiError
     */
    public static readLicensesApiV1LicenseGet(): CancelablePromise<Array<License>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/license',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
