/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RateOverride } from '../models/RateOverride';
import type { RateOverrideCreate } from '../models/RateOverrideCreate';
import type { RateOverrideMutationResponse } from '../models/RateOverrideMutationResponse';
import type { RateOverrideUpdate } from '../models/RateOverrideUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RateOverrideService {

    /**
     * Read Officer Rate Overrides
     * Return the rate_overrides for an officer
     * @param officerId
     * @returns RateOverride Successful Response
     * @throws ApiError
     */
    public static readOfficerRateOverridesApiV1OfficerRateOverridesOfficerIdGet(
        officerId: string,
    ): CancelablePromise<Array<RateOverride>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/officer_rate_overrides/{officer_id}',
            path: {
                'officer_id': officerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Rate Override
     * @param requestBody
     * @returns RateOverrideMutationResponse Successful Response
     * @throws ApiError
     */
    public static createRateOverrideApiV1OfficerRateOverridePost(
        requestBody: RateOverrideCreate,
    ): CancelablePromise<RateOverrideMutationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/officer_rate_override',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Rate Override
     * @param id
     * @param requestBody
     * @returns RateOverrideMutationResponse Successful Response
     * @throws ApiError
     */
    public static editRateOverrideApiV1OfficerRateOverrideIdPut(
        id: string,
        requestBody: RateOverrideUpdate,
    ): CancelablePromise<RateOverrideMutationResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/officer_rate_override/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Rate Override
     * @param id
     * @returns RateOverrideMutationResponse Successful Response
     * @throws ApiError
     */
    public static deleteRateOverrideApiV1OfficerRateOverrideIdDelete(
        id: string,
    ): CancelablePromise<RateOverrideMutationResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/officer_rate_override/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
