/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type NonHourlyPayItem = {
    kind: NonHourlyPayItem.kind;
    firm_id: string;
    officer_id: string;
    start_date: string;
    end_date: string;
    amount_cents: number;
    checkhq_payroll_id?: string;
    reimbursement_policy_id?: string;
    customer_id?: string;
    non_customer_workplace_id?: string;
    id?: string;
    /**
     * If set, this non-hourly pay item is associated with a specific pay group
     */
    pay_group_id?: string;
};

export namespace NonHourlyPayItem {

    export enum kind {
        SALARY = 'salary',
        REIMBURSEMENT = 'reimbursement',
        PAYOUT_PTO = 'payout_pto',
        PAYOUT_SICK = 'payout_sick',
    }


}

