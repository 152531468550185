/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EverifyCase } from '../models/EverifyCase';
import type { EverifyCaseCreate } from '../models/EverifyCaseCreate';
import type { EverifyCaseUpdate } from '../models/EverifyCaseUpdate';
import type { OfficerForEverifyCaseWithI9 } from '../models/OfficerForEverifyCaseWithI9';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EverifyService {

    /**
     * Read Officer
     * Returns the Everify Case for officer with ID
     * @param id
     * @returns OfficerForEverifyCaseWithI9 Successful Response
     * @throws ApiError
     */
    public static readOfficerApiV1EverifyEmployeeIdGet(
        id: string,
    ): CancelablePromise<OfficerForEverifyCaseWithI9> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/everify/employee/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Everify Case
     * Returns the Everify Case with ID
     * @param id
     * @returns EverifyCase Successful Response
     * @throws ApiError
     */
    public static readEverifyCaseApiV1EverifyIdGet(
        id: string,
    ): CancelablePromise<EverifyCase> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/everify/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Everify Case
     * Creates an Everify Case
     * @param requestBody
     * @returns EverifyCase Successful Response
     * @throws ApiError
     */
    public static createEverifyCaseApiV1EverifyPost(
        requestBody: EverifyCaseCreate,
    ): CancelablePromise<EverifyCase> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/everify',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Everify Case
     * Updates an Everify case
     * @param everifyCaseId
     * @param requestBody
     * @returns EverifyCase Successful Response
     * @throws ApiError
     */
    public static updateEverifyCaseApiV1EverifyEverifyCaseIdPut(
        everifyCaseId: string,
        requestBody: EverifyCaseUpdate,
    ): CancelablePromise<EverifyCase> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/everify/{everify_case_id}',
            path: {
                'everify_case_id': everifyCaseId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
