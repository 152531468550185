import React from 'react'
import { Grid, CircularProgress } from '@mui/material'

export const Loading = ({ style }: any) => {
    const defaultStyle = { minHeight: '100vh' }

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={style || defaultStyle}
        >
            <Grid item xs={3}>
                <CircularProgress />
            </Grid>
        </Grid>
    )
}
