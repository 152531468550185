/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuditRecordPostOrder } from '../models/AuditRecordPostOrder';
import type { PostOrder } from '../models/PostOrder';
import type { PostOrderCreate } from '../models/PostOrderCreate';
import type { PostOrderUpdate } from '../models/PostOrderUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PostorderService {

    /**
     * Create Post Order
     * @param customerId
     * @param requestBody
     * @returns PostOrder Successful Response
     * @throws ApiError
     */
    public static createPostOrderApiV1PostorderCustomerIdPost(
        customerId: string,
        requestBody: PostOrderCreate,
    ): CancelablePromise<PostOrder> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/postorder/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Post Order
     * Return the postorder for a customer.
     * @param customerId
     * @returns PostOrder Successful Response
     * @throws ApiError
     */
    public static readPostOrderApiV1PostorderCustomerIdGet(
        customerId: string,
    ): CancelablePromise<PostOrder> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/postorder/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Post Order
     * @param customerId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deletePostOrderApiV1PostorderCustomerIdDelete(
        customerId: string,
    ): CancelablePromise<(PostOrder | null)> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/postorder/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Employee Read Post Order
     * @param customerId
     * @returns PostOrder Successful Response
     * @throws ApiError
     */
    public static employeeReadPostOrderApiV1EmployeeCustomerCustomerIdPostOrderGet(
        customerId: string,
    ): CancelablePromise<PostOrder> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/employee/customer/{customer_id}/post-order',
            path: {
                'customer_id': customerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Post Order Audit Record
     * Return the postorder for a give ID.
     * @param postorderId
     * @returns AuditRecordPostOrder Successful Response
     * @throws ApiError
     */
    public static readPostOrderAuditRecordApiV1PostorderAuditRecordPostorderIdGet(
        postorderId: string,
    ): CancelablePromise<AuditRecordPostOrder> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/postorder-audit-record/{postorder_id}',
            path: {
                'postorder_id': postorderId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Post Order
     * @param customerId
     * @param requestBody
     * @returns PostOrder Successful Response
     * @throws ApiError
     */
    public static updatePostOrderApiV1PostorderCustomerIdPut(
        customerId: string,
        requestBody: PostOrderUpdate,
    ): CancelablePromise<PostOrder> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/postorder/{customer_id}/}',
            path: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
