/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Uploadcare resizing docs:
 * https://uploadcare.com/docs/transformations/image/resize-crop/#operation-resize
 */
export enum ImageSize {
    WIDTH_100 = 'width_100',
    WIDTH_1000 = 'width_1000',
    HEIGHT_100 = 'height_100',
    SMART_SQUARE_BORDER = 'smart_square_border',
}
