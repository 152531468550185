/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BulkShiftOfferCreate } from '../models/BulkShiftOfferCreate';
import type { OfficerShiftMarketplaceParams } from '../models/OfficerShiftMarketplaceParams';
import type { ResolveGroupOfferPayload } from '../models/ResolveGroupOfferPayload';
import type { ShiftOffer } from '../models/ShiftOffer';
import type { ShiftOfferCreate } from '../models/ShiftOfferCreate';
import type { ShiftOfferUpdate } from '../models/ShiftOfferUpdate';
import type { ShiftOfferWithQualifications } from '../models/ShiftOfferWithQualifications';
import type { SimpleShiftInstanceForSchedule } from '../models/SimpleShiftInstanceForSchedule';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ShiftOfferService {

    /**
     * Read Shift Offer For Shift Instance
     * @param shiftInstanceId
     * @returns ShiftOfferWithQualifications Successful Response
     * @throws ApiError
     */
    public static readShiftOfferForShiftInstanceApiV1ShiftInstanceShiftInstanceIdShiftOfferGet(
        shiftInstanceId: string,
    ): CancelablePromise<ShiftOfferWithQualifications> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/shift-instance/{shift_instance_id}/shift-offer',
            path: {
                'shift_instance_id': shiftInstanceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Shift Offer For Shift Instance
     * @param shiftInstanceId
     * @param requestBody
     * @returns ShiftOffer Successful Response
     * @throws ApiError
     */
    public static createShiftOfferForShiftInstanceApiV1ShiftInstanceShiftInstanceIdShiftOfferPost(
        shiftInstanceId: string,
        requestBody: ShiftOfferCreate,
    ): CancelablePromise<ShiftOffer> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/shift-instance/{shift_instance_id}/shift-offer',
            path: {
                'shift_instance_id': shiftInstanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Shift Offer For Shift Id Start And End
     * @param shiftId
     * @param shiftStartDateTime
     * @param shiftEndDateTime
     * @param shiftInstanceId
     * @returns ShiftOfferWithQualifications Successful Response
     * @throws ApiError
     */
    public static readShiftOfferForShiftIdStartAndEndApiV1ShiftInstanceShiftIdShiftStartDateTimeShiftEndDateTimeShiftOfferGet(
        shiftId: string,
        shiftStartDateTime: string,
        shiftEndDateTime: string,
        shiftInstanceId: string,
    ): CancelablePromise<Array<ShiftOfferWithQualifications>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/shift-instance/{shift_id}/{shift_start_date_time}/{shift_end_date_time}/shift-offer',
            path: {
                'shift_id': shiftId,
                'shift_start_date_time': shiftStartDateTime,
                'shift_end_date_time': shiftEndDateTime,
            },
            query: {
                'shift_instance_id': shiftInstanceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Shift Instances For Marketplace
     * @param startDate
     * @param endDate
     * @returns any Successful Response
     * @throws ApiError
     */
    public static readShiftInstancesForMarketplaceApiV1MarketplaceShiftInstancesGet(
        startDate: string,
        endDate: string,
    ): CancelablePromise<(Array<SimpleShiftInstanceForSchedule> | null)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/marketplace/shift-instances',
            query: {
                'start_date': startDate,
                'end_date': endDate,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Pending Assignment Count For Date Range
     * @param startDate
     * @param endDate
     * @returns any Successful Response
     * @throws ApiError
     */
    public static readPendingAssignmentCountForDateRangeApiV1MarketplacePendingAssignmentCountGet(
        startDate: string,
        endDate: string,
    ): CancelablePromise<(number | null)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/marketplace/pending-assignment-count',
            query: {
                'start_date': startDate,
                'end_date': endDate,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Shift Offer For Multiple Shift Instances
     * @param requestBody
     * @returns ShiftOffer Successful Response
     * @throws ApiError
     */
    public static createShiftOfferForMultipleShiftInstancesApiV1BulkCreateShiftOffersPost(
        requestBody: BulkShiftOfferCreate,
    ): CancelablePromise<Array<ShiftOffer>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/bulk-create-shift-offers',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Shift Offer
     * @param shiftOfferId
     * @param requestBody
     * @returns ShiftOffer Successful Response
     * @throws ApiError
     */
    public static updateShiftOfferApiV1ShiftOfferShiftOfferIdPut(
        shiftOfferId: string,
        requestBody: ShiftOfferUpdate,
    ): CancelablePromise<ShiftOffer> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/shift-offer/{shift_offer_id}',
            path: {
                'shift_offer_id': shiftOfferId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Send Shift Offer
     * @param shiftOfferId
     * @returns ShiftOffer Successful Response
     * @throws ApiError
     */
    public static sendShiftOfferApiV1ShiftOfferShiftOfferIdPost(
        shiftOfferId: string,
    ): CancelablePromise<ShiftOffer> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/shift-offer/{shift_offer_id}',
            path: {
                'shift_offer_id': shiftOfferId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Shift Offer For Id
     * @param shiftOfferId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static deleteShiftOfferForIdApiV1ShiftOfferShiftOfferIdDelete(
        shiftOfferId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/shift-offer/{shift_offer_id}',
            path: {
                'shift_offer_id': shiftOfferId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Shift Marketplace Settings For Officer
     * @returns OfficerShiftMarketplaceParams Successful Response
     * @throws ApiError
     */
    public static getShiftMarketplaceSettingsForOfficerApiV1ShiftMarketplaceSettingsOfficerGet(): CancelablePromise<OfficerShiftMarketplaceParams> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/shift-marketplace/settings/officer',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Combine Offers
     * @param shiftId
     * @param shiftStartDateTime
     * @param shiftEndDateTime
     * @returns string Successful Response
     * @throws ApiError
     */
    public static combineOffersApiV1ShiftOfferShiftIdShiftStartDateTimeShiftEndDateTimeCombinePost(
        shiftId: string,
        shiftStartDateTime: string,
        shiftEndDateTime: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/shift-offer/{shift_id}/{shift_start_date_time}/{shift_end_date_time}/combine',
            path: {
                'shift_id': shiftId,
                'shift_start_date_time': shiftStartDateTime,
                'shift_end_date_time': shiftEndDateTime,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Shift Instances For Grouping
     * @param shiftOfferId
     * @returns SimpleShiftInstanceForSchedule Successful Response
     * @throws ApiError
     */
    public static readShiftInstancesForGroupingApiV1ShiftOfferShiftOfferIdShiftInstancesGet(
        shiftOfferId: string,
    ): CancelablePromise<Array<SimpleShiftInstanceForSchedule>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/shift-offer/{shift_offer_id}/shift-instances',
            path: {
                'shift_offer_id': shiftOfferId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Resolve Group Offers For Shift Updates
     * @param requestBody
     * @returns string Successful Response
     * @throws ApiError
     */
    public static resolveGroupOffersForShiftUpdatesApiV1ResolveGroupOffersForShiftUpdatesPost(
        requestBody: ResolveGroupOfferPayload,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/resolve-group-offers-for-shift-updates',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
