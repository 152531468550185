/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ActivityType } from './ActivityType';
import type { MediaUpdate } from './MediaUpdate';

export type ActivityReportFiledUpdate = {
    activity_type?: ActivityType;
    description?: string;
    occurred_at?: string;
    title: string;
    location: string;
    media?: Array<MediaUpdate>;
    activity_type_literal?: ActivityReportFiledUpdate.activity_type_literal;
};

export namespace ActivityReportFiledUpdate {

    export enum activity_type_literal {
        ACTIVITY_REPORT_FILED = 'ACTIVITY_REPORT_FILED',
    }


}

