/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClaspEmployeePortalResponse } from '../models/ClaspEmployeePortalResponse';
import type { ClaspEmployerPortalResponse } from '../models/ClaspEmployerPortalResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BenefitsService {

    /**
     * Read Clasp Config For Firm
     * Return the Clasp API configuration for user's firm
     * @returns ClaspEmployerPortalResponse Successful Response
     * @throws ApiError
     */
    public static readClaspConfigForFirmApiV1BenefitsClaspEmployerPortalGet(): CancelablePromise<ClaspEmployerPortalResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/benefits/clasp_employer_portal',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Clasp Config For Member
     * Return the Clasp API configuration for member
     * @returns ClaspEmployeePortalResponse Successful Response
     * @throws ApiError
     */
    public static readClaspConfigForMemberApiV1BenefitsClaspEmployeePortalGet(): CancelablePromise<ClaspEmployeePortalResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/benefits/clasp_employee_portal',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
