/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ShiftOfferMetadata = {
    id: string;
    times_offered: number;
    shift_offer_status: ShiftOfferMetadata.shift_offer_status;
    last_offered_at?: string;
};

export namespace ShiftOfferMetadata {

    export enum shift_offer_status {
        OFFERED = 'OFFERED',
        REOFFERED = 'REOFFERED',
        QUEUED = 'QUEUED',
        CONFIRMED = 'CONFIRMED',
    }


}

