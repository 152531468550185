/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Payment } from '../models/Payment';
import type { PaymentCreate } from '../models/PaymentCreate';
import type { PaymentUpdate } from '../models/PaymentUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AccountsPayablePaymentService {

    /**
     * Create Payment Endpoint
     * Create a new payment.
     *
     * Args:
     * payment_data (schemas.PaymentCreate): Payment details to create.
     * db (AsyncSession): Database session.
     *
     * Returns:
     * schemas.Payment: The created payment object.
     * @param requestBody
     * @returns Payment Successful Response
     * @throws ApiError
     */
    public static createPaymentEndpointApiV1PaymentPost(
        requestBody: PaymentCreate,
    ): CancelablePromise<Payment> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/payment',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Payments
     * List all payments.
     *
     * Args:
     * db (AsyncSession): Database session.
     *
     * Returns:
     * Sequence[Payment]: A sequence of payment objects.
     * @returns Payment Successful Response
     * @throws ApiError
     */
    public static readPaymentsApiV1PaymentsGet(): CancelablePromise<Array<Payment>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payments',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Payments Endpoint
     * Create multiple payments in one request.
     *
     * Args:
     * payments_data (Sequence[schemas.PaymentCreate]): Payment details to create.
     * db (AsyncSession): Database session.
     *
     * Returns:
     * Sequence[schemas.Payment]: The created payment objects.
     * @param requestBody
     * @returns Payment Successful Response
     * @throws ApiError
     */
    public static createPaymentsEndpointApiV1PaymentsPost(
        requestBody: Array<PaymentCreate>,
    ): CancelablePromise<Array<Payment>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/payments',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Payment
     * Get payment by ID. This will return the most up-to-date version of the payment, no matter where in the version chain it is.
     *
     * Args:
     * payment_id (UUID): Unique identifier of the payment.
     * db (AsyncSession): Database session.
     *
     * Returns:
     * schemas.Payment | None: The payment object, or None if not found.
     * @param paymentId
     * @returns Payment Successful Response
     * @throws ApiError
     */
    public static readPaymentApiV1PaymentPaymentIdGet(
        paymentId: string,
    ): CancelablePromise<Payment> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payment/{payment_id}',
            path: {
                'payment_id': paymentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Payment Endpoint
     * Update payment by ID. Under the hood, this may create a new version of the payment, abstracted away from the user.
     *
     * Args:
     * payment_id (UUID): Unique identifier of the payment.
     * payment_update (schemas.PaymentUpdate): Updated payment details.
     * db (AsyncSession): Database session.
     * admin_user (schemas.AdminUser): Admin user object.
     *
     * Returns:
     * schemas.Payment: The updated payment object.
     *
     * Raises:
     * HTTPException: If the payment is not found (404).
     * @param paymentId
     * @param requestBody
     * @returns Payment Successful Response
     * @throws ApiError
     */
    public static updatePaymentEndpointApiV1PaymentPaymentIdPatch(
        paymentId: string,
        requestBody: PaymentUpdate,
    ): CancelablePromise<Payment> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/payment/{payment_id}',
            path: {
                'payment_id': paymentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Payment Versions
     * Get all versions of a payment.
     *
     * Args:
     * payment_id (UUID): Unique identifier of the payment.
     * db (AsyncSession): Database session.
     *
     * Returns:
     * Sequence[schemas.Payment]: A sequence of payment objects.
     * @param paymentId
     * @returns Payment Successful Response
     * @throws ApiError
     */
    public static readPaymentVersionsApiV1PaymentPaymentIdVersionsGet(
        paymentId: string,
    ): CancelablePromise<Array<Payment>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payment/{payment_id}/versions',
            path: {
                'payment_id': paymentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Payments With Invoice
     * List all payments with their associated invoice data.
     * @returns Payment Successful Response
     * @throws ApiError
     */
    public static readPaymentsWithInvoiceApiV1PaymentsFullGet(): CancelablePromise<Array<Payment>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payments/full',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Payments By Invoice Id
     * List all current version payments for an invoice.
     *
     * Args:
     * invoice_id (UUID): Unique identifier of the invoice.
     * db (AsyncSession): Database session.
     *
     * Returns:
     * Sequence[schemas.Payment]: A sequence of payment objects.
     * @param invoiceId
     * @returns Payment Successful Response
     * @throws ApiError
     */
    public static readPaymentsByInvoiceIdApiV1PaymentsInvoiceIdGet(
        invoiceId: string,
    ): CancelablePromise<Array<Payment>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payments/{invoice_id}',
            path: {
                'invoice_id': invoiceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
