import { useLoadSchedule } from 'src/services/schedule_v2'

import { useLoadOfficers } from 'src/services/officer'
import { ScheduleShiftInstance } from 'src/generated'

import { shiftInstanceTransform } from 'src/transformers/shiftInstanceTransformer'

import { useRouter } from 'next/router'
import {
    useLoadSchedule as useLoadScheduleOld,
    useLoadCustomersForSchedule,
} from 'src/services/schedule'
import { usePreviewEligible } from 'src/hooks'
import { Features } from 'src/constants/previewEligibleOrgs'

import { NewShiftInstance } from 'src/types/newShiftInstance'

export function useV4ScheduleFlag() {
    const router = useRouter()

    const isTimekeepingV2PreviewEligible = usePreviewEligible(
        Features.TIMEKEEPING_V2
    )

    const v4ScheduleFlag = !!router.query.v4ScheduleFlag
    return v4ScheduleFlag || isTimekeepingV2PreviewEligible
}

type ScheduleResults = {
    shiftInstances: NewShiftInstance[]
    isScheduleLoading: boolean
    isScheduleError: boolean
}
export function useSchedule(
    startDate?: string,
    endDate?: string,
    officerId?: string
): ScheduleResults {
    const v4ScheduleFlag = useV4ScheduleFlag()
    const {
        schedule,
        isScheduleLoading: isLoadingOld,
        isScheduleError: isErrorOld,
    } = useLoadScheduleOld(startDate, endDate, !!v4ScheduleFlag, officerId)
    const { shiftInstances, isScheduleLoading, isScheduleError } =
        useScheduleNew(!!v4ScheduleFlag, startDate, endDate, officerId)

    if (!!v4ScheduleFlag) {
        return {
            shiftInstances,
            isScheduleLoading,
            isScheduleError,
        }
    }

    return {
        shiftInstances: schedule.shift_schedule,
        isScheduleLoading: isLoadingOld,
        isScheduleError: isErrorOld,
    }
}

type ScheduleResultsNew = {
    shiftInstances: ScheduleShiftInstance[]
    isScheduleLoading: boolean
    isScheduleError: boolean
}

function useScheduleNew(
    v4ScheduleFlag: boolean,
    startDate?: string,
    endDate?: string,
    officerId?: string | null
): ScheduleResultsNew {
    const {
        shiftInstances,
        isLoading: isScheduleLoading,
        isError: isScheduleError,
    } = useLoadSchedule(v4ScheduleFlag, startDate, endDate, officerId)

    const {
        officers,
        isLoading: isOfficersLoading,
        isError: isOfficersError,
    } = useLoadOfficers(/*onlyTerminated=*/ false, /*allOfficers=*/ true)

    const {
        customers,
        isLoading: isCustomerLoading,
        isError: isCustomerError,
    } = useLoadCustomersForSchedule(startDate, endDate)

    if (isScheduleLoading || isOfficersLoading || isCustomerLoading) {
        return {
            shiftInstances: [],
            isScheduleLoading: true,
            isScheduleError: false,
        }
    }

    if (isScheduleError || isOfficersError || isCustomerError) {
        return {
            shiftInstances: [],
            isScheduleLoading: false,
            isScheduleError: true,
        }
    }

    return {
        shiftInstances: shiftInstanceTransform(
            shiftInstances,
            officers,
            customers
        ),
        isScheduleLoading,
        isScheduleError,
    }
}
