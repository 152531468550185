import useSWR from 'swr'
import {
    ActivityReportFiled,
    BelltowerReportType,
    ClientPortalService,
    ConsolidatedActivityReport,
    CustomReport,
    FirmInfoForClientPortal,
    FirmInfoService,
    HighLevelActivity,
    Incident,
    ScheduleShiftInstance,
    ReportFilterParams,
    PaginatedActivityFilter,
    BelltowerReportMetadata,
    ShiftInstanceForClientBelltower,
    BasicCustomer,
    NoticeCreate,
    PaymentService,
    ActivePaymentRequest,
    OneTimePayment,
    PaymentResponse,
    PaymentResponseStatus,
    InvoiceService,
    ReportMetadata,
    PositionLookup,
} from '../src/generated'
import { FunctionStatusType } from '../src/services/sharedTypes'
import { useSWRConfig } from 'swr'
import { errorReasonToString } from '../src/utils/errorUtils'
import { toast } from 'react-toastify'
import { ScopedMutator } from 'swr/dist/types'

type FirmInfoType = {
    firmInfo: FirmInfoForClientPortal | undefined
    isLoading: boolean
    isError: boolean
}

type GetFirmInfoType = () => FirmInfoType
export const useGetFirmInfo: GetFirmInfoType = () => {
    const { data, error } = useSWR(
        `/firmInfo`,
        FirmInfoService.getFirmInfoApiV1ClientPortalFirmInfoGet
    )
    return {
        firmInfo: data,
        isLoading: !error && !data,
        isError: error,
    }
}

type ClientCustomersType = {
    customers: BasicCustomer[] | undefined
    isLoading: boolean
    isError: boolean
}
type GetClientCustomersType = () => ClientCustomersType

export const useGetClientCustomers: GetClientCustomersType = () => {
    const { data, error } = useSWR(`/client_customers`, () =>
        ClientPortalService.getClientCustomersApiV1ClientPortalCustomersGet()
    )
    return {
        customers: data,
        isLoading: !error && !data,
        isError: error,
    }
}

type LoadScheduleFunc = (
    startDate: string,
    endDate: string
) => {
    shift_instances: ScheduleShiftInstance[] | undefined
    isScheduleLoading: boolean
    isScheduleError: boolean
}
export const useLoadClientSchedule: LoadScheduleFunc = (startDate, endDate) => {
    const { data, error } = useSWR(
        startDate != endDate ? [`/schedule`, startDate, endDate] : null,
        () =>
            ClientPortalService.getClientScheduleForDateRangeApiV1ClientPortalScheduleGet(
                startDate!,
                endDate!
            )
    )
    return {
        shift_instances: data,
        isScheduleLoading: !error && !data,
        isScheduleError: error,
    }
}

type LoadShiftInstanceForBelltowerType = {
    shift_instances: ShiftInstanceForClientBelltower[] | undefined
} & FunctionStatusType

type LoadBelltowerLiveFunc = () => LoadShiftInstanceForBelltowerType
export const useLoadClientBelltowerLive: LoadBelltowerLiveFunc = () => {
    const { data, error } = useSWR(
        '/belltower/live',
        ClientPortalService.getLiveBelltowerApiV1ClientPortalBelltowerLiveGet
    )
    return {
        shift_instances: data,
        isLoading: !error && !data,
        isError: error,
    }
}

type LoadClientReportTypesType = {
    reportTypes: string[]
} & FunctionStatusType

type LoadClientReportTypesFunc = () => LoadClientReportTypesType
export const useLoadClientReportTypes: LoadClientReportTypesFunc = () => {
    const { data, error } = useSWR(
        '/belltower/reports/types',
        ClientPortalService.getReportTypesApiV1ClientPortalBelltowerReportTypesGet
    )
    return {
        reportTypes: data ?? [],
        isLoading: !error && !data,
        isError: error,
    }
}

type LoadClientReportTypesMetadataType = {
    reportTypes: string[]
} & FunctionStatusType

type LoadClientReportTypesMetadataFunc = () => LoadClientReportTypesMetadataType
export const useLoadClientReportTypesMetadata: LoadClientReportTypesMetadataFunc =
    () => {
        const { data, error } = useSWR(
            'belltower-report-types',
            ClientPortalService.getReportTypesApiV2ClientPortalBelltowerReportTypesGet
        )
        return {
            reportTypes: data ?? [],
            isLoading: !error && !data,
            isError: error,
        }
    }

type LoadClientBelltowerReportMetadataType = {
    belltowerReportMetadatas: ReportMetadata[]
    pageInfo: PageInfo | undefined
} & FunctionStatusType

type LoadAllClientBelltowerReportMetadataFunc = (
    queryOptions: QueryOptions,
    filterParams: ReportFilterParams
) => LoadClientBelltowerReportMetadataType
export const useLoadBelltowerReportMetadata: LoadAllClientBelltowerReportMetadataFunc =
    (queryOptions, filterParams) => {
        const { data, error } = useSWR(
            [
                `belltower-reports-metadata`,
                queryOptions.page,
                queryOptions.pageSize,
                filterParams,
            ],
            () =>
                ClientPortalService.readBelltowerReportsApiV2ClientPortalBelltowerReportsPost(
                    queryOptions.page,
                    queryOptions.pageSize,
                    filterParams
                )
        )
        return {
            belltowerReportMetadatas: data ? data.report_metadata : [],
            pageInfo: data
                ? {
                      page: data.page_info.page,
                      pageSize: data.page_info.pageSize,
                      total: data.page_info.total,
                  }
                : undefined,
            isLoading: !error && !data,
            isError: error,
        }
    }

type LoadClientReportsType = {
    belltowerReports: BelltowerReportMetadata[]
    pageInfo: PageInfo | undefined
} & FunctionStatusType

type LoadClientReportsFunc = (
    queryOptions: QueryOptions,
    filterParams: ReportFilterParams
) => LoadClientReportsType
export const useLoadClientReports: LoadClientReportsFunc = (
    queryOptions,
    filterParams
) => {
    const { data, error } = useSWR(
        [
            `/belltower/reports`,
            queryOptions.page,
            queryOptions.pageSize,
            filterParams,
        ],
        () =>
            ClientPortalService.readBelltowerReportsApiV1ClientPortalBelltowerReportsPost(
                queryOptions.page,
                queryOptions.pageSize,
                filterParams
            )
    )
    return {
        belltowerReports: data ? data.report_metadata : [],
        pageInfo: data
            ? {
                  page: data.page_info.page,
                  pageSize: data.page_info.pageSize,
                  total: data.page_info.total,
              }
            : undefined,
        isLoading: !error && !data,
        isError: error,
    }
}

type LoadClientBelltowerReportType = {
    report:
        | ConsolidatedActivityReport
        | Incident
        | CustomReport
        | ActivityReportFiled
        | string
        | undefined
} & FunctionStatusType

type LoadBelltowerReportFunc = (
    customerId: string,
    id: string,
    type: BelltowerReportType | string
) => LoadClientBelltowerReportType
export const useLoadClientBelltowerReport: LoadBelltowerReportFunc = (
    customerId,
    id,
    type
) => {
    const { data, error } = useSWR(`/belltower/reports/${id}`, () =>
        ClientPortalService.readBelltowerReportForCustomerTypeAndIdApiV1ClientPortalBelltowerReportsCustomerIdIdGet(
            customerId,
            id,
            type
        )
    )
    return {
        report: data,
        isLoading: !error && !data,
        isError: error,
    }
}

type QueryOptions = {
    page: number
    pageSize: number
}

type PageInfo = {
    page: number
    pageSize: number
    total: number
}

type LoadActivitiesType = {
    activities: HighLevelActivity[]
    pageInfo: PageInfo | undefined
} & FunctionStatusType

type LoadAllActivitiesFunc = (
    queryOptions: QueryOptions,
    filterParams: PaginatedActivityFilter
) => LoadActivitiesType
export const useLoadClientActivities: LoadAllActivitiesFunc = (
    queryOptions,
    filterParams
) => {
    // backend page counts starts at 1, frontend at 0. So we adjust in this func
    const adjustedPageCount = queryOptions.page + 1
    const { data, error } = useSWR(
        [
            'all-activities',
            queryOptions.page,
            queryOptions.pageSize,
            filterParams,
        ],
        () =>
            ClientPortalService.readActivitiesForClientApiV1ClientPortalBelltowerActivitiesPost(
                adjustedPageCount,
                queryOptions.pageSize,
                filterParams
            )
    )
    return {
        activities: data ? data.items : [],
        pageInfo: data
            ? {
                  page: (data.page ?? 1) - 1,
                  pageSize: data.size ?? 1,
                  total: data.total ?? 1,
              }
            : undefined,
        isLoading: !error && !data,
        isError: error,
    }
}

export const useLoadClientNotices = () => {
    const { data, error } = useSWR(`/notices`, () =>
        ClientPortalService.getNoticesApiV1ClientPortalNoticeGet()
    )
    return { notices: data, isLoading: !error && !data, isError: error }
}

export const useCrudClientNotice = () => {
    const { mutate } = useSWRConfig()
    const createNoticeTrigger = async (
        customerId: string,
        notice: NoticeCreate
    ) => {
        await ClientPortalService.createNoticeApiV1ClientPortalNoticePut(
            customerId,
            notice
        ).catch((reason) => toast.error(errorReasonToString(reason)))
        mutate(`/notices`, undefined, true)
    }
    const deleteClientNoticeTrigger = async (noticeId: string) => {
        await ClientPortalService.deleteNoticeApiV1ClientPortalNoticeNoticeIdDelete(
            noticeId
        ).catch((reason) => toast.error(errorReasonToString(reason)))
        mutate(`/notices`, undefined, true)
    }
    return { createNoticeTrigger, deleteClientNoticeTrigger }
}

type LoadActivePaymentRequestType = {
    paymentRequest?: ActivePaymentRequest
} & FunctionStatusType

type LoadActivePaymentRequestFunc = (
    paymentRequestId: string
) => LoadActivePaymentRequestType
export const useLoadActivePaymentRequest: LoadActivePaymentRequestFunc = (
    paymentRequestId
) => {
    const { data, error } = useSWR(
        `/active-payment-request/${paymentRequestId}`,
        () =>
            paymentRequestId
                ? PaymentService.readActivePaymentRequestNoAuthApiV1PaymentrequestIdActiveGet(
                      paymentRequestId
                  )
                : undefined
    )
    return {
        paymentRequest: data,
        isLoading: !error && !data,
        isError: error,
    }
}

type submitOneTimePaymentFunc = (
    oneTimePayment: OneTimePayment | undefined,
    paymentRequestId: string,
    mutate: ScopedMutator
) => Promise<PaymentResponse>
export const submitOneTimePayment: submitOneTimePaymentFunc = async (
    oneTimePayment,
    paymentRequestId: string,
    mutate
) => {
    if (!oneTimePayment) {
        return {
            status: PaymentResponseStatus.ERROR,
            failed_payload: {
                msg: 'An error occurred. Please check with you merchant about the status of the payment.',
            },
        }
    }

    const options = { optimisticData: oneTimePayment, rollbackOnError: true }
    return mutate(
        `/payments/onetimepayment/${paymentRequestId}`,
        async () => {
            return await PaymentService.paymentsSubmitOneTimeApiV1PaymentsOneTimePost(
                oneTimePayment
            ).catch((reason) => {
                console.error(reason)
                return {
                    status: PaymentResponseStatus.ERROR,
                    failed_payload: {
                        msg: 'An error occurred. Please check with you merchant about the status of the payment.',
                    },
                }
            })
        },
        options
    )
}

export const onDownloadPDFInvoiceClick = (
    invoice_id: string,
    invoice_number: string
) => {
    if (!invoice_id) {
        return
    }
    InvoiceService.createPdfForInvoiceNoUserApiV1InvoicePdfInvoiceIdPaymentGet(
        invoice_id
    )
        .then((res) => {
            const blob = new Blob([res], { type: 'application/pdf' })
            const fileURL = window.URL.createObjectURL(blob)
            let alink = document.createElement('a')
            alink.href = fileURL
            alink.download = `invoice-${invoice_number}.pdf`
            alink.click()
        })
        .catch((e) => {
            const text = errorReasonToString(e)
            if (text) {
                toast.error(text)
            } else {
                toast.error('Failed to Download PDF')
            }
        })
}

export function useLoadPositionsLookupForClientPortal(): {
    positionLookup: PositionLookup['lookup']
} & FunctionStatusType {
    const { data, error } = useSWR(
        'position_lookup',
        ClientPortalService.positionsLookupApiV1ClientPortalPositionsGet
    )
    return {
        positionLookup: data ? data.lookup : {},
        isLoading: !error && !data,
        isError: error,
    }
}
