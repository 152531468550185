/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PayrollServicesEnum {
    GENERIC = 'GENERIC',
    HOURS_BY_WORKER = 'HOURS_BY_WORKER',
    UKG = 'UKG',
    PAYCOM = 'PAYCOM',
    ISOLVED = 'ISOLVED',
    INDIVIDUAL = 'INDIVIDUAL',
    ADP = 'ADP',
}
