/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PostalAddress } from '../models/PostalAddress';
import type { PostalAddressResponse } from '../models/PostalAddressResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AddressService {

    /**
     * Validity
     * Takes a postal address obj and returns a validated postal address or "incomplete"
     * @param requestBody
     * @returns PostalAddressResponse Successful Response
     * @throws ApiError
     */
    public static validityApiV1ValidateAddressPost(
        requestBody: PostalAddress,
    ): CancelablePromise<PostalAddressResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/validate_address',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
