/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EmployeeForMassAnnouncementCreate } from './EmployeeForMassAnnouncementCreate';
import type { FileCreate } from './FileCreate';

export type MassAnnouncementCreate = {
    send_email: boolean;
    send_push: boolean;
    send_sms: boolean;
    subject: string;
    content: string;
    group_recipient_type?: MassAnnouncementCreate.group_recipient_type;
    file_in?: FileCreate;
    recipients: Array<EmployeeForMassAnnouncementCreate>;
    site_ids?: Array<string>;
    role_ids?: Array<string>;
};

export namespace MassAnnouncementCreate {

    export enum group_recipient_type {
        ROLE = 'ROLE',
        SITE = 'SITE',
    }


}

