/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IncidentContactRole {
    VICTIM = 'VICTIM',
    WITNESS = 'WITNESS',
    PERPETRATOR = 'PERPETRATOR',
    OTHER = 'OTHER',
    REPORTING_PARTY = 'REPORTING_PARTY',
}
