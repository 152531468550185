/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PaymentCreate = {
    amount_cents: number;
    memo?: string;
    occurred_at?: (string | null);
    posted_at?: (string | null);
    method?: PaymentCreate.method;
    status?: string;
    accounts_payable_invoice_id?: (string | null);
    vendor_id?: (string | null);
    batch_number?: (string | null);
    is_current?: boolean;
    is_archived?: boolean;
    target_version_id?: (string | null);
    officer_updating_immutable_columns_id?: (string | null);
};

export namespace PaymentCreate {

    export enum method {
        CREDIT_CARD = 'Credit Card',
        ACH = 'ACH',
    }


}

