/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EmployeeProfileCreate = {
    gender?: EmployeeProfileCreate.gender;
    race?: EmployeeProfileCreate.race;
    category?: EmployeeProfileCreate.category;
    is_veteran?: boolean;
    officer_id?: string;
};

export namespace EmployeeProfileCreate {

    export enum gender {
        MALE = 'Male',
        FEMALE = 'Female',
        NON_BINARY = 'Non-binary',
    }

    export enum race {
        HISPANIC_OR_LATINO = 'Hispanic or Latino',
        WHITE = 'White',
        BLACK_OR_AFRICAN_AMERICAN = 'Black or African American',
        NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER = 'Native Hawaiian or Other Pacific Islander',
        ASIAN = 'Asian',
        AMERICAN_INDIAN_OR_OTHER_ALASKA_NATIVE = 'American Indian or Other Alaska Native',
        TWO_OR_MORE_RACES = 'Two or more races',
    }

    export enum category {
        EXECUTIVE_SENIOR_LEVEL_OFFICIAL_AND_MANAGER = 'Executive/Senior Level Official and Manager',
        FIRST_MID_LEVEL_OFFICIAL_AND_MANAGER = 'First/Mid-Level Official and Manager',
        PROFESSIONAL = 'Professional',
        TECHNICIAN = 'Technician',
        SALES_WORKER = 'Sales Worker',
        ADMINISTRATIVE_SUPPORT_WORKER = 'Administrative Support Worker',
        CRAFT_WORKER = 'Craft Worker',
        OPERATIVE = 'Operative',
        LABORER_AND_HELPER = 'Laborer and Helper',
        SERVICE_WORKER = 'Service Worker',
    }


}

