/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CHQEarningCode = {
    id?: string;
    company: string;
    type: CHQEarningCode.type;
    name: string;
    active: boolean;
};

export namespace CHQEarningCode {

    export enum type {
        HOURLY = 'hourly',
        SALARIED = 'salaried',
        OVERTIME = 'overtime',
        DOUBLE_OVERTIME = 'double_overtime',
        PAID_HOLIDAY = 'paid_holiday',
        PTO = 'pto',
        SICK = 'sick',
        OTHER_IMPUTED = 'other_imputed',
        NON_HOURLY_REGULAR = 'non_hourly_regular',
        BONUS = 'bonus',
        SEVERANCE = 'severance',
        REGULAR = 'regular',
    }


}

