/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum InvoiceStatusAction {
    VOID = 'Void',
    SET_UNCOLLECTIBLE = 'Set Uncollectible',
    SET_UNCOLLECTIBLE_AND_STOP_FUTURE_PAYMENTS = 'Set Uncollectible and Stop Future Payments',
    VOID_AND_COPY = 'Void and Copy',
}
