/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum BilledOTSetting {
    DISABLED = 'DISABLED',
    RULE = 'RULE',
    WAGE = 'WAGE',
    WORKWEEK = 'WORKWEEK',
}
