/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MasterScheduleShiftCreate } from '../models/MasterScheduleShiftCreate';
import type { MasterScheduleShiftUpdate } from '../models/MasterScheduleShiftUpdate';
import type { ScheduleUpdatePreview } from '../models/ScheduleUpdatePreview';
import type { ShiftListing } from '../models/ShiftListing';
import type { ShiftMutationResponse } from '../models/ShiftMutationResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MasterScheduleService {

    /**
     * Create Master Schedule Shift
     * @param requestBody
     * @returns ShiftListing Successful Response
     * @throws ApiError
     */
    public static createMasterScheduleShiftApiV1MasterScheduleShiftPost(
        requestBody: MasterScheduleShiftCreate,
    ): CancelablePromise<ShiftListing> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/master_schedule_shift',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Master Schedule Shift
     * @param shiftId
     * @param requestBody
     * @returns ShiftListing Successful Response
     * @throws ApiError
     */
    public static updateMasterScheduleShiftApiV1MasterScheduleShiftShiftIdPatch(
        shiftId: string,
        requestBody: MasterScheduleShiftUpdate,
    ): CancelablePromise<ShiftListing> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/master_schedule_shift/{shift_id}',
            path: {
                'shift_id': shiftId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Master Schedule Shift
     * This is a soft delete endpoint. We want to preserve historical records of shifts so we don't actually delete them.
     * Instead, we set the end_date of the shift to the current date. We also remove all related shift instances that are in the future.
     * @param shiftId
     * @returns ShiftMutationResponse Successful Response
     * @throws ApiError
     */
    public static deleteMasterScheduleShiftApiV1MasterScheduleShiftShiftIdDelete(
        shiftId: string,
    ): CancelablePromise<ShiftMutationResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/master_schedule_shift/{shift_id}',
            path: {
                'shift_id': shiftId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Preview Master Schedule Update
     * @param shiftId
     * @param requestBody
     * @returns ScheduleUpdatePreview Successful Response
     * @throws ApiError
     */
    public static previewMasterScheduleUpdateApiV1MasterScheduleShiftPreviewUpdateShiftIdPost(
        shiftId: string,
        requestBody: MasterScheduleShiftUpdate,
    ): CancelablePromise<ScheduleUpdatePreview> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/master_schedule_shift/preview_update/{shift_id}',
            path: {
                'shift_id': shiftId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
