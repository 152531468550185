/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Address } from './Address';
import type { BelfryOfficerMetadata } from './BelfryOfficerMetadata';
import type { EmployeeOnboard } from './EmployeeOnboard';

export type CHQContractor = {
    id: string;
    first_name?: string;
    last_name: string;
    middle_name?: string;
    email?: string;
    dob?: string;
    bank_accounts: Array<string>;
    ssn_last_four?: string;
    ssn_validation_status?: string;
    payment_method_preference?: CHQContractor.payment_method_preference;
    onboard: EmployeeOnboard;
    workplaces: Array<string>;
    primary_workplace?: string;
    company: string;
    start_date?: string;
    termination_date?: string;
    default_net_pay_split?: string;
    address?: Address;
    metadata?: BelfryOfficerMetadata;
};

export namespace CHQContractor {

    export enum payment_method_preference {
        DIRECT_DEPOSIT = 'direct_deposit',
        MANUAL = 'manual',
    }


}

