/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PaymentAccount = {
    name: string;
    description?: string;
    pass_through_fees_enabled?: boolean;
    type: PaymentAccount.type;
    is_payments_acceptance_enabled?: boolean;
    status: PaymentAccount.status;
    customer_id?: string;
    parent_customer_id?: string;
    firm_id?: string;
    created_at: string;
    id: string;
};

export namespace PaymentAccount {

    export enum type {
        FIRM = 'Firm',
        CUSTOMER = 'Customer',
        FEES = 'Fees',
        GENERAL_FEES = 'General Fees',
    }

    export enum status {
        ACTIVE = 'Active',
        PENDING = 'Pending',
        ARCHIVED = 'Archived',
    }


}

