/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TimeOffDailyRequestCreate } from '../models/TimeOffDailyRequestCreate';
import type { TimeOffHourlyRequestCreate } from '../models/TimeOffHourlyRequestCreate';
import type { TimeOffRequest } from '../models/TimeOffRequest';
import type { TimeOffRequestApproval } from '../models/TimeOffRequestApproval';
import type { TimeOffRequestDenial } from '../models/TimeOffRequestDenial';
import type { TimeOffRequestPolicy } from '../models/TimeOffRequestPolicy';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TimeOffRequestService {

    /**
     * Get Pending Time Off Requests
     * Creates a new time off object and returns a list of all of the time off object based on the return form.
     * @returns TimeOffRequest Successful Response
     * @throws ApiError
     */
    public static getPendingTimeOffRequestsApiV1TimeOffRequestsPendingGet(): CancelablePromise<Array<TimeOffRequest>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/time_off_requests_pending',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Pending Time Off Requests Count
     * Creates a new time off object and returns a list of all of the time off object based on the return form.
     * @returns number Successful Response
     * @throws ApiError
     */
    public static getPendingTimeOffRequestsCountApiV1TimeOffRequestsPendingCountGet(): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/time_off_requests_pending/count',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Daily Time Off Request
     * Creates a new time off request entry for a daily policy.
     * @param requestBody
     * @returns TimeOffRequest Successful Response
     * @throws ApiError
     */
    public static createDailyTimeOffRequestApiV1TimeOffDailyRequestPost(
        requestBody: TimeOffDailyRequestCreate,
    ): CancelablePromise<TimeOffRequest> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/time_off_daily_request',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Hourly Time Off Request
     * Creates a new time off request entry for a daily policy.
     * @param requestBody
     * @returns TimeOffRequest Successful Response
     * @throws ApiError
     */
    public static createHourlyTimeOffRequestApiV1TimeOffHourlyRequestPost(
        requestBody: TimeOffHourlyRequestCreate,
    ): CancelablePromise<TimeOffRequest> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/time_off_hourly_request',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Policies For Employee
     * Get all applicable policies for the employee and their current balance
     * @returns TimeOffRequestPolicy Successful Response
     * @throws ApiError
     */
    public static getPoliciesForEmployeeApiV1TimeOffRequestPoliciesGet(): CancelablePromise<Array<TimeOffRequestPolicy>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/time_off_request/policies',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Time Off Request
     * Cancel a time off request entry.
     * @param requestId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static deleteTimeOffRequestApiV1TimeOffHourlyRequestRequestIdDeletePost(
        requestId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/time_off_hourly_request/{request_id}/delete',
            path: {
                'request_id': requestId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Reject Time Off Request
     * @param requestId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static rejectTimeOffRequestApiV1TimeOffDailyRequestRequestIdRejectPost(
        requestId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/time_off_daily_request/{request_id}/reject',
            path: {
                'request_id': requestId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Approve Time Off Request
     * @param requestId
     * @param requestBody
     * @returns string Successful Response
     * @throws ApiError
     */
    public static approveTimeOffRequestApiV1TimeOffDailyRequestRequestIdApprovePost(
        requestId: string,
        requestBody: TimeOffRequestApproval,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/time_off_daily_request/{request_id}/approve',
            path: {
                'request_id': requestId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Approve Time Off Request With Edit
     * @param requestId
     * @param requestBody
     * @returns string Successful Response
     * @throws ApiError
     */
    public static approveTimeOffRequestWithEditApiV1TimeOffDailyRequestRequestIdApproveWithEditPost(
        requestId: string,
        requestBody: TimeOffRequestApproval,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/time_off_daily_request/{request_id}/approve_with_edit',
            path: {
                'request_id': requestId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Pending Time Off Requests For Employee
     * Creates a new time off object and returns a list of all of the time off object based on the return form.
     * @returns TimeOffRequest Successful Response
     * @throws ApiError
     */
    public static getPendingTimeOffRequestsForEmployeeApiV1TimeOffRequestsEmployeeGet(): CancelablePromise<Array<TimeOffRequest>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/time_off_requests/employee',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Employee Pending Time Off Requests For Admin
     * Creates a new time off object and returns a list of all of the time off object based on the return form.
     * @param employeeId
     * @returns TimeOffRequest Successful Response
     * @throws ApiError
     */
    public static getEmployeePendingTimeOffRequestsForAdminApiV1TimeOffRequestsPendingEmployeeEmployeeIdGet(
        employeeId: string,
    ): CancelablePromise<Array<TimeOffRequest>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/time_off_requests_pending/employee/{employee_id}',
            path: {
                'employee_id': employeeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Reject Time Off Request
     * @param requestId
     * @param requestBody
     * @returns string Successful Response
     * @throws ApiError
     */
    public static rejectTimeOffRequestApiV2TimeOffDailyRequestRequestIdRejectPost(
        requestId: string,
        requestBody: TimeOffRequestDenial,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/time_off_daily_request/{request_id}/reject',
            path: {
                'request_id': requestId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
