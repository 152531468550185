import { Settings, SettingsService } from '../generated'
import useSWR from 'swr'
import { toast } from 'react-toastify'
import { errorReasonToString } from '../utils/errorUtils'
import { SettingsForEmployeePortal } from 'src/generated/models/SettingsForEmployeePortal'

type SettingsType = {
    settings: Settings | undefined
    isLoading: boolean
    isError: boolean
}

type GetSettingsFunc = () => SettingsType
export const useGetSettings: GetSettingsFunc = () => {
    const { data, error } = useSWR(
        `/settings`,
        SettingsService.getSettingsApiV1SettingsGet
    )
    return {
        settings: data,
        isLoading: !error && !data,
        isError: error,
    }
}

type SettingsForEmployeePortalType = {
    settings: SettingsForEmployeePortal | undefined
    isLoading: boolean
    isError: boolean
}

type GetSettingsForEmployeePortalFunc = () => SettingsForEmployeePortalType
export const useGetSettingsForEmployeePortal: GetSettingsForEmployeePortalFunc =
    () => {
        const { data, error } = useSWR(
            `/employee_portal/settings`,
            SettingsService.loadFirmSettingsForEmployeePortalApiV1EmployeePortalSettingsGet
        )
        return {
            settings: data,
            isLoading: !error && !data,
            isError: error,
        }
    }

type UpdateSettingsFunc = (settings: Settings, mutate: any) => void
export const updateSettings: UpdateSettingsFunc = (settings, mutate) => {
    const options = { optimisticData: settings, rollbackOnError: true }
    mutate(
        `/settings`,
        () =>
            SettingsService.updateSettingsApiV1SettingsPost(settings).catch(
                (error) => toast.error(errorReasonToString(error))
            ),
        options
    )
}
