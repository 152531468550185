/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivityReportFiled } from '../models/ActivityReportFiled';
import type { BelltowerReportType } from '../models/BelltowerReportType';
import type { ConsolidatedActivityReport } from '../models/ConsolidatedActivityReport';
import type { CustomReport } from '../models/CustomReport';
import type { Incident } from '../models/Incident';
import type { ReportFilterParams } from '../models/ReportFilterParams';
import type { ReportMetadataResponsePage } from '../models/ReportMetadataResponsePage';
import type { ReportResponsePage } from '../models/ReportResponsePage';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BelltowerReportService {

    /**
     * Get Report Types
     * @param customerId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getReportTypesApiV1BelltowerReportTypesGet(
        customerId?: (string | null),
    ): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/belltower-report-types',
            query: {
                'customer_id': customerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Belltower Reports
     * @param page
     * @param pageSize
     * @param requestBody
     * @returns ReportResponsePage Successful Response
     * @throws ApiError
     */
    public static readBelltowerReportsApiV1BelltowerReportsNewPost(
        page: number,
        pageSize: number,
        requestBody: (ReportFilterParams | null),
    ): CancelablePromise<ReportResponsePage> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/belltower-reports-new',
            query: {
                'page': page,
                'pageSize': pageSize,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Belltower Reports For Customer
     * Return the belltower reports for a customer.
     * @param customerId
     * @param page
     * @param pageSize
     * @param requestBody
     * @returns ReportResponsePage Successful Response
     * @throws ApiError
     */
    public static readBelltowerReportsForCustomerApiV1CustomerCustomerIdBelltowerReportsPost(
        customerId: string,
        page: number,
        pageSize: number,
        requestBody: (ReportFilterParams | null),
    ): CancelablePromise<ReportResponsePage> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/customer/{customer_id}/belltower-reports',
            path: {
                'customer_id': customerId,
            },
            query: {
                'page': page,
                'pageSize': pageSize,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Export Belltower Reports For Customer
     * Exports the belltower reports for a customer to a CSV file based on the filter params.
     * @param customerId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static exportBelltowerReportsForCustomerApiV1CustomersCustomerIdReportsExportPost(
        customerId: string,
        requestBody: (ReportFilterParams | null),
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/customers/{customer_id}/reports/export',
            path: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Belltower Report For Customer Type And Id
     * Return the belltower reports for a customer, report type and bell tower id.
     * @param customerId
     * @param id
     * @param type
     * @returns any Successful Response
     * @throws ApiError
     */
    public static readBelltowerReportForCustomerTypeAndIdApiV1CustomerCustomerIdBelltowerReportsIdGet(
        customerId: string,
        id: string,
        type: (BelltowerReportType | string),
    ): CancelablePromise<(CustomReport | ConsolidatedActivityReport | Incident | ActivityReportFiled)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/customer/{customer_id}/belltower-reports/{id}',
            path: {
                'customer_id': customerId,
                'id': id,
            },
            query: {
                'type': type,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Approve Belltower Report
     * @param customerId
     * @param id
     * @param type
     * @param withAttachment
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static approveBelltowerReportApiV1CustomerCustomerIdBelltowerReportsIdPost(
        customerId: string,
        id: string,
        type: (BelltowerReportType | string),
        withAttachment: boolean,
        requestBody?: Array<string>,
    ): CancelablePromise<(CustomReport | ConsolidatedActivityReport | Incident)> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/customer/{customer_id}/belltower-reports/{id}',
            path: {
                'customer_id': customerId,
                'id': id,
            },
            query: {
                'type': type,
                'with_attachment': withAttachment,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Report Types
     * @param customerId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getReportTypesApiV2BelltowerReportTypesGet(
        customerId?: (string | null),
    ): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/belltower-report-types',
            query: {
                'customer_id': customerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Belltower Reports
     * @param page
     * @param pageSize
     * @param requestBody
     * @returns ReportMetadataResponsePage Successful Response
     * @throws ApiError
     */
    public static readBelltowerReportsApiV2BelltowerReportsPost(
        page: number,
        pageSize: number,
        requestBody: (ReportFilterParams | null),
    ): CancelablePromise<ReportMetadataResponsePage> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/belltower-reports',
            query: {
                'page': page,
                'pageSize': pageSize,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Belltower Reports For Customer
     * Return the belltower reports for a customer.
     * @param customerId
     * @param page
     * @param pageSize
     * @param requestBody
     * @returns ReportMetadataResponsePage Successful Response
     * @throws ApiError
     */
    public static readBelltowerReportsForCustomerApiV2CustomerCustomerIdBelltowerReportsPost(
        customerId: string,
        page: number,
        pageSize: number,
        requestBody: (ReportFilterParams | null),
    ): CancelablePromise<ReportMetadataResponsePage> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/customer/{customer_id}/belltower-reports',
            path: {
                'customer_id': customerId,
            },
            query: {
                'page': page,
                'pageSize': pageSize,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
