/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmployeeProfile } from '../models/EmployeeProfile';
import type { EmployeeProfileCreate } from '../models/EmployeeProfileCreate';
import type { EmployeeProfileUpdate } from '../models/EmployeeProfileUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EmployeeProfileService {

    /**
     * Create Employee Profile
     * Creates the Employee EEO1 Profile for the given employee
     *
     * Employees can be assigned a 'Complete EEO1 Form' task by the back office. This endpoint
     * is hit by employees after creating their EEO1 profile.
     *
     * Query Parameters
     * ----------
     * finish_later : bool
     * if true, signifies that the action should not yet be completed.
     * @param employeeId
     * @param requestBody
     * @param finishLater
     * @returns EmployeeProfile Successful Response
     * @throws ApiError
     */
    public static createEmployeeProfileApiV1EmployeePortalEmployeeEeo1EmployeeIdPost(
        employeeId: string,
        requestBody: EmployeeProfileCreate,
        finishLater: boolean = false,
    ): CancelablePromise<EmployeeProfile> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/employee_portal/employee/eeo1/{employee_id}',
            path: {
                'employee_id': employeeId,
            },
            query: {
                'finish_later': finishLater,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Employee Profile
     * Updates the Employee EEO1 Profile for the given employee
     *
     * Employees can be assigned a 'Complete EEO1 Form' task by the back office. This endpoint
     * is hit by employees to update their existing EEO1 profile.
     *
     * Query Parameters
     * ----------
     * finish_later : bool
     * if true, signifies that the action should not yet be completed.
     * @param employeeId
     * @param requestBody
     * @param finishLater
     * @returns EmployeeProfile Successful Response
     * @throws ApiError
     */
    public static updateEmployeeProfileApiV1EmployeePortalEmployeeEeo1EmployeeIdPut(
        employeeId: string,
        requestBody: EmployeeProfileUpdate,
        finishLater: boolean = false,
    ): CancelablePromise<EmployeeProfile> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/employee_portal/employee/eeo1/{employee_id}',
            path: {
                'employee_id': employeeId,
            },
            query: {
                'finish_later': finishLater,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Employee Profile
     * Returns the Employee EEO1 Profile for the given employee
     * @param employeeId
     * @returns EmployeeProfile Successful Response
     * @throws ApiError
     */
    public static readEmployeeProfileApiV1EmployeeEmployeeIdEeo1Get(
        employeeId: string,
    ): CancelablePromise<EmployeeProfile> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/employee/{employee_id}/eeo1/',
            path: {
                'employee_id': employeeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Employee Profile Back Office
     * Creates the Employee EEO1 Profile for the given employee from back office
     * @param employeeId
     * @param requestBody
     * @param finishLater
     * @returns EmployeeProfile Successful Response
     * @throws ApiError
     */
    public static createEmployeeProfileBackOfficeApiV1EmployeeEmployeeIdEeo1BackOfficePost(
        employeeId: string,
        requestBody: EmployeeProfileCreate,
        finishLater: boolean = false,
    ): CancelablePromise<EmployeeProfile> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/employee/{employee_id}/eeo1/back_office/',
            path: {
                'employee_id': employeeId,
            },
            query: {
                'finish_later': finishLater,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Employee Profile Back Office
     * Updates the Employee Profile for the given employee from back office
     * @param employeeId
     * @param requestBody
     * @param finishLater
     * @returns EmployeeProfile Successful Response
     * @throws ApiError
     */
    public static updateEmployeeProfileBackOfficeApiV1EmployeeEmployeeIdEeo1BackOfficePut(
        employeeId: string,
        requestBody: EmployeeProfileUpdate,
        finishLater: boolean = false,
    ): CancelablePromise<EmployeeProfile> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/employee/{employee_id}/eeo1/back_office/',
            path: {
                'employee_id': employeeId,
            },
            query: {
                'finish_later': finishLater,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
