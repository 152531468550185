/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PaymentFirmOnboardingResponse = {
    firm_id: string;
    status: PaymentFirmOnboardingResponse.status;
};

export namespace PaymentFirmOnboardingResponse {

    export enum status {
        CREATED = 'Created',
        SUBMITTED = 'Submitted',
        HOLDING = 'Holding',
        UNDER_WRITING = 'UnderWriting',
        BOARDING = 'Boarding',
        DECLINED = 'Declined',
        ACTIVATED = 'Activated',
    }


}

