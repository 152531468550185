/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ActivityType } from './ActivityType';

export type BreakInUpdate = {
    activity_type?: ActivityType;
    description?: string;
    occurred_at?: string;
    title: string;
    location?: string;
    activity_type_literal?: BreakInUpdate.activity_type_literal;
};

export namespace BreakInUpdate {

    export enum activity_type_literal {
        BREAK_IN = 'BREAK_IN',
    }


}

