/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FileCreate } from './FileCreate';

export type OnboardingDocumentCreate = {
    firm_id?: string;
    requires_employee_acknowledgement?: boolean;
    share_group?: OnboardingDocumentCreate.share_group;
    share_group_role_ids?: Array<string>;
    file_in?: FileCreate;
    share_group_employee_ids?: Array<string>;
};

export namespace OnboardingDocumentCreate {

    export enum share_group {
        ALL = 'ALL',
        ROLE = 'ROLE',
        INDIVIDUAL = 'INDIVIDUAL',
        NONE = 'NONE',
    }


}

