/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { app__schemas__shift_offer__ShiftOfferInterest } from './app__schemas__shift_offer__ShiftOfferInterest';
import type { ScheduleOfficer } from './ScheduleOfficer';

export type SimpleShiftOffer = {
    id: string;
    shift_offer_status: SimpleShiftOffer.shift_offer_status;
    shift_offer_interests?: Array<app__schemas__shift_offer__ShiftOfferInterest>;
    offered_officers_from_filter?: Array<ScheduleOfficer>;
    offered_officers_from_manual?: Array<ScheduleOfficer>;
    offered_officer_count?: number;
    interested_officer_count?: number;
    unassigned_interested_officer_ids?: Array<string>;
    shift_instance_id?: string;
    shift_id?: string;
    shift_start_date_time?: string;
    shift_end_date_time?: string;
};

export namespace SimpleShiftOffer {

    export enum shift_offer_status {
        OFFERED = 'OFFERED',
        REOFFERED = 'REOFFERED',
        QUEUED = 'QUEUED',
        CONFIRMED = 'CONFIRMED',
    }


}

