import React, { createContext } from 'react'
import {
    useGetSettings,
    useGetSettingsForEmployeePortal,
} from '../../services/settings'
import { useGetFirmInfo } from 'client_portal/clientServices'

export const FirmSettingContext = createContext<{
    use24HourClock: boolean
    firmID: string | undefined
    discreteSubactivityReportRows: boolean
}>({ use24HourClock: false, firmID: '', discreteSubactivityReportRows: false })

type FirmSettingContextWrapperProps = React.PropsWithChildren<{}>

export const FirmSettingContextWrapper = ({
    children,
    ...props
}: FirmSettingContextWrapperProps) => {
    const { settings } = useGetSettings()

    return (
        <FirmSettingContext.Provider
            value={{
                use24HourClock: settings?.display_24_hour_clock ?? false,
                firmID: settings?.firm_id,
                discreteSubactivityReportRows:
                    settings?.discrete_subactivity_report_rows ?? false,
            }}
        >
            {children}
        </FirmSettingContext.Provider>
    )
}

export const FirmInfoContextWrapperForClientPortal = ({
    children,
    ...props
}: FirmSettingContextWrapperProps) => {
    const { firmInfo } = useGetFirmInfo()

    return (
        <FirmSettingContext.Provider
            value={{
                use24HourClock: firmInfo?.display_24_hour_clock ?? false,
                firmID: firmInfo?.firm_id,
                discreteSubactivityReportRows:
                    firmInfo?.discrete_subactivity_report_rows ?? false,
            }}
        >
            {children}
        </FirmSettingContext.Provider>
    )
}

export const FirmSettingContextWrapperForEmployeePortal = ({
    children,
    ...props
}: FirmSettingContextWrapperProps) => {
    const { settings } = useGetSettingsForEmployeePortal()

    return (
        <FirmSettingContext.Provider
            value={{
                use24HourClock: settings?.display_24_hour_clock ?? false,
                firmID: settings?.firm_id,
                discreteSubactivityReportRows:
                    settings?.discrete_subactivity_report_rows ?? false,
            }}
        >
            {children}
        </FirmSettingContext.Provider>
    )
}
