/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EmployeeProfile } from './EmployeeProfile';

export type OnboardingActionEEO1 = {
    status?: OnboardingActionEEO1.status;
    next_actor?: OnboardingActionEEO1.next_actor;
    form_type?: OnboardingActionEEO1.form_type;
    profile?: EmployeeProfile;
    id: string;
};

export namespace OnboardingActionEEO1 {

    export enum status {
        NOT_STARTED = 'NOT_STARTED',
        IN_PROGRESS = 'IN_PROGRESS',
        SUBMITTED = 'SUBMITTED',
        COMPLETE = 'COMPLETE',
        CANCELLED = 'CANCELLED',
    }

    export enum next_actor {
        EMPLOYEE = 'EMPLOYEE',
        EMPLOYER = 'EMPLOYER',
        EXTERNAL = 'EXTERNAL',
    }

    export enum form_type {
        I9 = 'I9',
        W4 = 'W4',
        WOTC = 'WOTC',
        PROFILE = 'PROFILE',
        AVAILABILITY = 'AVAILABILITY',
        EEO1 = 'EEO1',
    }


}

