/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { IssuedLicense } from './IssuedLicense';
import type { OfficerCategory } from './OfficerCategory';
import type { OnboardingStatus } from './OnboardingStatus';

export type HighLevelOfficerWithOnboardingStatus = {
    default_pay_rate?: number;
    pay_data_obscured?: boolean;
    name: string;
    given_name?: string;
    nickname?: string;
    family_name?: string;
    id: string;
    firm_id: string;
    phone_number?: string;
    hire_date?: string;
    licenses: Array<IssuedLicense>;
    sms_delivery_failure?: boolean;
    image_url?: string;
    role_id?: string;
    is_terminated?: boolean;
    checkhq_id?: string;
    checkhq_contractor_id?: string;
    category: OfficerCategory;
    has_primary_workplace: boolean;
    checkhq_onboard_status?: string;
    checkhq_ssn_val?: string;
    salaried?: boolean;
    employee_type?: HighLevelOfficerWithOnboardingStatus.employee_type;
    is_payroll_disabled?: boolean;
    clasp_member_id?: string;
    onboarding_status?: OnboardingStatus;
};

export namespace HighLevelOfficerWithOnboardingStatus {

    export enum employee_type {
        SCHEDULABLE = 'Schedulable',
        NOT_SCHEDULABLE = 'Not Schedulable',
    }


}

