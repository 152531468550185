/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FileCreate } from './FileCreate';
import type { FileUpdate } from './FileUpdate';

export type EmployeeNoteUpdate = {
    id: string;
    officer_id: string;
    note?: string;
    note_type?: EmployeeNoteUpdate.note_type;
    violation_type?: EmployeeNoteUpdate.violation_type;
    issue_datetime?: string;
    file?: (FileCreate | FileUpdate);
};

export namespace EmployeeNoteUpdate {

    export enum note_type {
        COMMENDATION = 'Commendation',
        VIOLATION = 'Violation',
        ADMINISTRATIVE = 'Administrative',
        OTHER = 'Other',
    }

    export enum violation_type {
        ATTENDANCE_TARDINESS_UNAUTHORIZED_ABSENCE = 'Attendance/Tardiness/Unauthorized Absence',
        BREACH_OF_COMPANY_POLICY = 'Breach of Company Policy',
        CARELESSNESS_SAFETY = 'Carelessness/Safety',
        CONDUCT = 'Conduct',
        UNIFORM_POLICY = 'Uniform Policy',
        CREATING_A_DISTURBANCE = 'Creating a Disturbance',
        FAILURE_TO_FOLLOW_INSTRUCTIONS = 'Failure to Follow Instructions',
        INSUBORDINATION = 'Insubordination',
        PERFORMANCE = 'Performance',
        OTHER = 'Other',
    }


}

