import { Typography, Link } from '@mui/material'
import NextLink from 'next/link'

import { toast } from 'react-toastify'
import { ApiError } from '../../generated'
import { errorReasonToString } from '../../utils/errorUtils'

export const detailedLinkErrorToToast = (
    error: ApiError,
    passedAutoClose?: number | false | undefined
) => {
    const autoClose = passedAutoClose
        ? passedAutoClose
        : error?.body?.detail?.message
        ? false
        : 5000
    const toastContent =
        error?.body?.detail?.message &&
        error?.body?.detail.link &&
        error?.body?.detail.link_text ? (
            <div>
                <Typography>{`${error?.body?.detail?.message}`}</Typography>

                <NextLink
                    href={error?.body?.detail.link}
                    passHref={true}
                    target="_blank"
                    legacyBehavior
                >
                    <Link
                        fontWeight={700}
                        target="_blank"
                        color="rgb(255,255,255)"
                    >
                        {error?.body?.detail.link_text}
                    </Link>
                </NextLink>
            </div>
        ) : (
            errorReasonToString(error)
        )

    toast.error(toastContent, {
        autoClose,
        closeOnClick: false,
    })
}
