/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ActivityType } from './ActivityType';

export type CheckpointScannedUpdate = {
    activity_type?: ActivityType;
    description?: string;
    occurred_at?: string;
    title: string;
    location?: string;
    activity_type_literal?: CheckpointScannedUpdate.activity_type_literal;
};

export namespace CheckpointScannedUpdate {

    export enum activity_type_literal {
        CHECKPOINT_SCANNED = 'CHECKPOINT_SCANNED',
    }


}

