/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ShiftTimeUserRule = {
    shift_start_or_shift_end: ShiftTimeUserRule.shift_start_or_shift_end;
    comparison_operator: ShiftTimeUserRule.comparison_operator;
    time: string;
    logical_operator: ShiftTimeUserRule.logical_operator;
};

export namespace ShiftTimeUserRule {

    export enum shift_start_or_shift_end {
        SHIFT_START = 'SHIFT_START',
        SHIFT_END = 'SHIFT_END',
    }

    export enum comparison_operator {
        GREATER = 'GREATER',
        GREATER_OR_EQUAL = 'GREATER_OR_EQUAL',
        LESS = 'LESS',
        LESS_OR_EQUAL = 'LESS_OR_EQUAL',
    }

    export enum logical_operator {
        AND = 'AND',
        OR = 'OR',
    }


}

