import {
    HighLevelOfficer,
    OfficerBaseWithPayrollOnboardStatus,
    OfficerCategory,
    OfficerForTimekeepingView,
} from '../generated'
import { HighLevelOfficerWithBansAndRegions } from '../generated/models/HighLevelOfficerWithBansAndRegions'

// Returns a function that checks whether an officer
// is valid for a given set of customer/sites and regions.
// The function returns true if the officer is valid, false otherwise.
export function isOfficerValidForSiteAndRegion(
    selectedCustomersIds: string[],
    selectedCustomersRegionIds: (string | undefined)[]
): (value: HighLevelOfficerWithBansAndRegions) => boolean {
    return (officer) => {
        if (
            officer.banned_customers.length === 0 &&
            officer.regions.length === 0
        ) {
            return true
        }
        const bannedCustIds = officer.banned_customers.map(
            ({ customer_id }) => customer_id
        )
        const passesBan = !bannedCustIds.some((custId) =>
            selectedCustomersIds.includes(custId)
        )
        const officerRegionIds = officer.regions.map(({ id }) => id)
        // only suggest officers with matching regions or no regions
        const passesRegion =
            officerRegionIds.length === 0
                ? true
                : selectedCustomersRegionIds.every((id) => {
                      if (!id) {
                          // but if a customer doesn't have a region, suggest the officer
                          return true
                      }
                      return officerRegionIds.includes(id)
                  })

        return passesBan && passesRegion
    }
}

export const filterToPayrollOnboardStatusIncompleteOfficers = (
    off:
        | OfficerBaseWithPayrollOnboardStatus
        | HighLevelOfficer
        | OfficerForTimekeepingView
) => off.checkhq_onboard_status !== 'completed' && !off.is_payroll_disabled

export const filterToPayrollOnboardStatusBlockingOfficers = (
    off:
        | OfficerBaseWithPayrollOnboardStatus
        | HighLevelOfficer
        | OfficerForTimekeepingView
) =>
    (off.checkhq_onboard_status === 'blocking' ||
        (off.category === OfficerCategory.CONTRACTOR_1099_ &&
            !off.checkhq_onboard_status)) &&
    !off.is_payroll_disabled
