/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ActivityFilter {
    CLOCKS = 'CLOCKS',
    BREAKS = 'BREAKS',
    ACTIVITY_REPORTS = 'ACTIVITY_REPORTS',
    INCIDENT_REPORTS = 'INCIDENT_REPORTS',
    CUSTOM_REPORTS = 'CUSTOM_REPORTS',
    TOURS = 'TOURS',
    POST_ORDER = 'POST_ORDER',
    CHECKS = 'CHECKS',
}
