import { useEffect, useState } from 'react'
import { placesGeocoding } from '../services/placesGeocoding'

export function useCustomerAddressCoordinates(
    customerAddress: string | undefined
): number[] {
    const [long, setLong] = useState<number | undefined>()
    const [lat, setLat] = useState<number | undefined>()

    // coordinate format is long, lat here: https://docs.mapbox.com/api/overview/#coordinate-format
    useEffect(() => {
        if (customerAddress) {
            placesGeocoding(customerAddress).then((data) => {
                const { bestResult, initialResults } = data
                if (bestResult) {
                    setLong(bestResult.lng)
                    setLat(bestResult.lat)
                } else if (initialResults[0]) {
                    setLong(initialResults[0].lng)
                    setLat(initialResults[0].lat)
                }
            })
        }
    }, [customerAddress])

    return long && lat ? [long, lat] : []
}
