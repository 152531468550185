/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SignedMetabase } from '../models/SignedMetabase';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MetabaseService {

    /**
     * Sign Url
     * Returns a signed link to the metabase dashboard
     * @param dashboardId
     * @returns SignedMetabase Successful Response
     * @throws ApiError
     */
    public static signUrlApiV1MetabaseIframeGet(
        dashboardId: number,
    ): CancelablePromise<SignedMetabase> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/metabase/iframe',
            query: {
                'dashboard_id': dashboardId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Sign Url With Filters
     * Returns a signed link to the metabase dashboard
     * @param dashboardId
     * @param requestBody
     * @returns SignedMetabase Successful Response
     * @throws ApiError
     */
    public static signUrlWithFiltersApiV1MetabaseIframeExtrasPost(
        dashboardId: number,
        requestBody?: Record<string, (string | null)>,
    ): CancelablePromise<SignedMetabase> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/metabase/iframe/extras',
            query: {
                'dashboard_id': dashboardId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
