/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BreakTimingUserRuleAbsoluteTime } from './BreakTimingUserRuleAbsoluteTime';
import type { BreakTimingUserRulePercentProgress } from './BreakTimingUserRulePercentProgress';
import type { BreakTimingUserRuleRelativeTime } from './BreakTimingUserRuleRelativeTime';
import type { ShiftLengthUserRule } from './ShiftLengthUserRule';
import type { ShiftTimeUserRule } from './ShiftTimeUserRule';

export type BreakAllotmentCreate = {
    name: string;
    duration_minutes: number;
    is_paid: boolean;
    is_billed: boolean;
    is_unskippable?: boolean;
    hours_worked_per_break: number;
    location_assigned: string;
    only_customer_id?: string;
    has_break_timing_requirements?: boolean;
    break_timing_type?: BreakAllotmentCreate.break_timing_type;
    break_timing_percent_progress_rule?: BreakTimingUserRulePercentProgress;
    break_timing_relative_time_rules?: Array<BreakTimingUserRuleRelativeTime>;
    break_timing_absolute_time_rules?: Array<BreakTimingUserRuleAbsoluteTime>;
    has_shift_length_requirements?: boolean;
    shift_length_rules?: Array<ShiftLengthUserRule>;
    has_shift_time_requirements?: boolean;
    shift_time_rules?: Array<ShiftTimeUserRule>;
    is_hours_worked_per_break_enabled?: boolean;
    is_late_breaks_allowed?: boolean;
};

export namespace BreakAllotmentCreate {

    export enum break_timing_type {
        PERCENT_PROGRESS = 'PERCENT_PROGRESS',
        RELATIVE_TIME = 'RELATIVE_TIME',
        ABSOLUTE_TIME = 'ABSOLUTE_TIME',
    }


}

