/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AccountsPayableInvoice = {
    invoice_number: string;
    invoice_description?: string;
    status: AccountsPayableInvoice.status;
    date_issued: string;
    date_due: string;
    date_service_start?: (string | null);
    date_service_end?: (string | null);
    date_paid?: (string | null);
    date_posted?: (string | null);
    date_archived?: (string | null);
    amount_due_cents: number;
    amount_paid_cents?: number;
    id: string;
    is_archived: boolean;
    file_id?: string;
    firm_id?: string;
    vendor_id?: string;
};

export namespace AccountsPayableInvoice {

    export enum status {
        DRAFT = 'Draft',
        DELETED = 'Deleted',
        OPEN = 'Open',
        VOID = 'Void',
        UNCOLLECTIBLE = 'Uncollectible',
        UNCOLLECTIBLE_PAID = 'Uncollectible Paid',
        SHORT_PAID = 'Short Paid',
        PAID = 'Paid',
    }


}

