/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BillingContactAssoc } from '../models/BillingContactAssoc';
import type { BillingContactAssocCreate } from '../models/BillingContactAssocCreate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BillingContactService {

    /**
     * Read Billing Contact Assocs
     * Return the billing contacts for a contract
     * @param contractId
     * @returns BillingContactAssoc Successful Response
     * @throws ApiError
     */
    public static readBillingContactAssocsApiV1BillingContactContractIdGet(
        contractId: string,
    ): CancelablePromise<Array<BillingContactAssoc>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/billing_contact/{contract_id}',
            path: {
                'contract_id': contractId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Billing Contact Assoc
     * @param contractId
     * @param requestBody
     * @returns BillingContactAssoc Successful Response
     * @throws ApiError
     */
    public static createBillingContactAssocApiV1BillingContactContractIdPost(
        contractId: string,
        requestBody: BillingContactAssocCreate,
    ): CancelablePromise<Array<BillingContactAssoc>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/billing_contact/{contract_id}',
            path: {
                'contract_id': contractId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Billing Contact Assoc
     * @param contractId
     * @param contactId
     * @returns BillingContactAssoc Successful Response
     * @throws ApiError
     */
    public static deleteBillingContactAssocApiV1BillingContactContractIdContactIdDelete(
        contractId: string,
        contactId: string,
    ): CancelablePromise<Array<BillingContactAssoc>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/billing_contact/{contract_id}/{contact_id}',
            path: {
                'contract_id': contractId,
                'contact_id': contactId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
