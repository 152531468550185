/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EverifyCaseInDB = {
    case_number: string;
    case_status?: EverifyCaseInDB.case_status;
    case_close_ts?: string;
    id: string;
    officer_id: string;
    created_ts?: string;
    last_update_ts?: string;
};

export namespace EverifyCaseInDB {

    export enum case_status {
        OPEN = 'OPEN',
        CLOSED = 'CLOSED',
    }


}

