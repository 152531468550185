/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuditRecordParentCustomer } from '../models/AuditRecordParentCustomer';
import type { ParentCustomer } from '../models/ParentCustomer';
import type { ParentCustomerCreate } from '../models/ParentCustomerCreate';
import type { ParentCustomerDeprecated } from '../models/ParentCustomerDeprecated';
import type { ParentCustomerHighLevel } from '../models/ParentCustomerHighLevel';
import type { ParentCustomerHighLevelDeprecated } from '../models/ParentCustomerHighLevelDeprecated';
import type { ParentCustomerUpdate } from '../models/ParentCustomerUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ParentCustomerService {

    /**
     * Read Parent Customers
     * Return the parent customers for a users firm.
     * @param onlyArchived
     * @returns ParentCustomerHighLevelDeprecated Successful Response
     * @throws ApiError
     */
    public static readParentCustomersApiV1ParentCustomersGet(
        onlyArchived: boolean = false,
    ): CancelablePromise<Array<ParentCustomerHighLevelDeprecated>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/parent_customers',
            query: {
                'only_archived': onlyArchived,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Parent Customer
     * @param requestBody
     * @returns ParentCustomerDeprecated Successful Response
     * @throws ApiError
     */
    public static createParentCustomerApiV1ParentCustomerPost(
        requestBody: ParentCustomerCreate,
    ): CancelablePromise<ParentCustomerDeprecated> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/parent_customer',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Parent Customer
     * @param id
     * @returns ParentCustomerDeprecated Successful Response
     * @throws ApiError
     */
    public static readParentCustomerApiV1ParentCustomerIdGet(
        id: string,
    ): CancelablePromise<ParentCustomerDeprecated> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/parent_customer/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Parent Customer
     * @param id
     * @param requestBody
     * @returns ParentCustomerDeprecated Successful Response
     * @throws ApiError
     */
    public static updateParentCustomerApiV1ParentCustomerIdPut(
        id: string,
        requestBody: ParentCustomerUpdate,
    ): CancelablePromise<ParentCustomerDeprecated> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/parent_customer/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Post Order Audit Record
     * Return the parent customer for a give ID.
     * @param id
     * @returns AuditRecordParentCustomer Successful Response
     * @throws ApiError
     */
    public static readPostOrderAuditRecordApiV1ParentCustomerAuditRecordIdGet(
        id: string,
    ): CancelablePromise<AuditRecordParentCustomer> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/parent_customer-audit-record/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Parent Customers
     * Return the parent customers for a users firm.
     * @param onlyArchived
     * @returns ParentCustomerHighLevel Successful Response
     * @throws ApiError
     */
    public static readParentCustomersApiV2ParentCustomersGet(
        onlyArchived: boolean = false,
    ): CancelablePromise<Array<ParentCustomerHighLevel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/parent_customers',
            query: {
                'only_archived': onlyArchived,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Parent Customer
     * @param requestBody
     * @returns ParentCustomer Successful Response
     * @throws ApiError
     */
    public static createParentCustomerApiV2ParentCustomerPost(
        requestBody: ParentCustomerCreate,
    ): CancelablePromise<ParentCustomer> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/parent_customer',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Parent Customer
     * @param id
     * @returns ParentCustomer Successful Response
     * @throws ApiError
     */
    public static readParentCustomerApiV2ParentCustomerIdGet(
        id: string,
    ): CancelablePromise<ParentCustomer> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/parent_customer/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Parent Customer
     * @param id
     * @param requestBody
     * @returns ParentCustomer Successful Response
     * @throws ApiError
     */
    public static updateParentCustomerApiV2ParentCustomerIdPut(
        id: string,
        requestBody: ParentCustomerUpdate,
    ): CancelablePromise<ParentCustomer> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v2/parent_customer/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Post Order Audit Record
     * Return the parent customer for a give ID.
     * @param id
     * @returns AuditRecordParentCustomer Successful Response
     * @throws ApiError
     */
    public static readPostOrderAuditRecordApiV2ParentCustomerAuditRecordIdGet(
        id: string,
    ): CancelablePromise<AuditRecordParentCustomer> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/parent_customer-audit-record/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
