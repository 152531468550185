/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ActivityType } from './ActivityType';
import type { TourInstanceStartedBackOfficeUpdate } from './TourInstanceStartedBackOfficeUpdate';

export type TourStartedUpdate = {
    activity_type?: ActivityType;
    description?: string;
    occurred_at?: string;
    title: string;
    location?: string;
    tour_instance_id: string;
    tour_instance_back_office_update?: TourInstanceStartedBackOfficeUpdate;
    activity_type_literal?: TourStartedUpdate.activity_type_literal;
};

export namespace TourStartedUpdate {

    export enum activity_type_literal {
        TOUR_STARTED = 'TOUR_STARTED',
    }


}

