/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JobTitleCreate } from '../models/JobTitleCreate';
import type { JobTitleUpdate } from '../models/JobTitleUpdate';
import type { JobTitleWithOfficers } from '../models/JobTitleWithOfficers';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class JobTitleService {

    /**
     * Get Job Titles
     * Get job titles for firm
     * @returns JobTitleWithOfficers Successful Response
     * @throws ApiError
     */
    public static getJobTitlesApiV1JobTitleGet(): CancelablePromise<Array<JobTitleWithOfficers>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/job_title',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Job Title
     * Creates a Job Title
     * @param requestBody
     * @returns JobTitleWithOfficers Successful Response
     * @throws ApiError
     */
    public static createJobTitleApiV1JobTitlePost(
        requestBody: JobTitleCreate,
    ): CancelablePromise<JobTitleWithOfficers> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/job_title',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Job Title
     * Updates a Job Title
     * @param id
     * @param requestBody
     * @returns JobTitleWithOfficers Successful Response
     * @throws ApiError
     */
    public static updateJobTitleApiV1JobTitleIdPut(
        id: string,
        requestBody: JobTitleUpdate,
    ): CancelablePromise<JobTitleWithOfficers> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/job_title/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Job Title
     * Deletes a Job Title
     * @param id
     * @returns string Successful Response
     * @throws ApiError
     */
    public static deleteJobTitleApiV1JobTitleIdDelete(
        id: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/job_title/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
