/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum UnitOfLength {
    INCHES = 'INCHES',
    FEET = 'FEET',
    YARD = 'YARD',
    MILE = 'MILE',
    MILIMETERS = 'MILIMETERS',
    CENTIMETERS = 'CENTIMETERS',
    METER = 'METER',
    KILOMETER = 'KILOMETER',
}
