/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ActivityType } from './ActivityType';
import type { RunsheetTaskBackOfficeUpdate } from './RunsheetTaskBackOfficeUpdate';

export type CheckOutUpdate = {
    activity_type?: ActivityType;
    description?: string;
    occurred_at?: string;
    title: string;
    location?: string;
    runsheet_task_id?: string;
    runsheet_task_back_office_update?: RunsheetTaskBackOfficeUpdate;
    activity_type_literal?: CheckOutUpdate.activity_type_literal;
};

export namespace CheckOutUpdate {

    export enum activity_type_literal {
        CHECK_OUT = 'CHECK_OUT',
    }


}

