/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { File } from './File';

export type EmployeeNote = {
    note: string;
    note_type?: EmployeeNote.note_type;
    violation_type?: EmployeeNote.violation_type;
    officer_id: string;
    issue_datetime: string;
    id: string;
    file?: File;
};

export namespace EmployeeNote {

    export enum note_type {
        COMMENDATION = 'Commendation',
        VIOLATION = 'Violation',
        ADMINISTRATIVE = 'Administrative',
        OTHER = 'Other',
    }

    export enum violation_type {
        ATTENDANCE_TARDINESS_UNAUTHORIZED_ABSENCE = 'Attendance/Tardiness/Unauthorized Absence',
        BREACH_OF_COMPANY_POLICY = 'Breach of Company Policy',
        CARELESSNESS_SAFETY = 'Carelessness/Safety',
        CONDUCT = 'Conduct',
        UNIFORM_POLICY = 'Uniform Policy',
        CREATING_A_DISTURBANCE = 'Creating a Disturbance',
        FAILURE_TO_FOLLOW_INSTRUCTIONS = 'Failure to Follow Instructions',
        INSUBORDINATION = 'Insubordination',
        PERFORMANCE = 'Performance',
        OTHER = 'Other',
    }


}

