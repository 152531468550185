/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EmployeePayrollWarning = {
    id: string;
    employee_payroll_id: string;
    code: EmployeePayrollWarning.code;
    reason: EmployeePayrollWarning.reason;
    deduction_type: EmployeePayrollWarning.deduction_type;
    actual_deduction_amount_cents?: number;
    expected_deduction_amount_cents?: number;
};

export namespace EmployeePayrollWarning {

    export enum code {
        SKIPPED = 'skipped',
        PARTIALLY_APPLIED = 'partially_applied',
    }

    export enum reason {
        NEGATIVE_NET_PAY = 'negative_net_pay',
        EXCEEDS_MAX_PERCENT = 'exceeds_max_percent',
    }

    export enum deduction_type {
        POST_TAX_DEDUCTION = 'post_tax_deduction',
        TAX = 'tax',
        BENEFIT = 'benefit',
    }


}

