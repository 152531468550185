/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type BreakTimingUserRuleAbsoluteTime = {
    comparison_operator: BreakTimingUserRuleAbsoluteTime.comparison_operator;
    logical_operator: BreakTimingUserRuleAbsoluteTime.logical_operator;
    time: string;
};

export namespace BreakTimingUserRuleAbsoluteTime {

    export enum comparison_operator {
        GREATER = 'GREATER',
        GREATER_OR_EQUAL = 'GREATER_OR_EQUAL',
        LESS = 'LESS',
        LESS_OR_EQUAL = 'LESS_OR_EQUAL',
    }

    export enum logical_operator {
        AND = 'AND',
        OR = 'OR',
    }


}

