/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CustomerStatus } from './CustomerStatus';
import type { PostalAddress } from './PostalAddress';

export type ActivityCustomer = {
    name: string;
    address?: string;
    postal_address?: PostalAddress;
    billing_name?: string;
    billing_address?: PostalAddress;
    us_state?: string;
    phone_number?: string;
    region_id?: string;
    status?: CustomerStatus;
    is_archived?: boolean;
    checkhq_id?: string;
    parent_id?: string;
    parent_billing_enabled?: boolean;
    type?: ActivityCustomer.type;
    block_mobile_clock_in?: boolean;
    id: string;
    firm_id: string;
    iana_timezone?: string;
};

export namespace ActivityCustomer {

    export enum type {
        SITE = 'SITE',
        PATROL = 'PATROL',
    }


}

