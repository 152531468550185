/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ActivityCustomer } from './ActivityCustomer';
import type { ActivityOfficer } from './ActivityOfficer';
import type { ActivityType } from './ActivityType';
import type { CustomReport } from './CustomReport';

export type CustomReportFiled = {
    activity_type: ActivityType;
    description?: string;
    id: string;
    title: string;
    index?: number;
    date?: string;
    shift_id?: string;
    customer_id: string;
    site_visit_id?: string;
    patrol_site?: ActivityCustomer;
    officer_id: string;
    officer: ActivityOfficer;
    created_at: string;
    occurred_at: string;
    post_name?: string;
    location?: string;
    custom_report: CustomReport;
    activity_type_literal?: CustomReportFiled.activity_type_literal;
};

export namespace CustomReportFiled {

    export enum activity_type_literal {
        CUSTOM_REPORT_FILED = 'CUSTOM_REPORT_FILED',
    }


}

