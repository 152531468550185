/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BasicCustomReportTemplate } from './BasicCustomReportTemplate';
import type { CustomReport } from './CustomReport';
import type { RunsheetTaskCustomer } from './RunsheetTaskCustomer';
import type { SiteVisitOperation } from './SiteVisitOperation';

export type RunsheetTask = {
    type: RunsheetTask.type;
    estimated_duration?: number;
    customer_id?: string;
    custom_report_template_id?: string;
    instructions?: string;
    task_details?: string;
    officer_summary?: string;
    reason?: string;
    active?: boolean;
    started_at?: string;
    completed_at?: string;
    id: string;
    runsheet_id?: string;
    customer?: RunsheetTaskCustomer;
    custom_report_template?: BasicCustomReportTemplate;
    custom_report?: CustomReport;
    site_visit_operation?: SiteVisitOperation;
};

export namespace RunsheetTask {

    export enum type {
        REPORT = 'Report',
        SITE_VISIT = 'Site Visit',
        ADMINISTRATIVE = 'Administrative',
    }


}

