import { useCallback, useEffect, useMemo, useState } from 'react'

type LocalStorageHookRet<T> = {
    value: T | null
    setValue: (arg: T) => void
}
export function useLocalStorage<T>(
    key: string,
    onChange?: () => void
): LocalStorageHookRet<T> {
    const [isClient, setIsClient] = useState<boolean>(false)
    const [intervalHandle, setIntervalHandle] = useState<NodeJS.Timer | null>(
        null
    )
    useEffect(() => {
        setIsClient(true)
    }, [])

    const strVal = isClient ? window.localStorage.getItem(key) : null

    useEffect(() => {
        if (!onChange || !isClient || intervalHandle) {
            return
        }

        setIntervalHandle(
            setInterval(() => {
                if (window.localStorage.getItem(key) !== strVal) {
                    onChange()
                }
            }, 3000)
        )

        return () => {
            if (intervalHandle) {
                clearInterval(intervalHandle)
            }
        }
    }, [isClient, intervalHandle, strVal, onChange, setIntervalHandle])

    const handleSetValue = useCallback(
        (v: T) =>
            isClient && window.localStorage.setItem(key, JSON.stringify(v)),
        [isClient]
    )

    return useMemo(() => {
        let parsedVal: T | null

        try {
            parsedVal = strVal ? JSON.parse(strVal) : null
        } catch {
            parsedVal = null
        }
        return {
            value: parsedVal || null,
            setValue: handleSetValue,
        }
    }, [key, strVal, handleSetValue])
}
