/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PayrollContactAssociation } from '../models/PayrollContactAssociation';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PayrollContactService {

    /**
     * List Payroll Contacts
     * @returns PayrollContactAssociation Successful Response
     * @throws ApiError
     */
    public static listPayrollContactsApiV1PayrollContactsGet(): CancelablePromise<Array<PayrollContactAssociation>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll_contacts',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Payroll Contact
     * @param officerId
     * @returns PayrollContactAssociation Successful Response
     * @throws ApiError
     */
    public static createPayrollContactApiV1PayrollContactsPost(
        officerId: string,
    ): CancelablePromise<Array<PayrollContactAssociation>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/payroll_contacts',
            query: {
                'officer_id': officerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Payroll Contact
     * @param payrollContactId
     * @returns PayrollContactAssociation Successful Response
     * @throws ApiError
     */
    public static removePayrollContactApiV1PayrollContactsPayrollContactIdDelete(
        payrollContactId: string,
    ): CancelablePromise<Array<PayrollContactAssociation>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/payroll_contacts/{payroll_contact_id}',
            path: {
                'payroll_contact_id': payrollContactId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
