/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { OfficerCategory } from './OfficerCategory';
import type { OfficerStatus } from './OfficerStatus';
import type { OfficerType } from './OfficerType';
import type { PostalAddress } from './PostalAddress';

export type OfficerForShift = {
    default_pay_rate?: number;
    pay_data_obscured?: boolean;
    name?: string;
    id?: string;
    given_name?: string;
    middle_name?: string;
    family_name?: string;
    nickname?: string;
    address?: string;
    postal_address?: PostalAddress;
    image_url?: string;
    phone_number?: string;
    hire_date?: string;
    date_of_birth?: string;
    email?: string;
    emergency_contact_name?: string;
    emergency_contact_phone_number?: string;
    employee_id?: string;
    employee_type?: OfficerForShift.employee_type;
    is_terminated?: boolean;
    termination_date?: string;
    termination_reason?: string;
    status?: OfficerStatus;
    category?: OfficerCategory;
    type?: OfficerType;
    bill_rate?: number;
    sms_delivery_failure?: boolean;
    role_id?: string;
    always_respect_officer_pay_rate?: boolean;
    exempt_employee?: boolean;
    salaried?: boolean;
    payroll_primary_workplace_customer_id?: (string | null);
    payroll_primary_workplace_non_customer_id?: (string | null);
    checkhq_id?: string;
    checkhq_contractor_id?: string;
    is_payroll_disabled?: boolean;
    job_title_id?: (string | null);
    workers_comp_code?: string;
    salary_weekly_hours?: number;
    firm_id: string;
};

export namespace OfficerForShift {

    export enum employee_type {
        SCHEDULABLE = 'Schedulable',
        NOT_SCHEDULABLE = 'Not Schedulable',
    }


}

