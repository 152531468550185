import useSWR from 'swr'
import { ImageSize } from 'src/generated'
import { SigningService } from 'src/generated'
import { captureException } from '@sentry/nextjs'
import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useState } from 'react'

type GetSignedUrlReturnType = {
    signedUrl?: string
    isLoading: boolean
    isError: boolean
}

type GetSignedUrlFuncType = (
    url?: string,
    lowerImageQuality?: boolean
) => GetSignedUrlReturnType

export const useGetSignedUrl: GetSignedUrlFuncType = (
    url,
    lowerImageQuality
) => {
    const { data, error } = useSWR(url, () => {
        if (!url) {
            return undefined
        }
        return SigningService.getSignedUrlApiV2SignGet(
            url,
            lowerImageQuality ? ImageSize.WIDTH_100 : null
        )
    })
    return {
        signedUrl: data,
        isLoading: !error && !data,
        isError: error,
    }
}

const useGetAccessToken: () => string | undefined = () => {
    const { getAccessTokenSilently } = useAuth0()
    const [accessToken, setAccessToken] = useState<string | undefined>(
        undefined
    )

    useEffect(() => {
        const getUserToken = async () => {
            const token = await getAccessTokenSilently()
            setAccessToken(token)
        }
        getUserToken()
    }, [getAccessTokenSilently])

    return accessToken
}

type GetCustomSignedUrlFuncType = (url?: string) => GetSignedUrlReturnType

export const useGetCustomSignedUrl: GetCustomSignedUrlFuncType = (url) => {
    const token = useGetAccessToken()
    const { data, error } = useSWR(url, () => {
        if (!url || !token) {
            return undefined
        }
        return SigningService.getSignedUrlCustomApiV2SignCustomGet(url, token)
    })
    return {
        signedUrl: data,
        isLoading: !error && !data,
        isError: error,
    }
}

export const downloadFile = (
    url: string,
    name: string,
    contentType: string
) => {
    SigningService.getSignedUrlApiV2SignGet(url).then((signedUrl) => {
        if (!signedUrl) {
            return
        }
        try {
            fetch(signedUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': contentType,
                },
            })
                .then((response) => response.blob())
                .then((blob) => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(new Blob([blob]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', name)

                    // Append to html link element page
                    document.body.appendChild(link)

                    // Start download
                    link.click()

                    // Clean up and remove the link
                    if (link.parentNode) {
                        link.parentNode.removeChild(link)
                    }
                })
        } catch (error) {
            // Special logic to try to get more info on why this specific error happens
            if (
                error instanceof Error &&
                (error.message == 'Load failed' ||
                    error.message == 'Failed to fetch')
            ) {
                captureException(error)
            }
            throw error
        }
    })
}

type ZendeskJwtType = {
    zendeskJwt: string | undefined
    isLoading: boolean
    isError: boolean
}
export const useGetZendeskJWT = (): ZendeskJwtType => {
    const options = {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        refreshWhenOffline: false,
        refreshWhenHidden: false,
        refreshInterval: 5 * 60 * 1000,
    }
    const { data, error } = useSWR(
        `zendesk_id`,
        () => SigningService.signZendeskJwtApiV1SignZendeskGet(),
        options
    )
    return {
        zendeskJwt: data,
        isLoading: !error && !data,
        isError: error,
    }
}
