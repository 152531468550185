/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PushSubscription = {
    platform: PushSubscription.platform;
    token: string;
    id: string;
    officer_id: string;
};

export namespace PushSubscription {

    export enum platform {
        IOS = 'ios',
        ANDROID = 'android',
    }


}

