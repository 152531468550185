/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Notification } from '../models/Notification';
import type { PushSubscription } from '../models/PushSubscription';
import type { PushSubscriptionCreate } from '../models/PushSubscriptionCreate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NotificationService {

    /**
     * Add Push Subscription
     * Create a record of the push subscription token from the users device and
     * then attempt to sync it with magicbell.
     * @param requestBody
     * @returns PushSubscription Successful Response
     * @throws ApiError
     */
    public static addPushSubscriptionApiV1PushSubscriptionPut(
        requestBody: PushSubscriptionCreate,
    ): CancelablePromise<PushSubscription> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/push_subscription',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Push Subscription
     * @param deviceToken
     * @param userId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deletePushSubscriptionApiV1PushSubscriptionDelete(
        deviceToken: string,
        userId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/push_subscription',
            query: {
                'device_token': deviceToken,
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Send Notification
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static sendNotificationApiV1NotificationPost(
        requestBody: Notification,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/notification',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Mark As Resolved
     * @param id
     * @returns Notification Successful Response
     * @throws ApiError
     */
    public static markAsResolvedApiV1NotificationIdResolvePost(
        id: string,
    ): CancelablePromise<Notification> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/notification/{id}/resolve',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
