/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PaymentToken } from './PaymentToken';

export type OneTimePayment = {
    firm_id: string;
    invoice_id: string;
    payment_request_id: string;
    tokens: Array<PaymentToken>;
    outstanding_amount_in_cents: number;
    amount_in_cents: number;
    fees_amount_in_cents?: (number | null);
    payment_type: OneTimePayment.payment_type;
    given_name: string;
    family_name: string;
    email: string;
    phone_number?: string;
    zip_code?: string;
};

export namespace OneTimePayment {

    export enum payment_type {
        CREDIT_CARD = 'Credit Card',
        ACH = 'ACH',
    }


}

