/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ActivityType } from './ActivityType';

export type PostOrderAcknowledgedUpdate = {
    activity_type?: ActivityType;
    description?: string;
    occurred_at?: string;
    title: string;
    location?: string;
    activity_type_literal?: PostOrderAcknowledgedUpdate.activity_type_literal;
};

export namespace PostOrderAcknowledgedUpdate {

    export enum activity_type_literal {
        POST_ORDER_ACKNOWLEDGED = 'POST_ORDER_ACKNOWLEDGED',
    }


}

