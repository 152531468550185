/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum WageCategoryReasons {
    DAILY_OT = 'DAILY_OT',
    DAILY_DT = 'DAILY_DT',
    WEEKLY_OT = 'WEEKLY_OT',
    WEEKLY_DT = 'WEEKLY_DT',
    DAY_RULE_OT = 'DAY_RULE_OT',
    DAY_RULE_DT = 'DAY_RULE_DT',
    CONSECUTIVE_HOURS_OT = 'CONSECUTIVE_HOURS_OT',
}
