/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PaymentToken = {
    token: string;
    token_type: PaymentToken.token_type;
};

export namespace PaymentToken {

    export enum token_type {
        CREDIT_CARD = 'Credit Card',
        ACCOUNT_NUMBER = 'Account Number',
        ROUTING_NUMBER = 'Routing Number',
        BANK = 'Bank',
    }


}

