/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TourScheduleType {
    SPECIFIC_TIMES = 'Specific Times',
    INTERVAL = 'Interval',
    RANDOM_TIMES = 'Random Times',
    NO_SCHEDULE = 'No Schedule',
}
