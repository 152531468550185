import React from 'react'
import { SWRConfig } from 'swr'
import { toast } from 'react-toastify'
import * as Sentry from '@sentry/nextjs'
import { Link, Typography } from '@mui/material'

export const SWRConfigWrapper = ({
    children,
}: React.PropsWithChildren<unknown>): JSX.Element => {
    return (
        <>
            <SWRConfig
                value={{
                    refreshInterval: 90000,
                    onError: (error: any, key: any) => {
                        const maybeRefreshLinkText = !error.status ? (
                            <>
                                Click{' '}
                                <Link
                                    sx={{
                                        color: '#4F46E5',
                                        textDecorationColor: '#4F46E5',
                                    }}
                                    onClick={() => window.location.reload()}
                                >
                                    here
                                </Link>{' '}
                                to refresh the page.
                            </>
                        ) : (
                            ''
                        )
                        if (error.status !== 403 && error.status !== 404) {
                            toast.error(
                                <Typography>
                                    {error.message}. {maybeRefreshLinkText}
                                </Typography>
                            )
                            // logs client-side exceptions iff server is down, etc
                            if (error.status >= 500 || !error.status) {
                                Sentry.captureException(error)
                            }
                        }
                    },
                }}
            >
                {children}
            </SWRConfig>
        </>
    )
}
