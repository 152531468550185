/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PushSubscriptionCreate = {
    platform: PushSubscriptionCreate.platform;
    token: string;
};

export namespace PushSubscriptionCreate {

    export enum platform {
        IOS = 'ios',
        ANDROID = 'android',
    }


}

