/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum BelltowerReportType {
    ACTIVITY = 'Activity',
    CONSOLIDATED_ACTIVITY = 'Consolidated Activity',
    INCIDENT = 'Incident',
    CUSTOM = 'Custom',
}
