/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WorkersCompCode } from '../models/WorkersCompCode';
import type { WorkersCompCodeCreate } from '../models/WorkersCompCodeCreate';
import type { WorkersCompCodeUpdate } from '../models/WorkersCompCodeUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WorkersCompCodeService {

    /**
     * Get Workers Comp Codes
     * Get workers comp code for firm.
     * @returns WorkersCompCode Successful Response
     * @throws ApiError
     */
    public static getWorkersCompCodesApiV1WorkersCompCodeGet(): CancelablePromise<Array<WorkersCompCode>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/workers_comp_code',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Workers Comp Code
     * Creates a workers comp code.
     * @param requestBody
     * @returns WorkersCompCode Successful Response
     * @throws ApiError
     */
    public static createWorkersCompCodeApiV1WorkersCompCodePost(
        requestBody: WorkersCompCodeCreate,
    ): CancelablePromise<WorkersCompCode> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/workers_comp_code',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Workers Comp Code
     * Updates a workers comp code.
     * @param id
     * @param requestBody
     * @returns WorkersCompCode Successful Response
     * @throws ApiError
     */
    public static updateWorkersCompCodeApiV1WorkersCompCodeIdPut(
        id: string,
        requestBody: WorkersCompCodeUpdate,
    ): CancelablePromise<WorkersCompCode> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/workers_comp_code/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Workers Comp Code
     * Deletes a workers comp code.
     * @param id
     * @returns string Successful Response
     * @throws ApiError
     */
    public static deleteWorkersCompCodeApiV1WorkersCompCodeIdDelete(
        id: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/workers_comp_code/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
