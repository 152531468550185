import useSWRImmutable from 'swr'

import { BelfryUser, UserService } from '../generated'

type LoadUserType = {
    user: BelfryUser | undefined
    isLoading: boolean
    isError: boolean
}
type LoadUserFunc = () => LoadUserType
export const useLoadUser: LoadUserFunc = () => {
    const { data, error } = useSWRImmutable(`user`, () =>
        UserService.getUserDetailsApiV1UserGet()
    )

    return {
        user: data,
        isLoading: !data && !error,
        isError: error,
    }
}
