/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivePaymentRequest } from '../models/ActivePaymentRequest';
import type { FirmUpdate } from '../models/FirmUpdate';
import type { OneTimePayment } from '../models/OneTimePayment';
import type { Page_PaymentDeposit_ } from '../models/Page_PaymentDeposit_';
import type { Page_PaymentTransactionSimple_ } from '../models/Page_PaymentTransactionSimple_';
import type { PaymentAccount } from '../models/PaymentAccount';
import type { PaymentAccountUpdate } from '../models/PaymentAccountUpdate';
import type { PaymentDepositFilter } from '../models/PaymentDepositFilter';
import type { PaymentFirmOnboardingResponse } from '../models/PaymentFirmOnboardingResponse';
import type { PaymentReceiptResponse } from '../models/PaymentReceiptResponse';
import type { PaymentResponse } from '../models/PaymentResponse';
import type { PaymentTransactionDetails } from '../models/PaymentTransactionDetails';
import type { PaymentTransactionFilter } from '../models/PaymentTransactionFilter';
import type { PaymentTransactionsSummary } from '../models/PaymentTransactionsSummary';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PaymentService {

    /**
     * Read Active Payment Request No Auth
     * Retrieve an active payment request (if any).
     * @param id
     * @returns ActivePaymentRequest Successful Response
     * @throws ApiError
     */
    public static readActivePaymentRequestNoAuthApiV1PaymentrequestIdActiveGet(
        id: string,
    ): CancelablePromise<ActivePaymentRequest> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/paymentrequest/{id}/active',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payments Submit One Time
     * @param requestBody
     * @returns PaymentResponse Successful Response
     * @throws ApiError
     */
    public static paymentsSubmitOneTimeApiV1PaymentsOneTimePost(
        requestBody: OneTimePayment,
    ): CancelablePromise<PaymentResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/payments/one_time',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search Payment Transactions
     * @param requestBody
     * @param page Page number
     * @param size Page size
     * @returns Page_PaymentTransactionSimple_ Successful Response
     * @throws ApiError
     */
    public static searchPaymentTransactionsApiV1PaymenttransactionsPost(
        requestBody: PaymentTransactionFilter,
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_PaymentTransactionSimple_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/paymenttransactions',
            query: {
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Payment Transaction
     * @param transactionId
     * @returns PaymentTransactionDetails Successful Response
     * @throws ApiError
     */
    public static getPaymentTransactionApiV1PaymenttransactionsTransactionIdGet(
        transactionId: string,
    ): CancelablePromise<PaymentTransactionDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/paymenttransactions/{transaction_id}',
            path: {
                'transaction_id': transactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Payment Transactions Summary
     * @param requestBody
     * @returns PaymentTransactionsSummary Successful Response
     * @throws ApiError
     */
    public static getPaymentTransactionsSummaryApiV1PaymenttransactionsSummaryPost(
        requestBody: PaymentTransactionFilter,
    ): CancelablePromise<PaymentTransactionsSummary> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/paymenttransactions/summary',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Send Payment Transaction Receipt
     * @param transactionId
     * @param email
     * @returns PaymentReceiptResponse Successful Response
     * @throws ApiError
     */
    public static sendPaymentTransactionReceiptApiV1PaymenttransactionTransactionIdSendreceiptPost(
        transactionId: string,
        email: string,
    ): CancelablePromise<PaymentReceiptResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/paymenttransaction/{transaction_id}/sendreceipt',
            path: {
                'transaction_id': transactionId,
            },
            query: {
                'email': email,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Initial Firm Onboarding For Payments
     * @param firmId
     * @param requestBody
     * @returns PaymentFirmOnboardingResponse Successful Response
     * @throws ApiError
     */
    public static initialFirmOnboardingForPaymentsApiV1PaymentonboardingFirmIdInitPost(
        firmId: string,
        requestBody: FirmUpdate,
    ): CancelablePromise<PaymentFirmOnboardingResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/paymentonboarding/{firm_id}/init',
            path: {
                'firm_id': firmId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Payment Account For Contract
     * @param contractId
     * @returns PaymentAccount Successful Response
     * @throws ApiError
     */
    public static getPaymentAccountForContractApiV1PaymentaccountContractIdGet(
        contractId: string,
    ): CancelablePromise<PaymentAccount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/paymentaccount/{contract_id}',
            path: {
                'contract_id': contractId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Payment Account For Contract
     * @param contractId
     * @param requestBody
     * @returns PaymentAccount Successful Response
     * @throws ApiError
     */
    public static updatePaymentAccountForContractApiV1PaymentaccountContractIdPut(
        contractId: string,
        requestBody: PaymentAccountUpdate,
    ): CancelablePromise<PaymentAccount> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/paymentaccount/{contract_id}',
            path: {
                'contract_id': contractId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search Payment Deposits
     * @param requestBody
     * @param page Page number
     * @param size Page size
     * @returns Page_PaymentDeposit_ Successful Response
     * @throws ApiError
     */
    public static searchPaymentDepositsApiV1PaymenttransactionsDepositsPost(
        requestBody: PaymentDepositFilter,
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_PaymentDeposit_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/paymenttransactions/deposits',
            query: {
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Batch Details
     * @param batchNumber
     * @returns PaymentTransactionDetails Successful Response
     * @throws ApiError
     */
    public static getBatchDetailsApiV1PaymenttransactionsDepositsBatchNumberGet(
        batchNumber: string,
    ): CancelablePromise<Array<PaymentTransactionDetails>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/paymenttransactions/deposits/{batch_number}',
            path: {
                'batch_number': batchNumber,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Export Csv Payment Transactions
     * Return the csv of payment transactions
     * @param start
     * @param end
     * @returns any Successful Response
     * @throws ApiError
     */
    public static exportCsvPaymentTransactionsApiV1PaymenttransactionsExportCsvStartEndGet(
        start: string,
        end: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/paymenttransactions/export_csv/{start}/{end}',
            path: {
                'start': start,
                'end': end,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
