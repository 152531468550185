/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ActivityType } from './ActivityType';

export type ClockOutUpdate = {
    activity_type?: ActivityType;
    description?: string;
    occurred_at?: string;
    title: string;
    location?: string;
    activity_type_literal?: ClockOutUpdate.activity_type_literal;
};

export namespace ClockOutUpdate {

    export enum activity_type_literal {
        CLOCK_OUT = 'CLOCK_OUT',
    }


}

