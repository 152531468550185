/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuditRecordContactMapping } from '../models/AuditRecordContactMapping';
import type { AuditRecordDistributionSetting } from '../models/AuditRecordDistributionSetting';
import type { DistributionSetting } from '../models/DistributionSetting';
import type { DistributionSettingCreate } from '../models/DistributionSettingCreate';
import type { DistributionSettingUpdate } from '../models/DistributionSettingUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DistributionSettingService {

    /**
     * Read Distribution Setting For Customer
     * @param customerId
     * @returns DistributionSetting Successful Response
     * @throws ApiError
     */
    public static readDistributionSettingForCustomerApiV1DistributionsettingCustomerIdGet(
        customerId: string,
    ): CancelablePromise<DistributionSetting> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/distributionsetting/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Distribution Setting
     * @param customerId
     * @param requestBody
     * @returns DistributionSetting Successful Response
     * @throws ApiError
     */
    public static createDistributionSettingApiV1DistributionsettingCustomerIdPost(
        customerId: string,
        requestBody: DistributionSettingCreate,
    ): CancelablePromise<DistributionSetting> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/distributionsetting/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Distribution Setting
     * @param customerId
     * @param requestBody
     * @returns DistributionSetting Successful Response
     * @throws ApiError
     */
    public static updateDistributionSettingApiV1DistributionsettingCustomerIdPut(
        customerId: string,
        requestBody: DistributionSettingUpdate,
    ): CancelablePromise<DistributionSetting> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/distributionsetting/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Distribution Setting For Audit Record
     * @param distributionSettingId
     * @returns AuditRecordDistributionSetting Successful Response
     * @throws ApiError
     */
    public static readDistributionSettingForAuditRecordApiV1DistributionsettingAuditRecordDistributionSettingIdGet(
        distributionSettingId: string,
    ): CancelablePromise<AuditRecordDistributionSetting> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/distributionsetting-audit-record/{distribution_setting_id}',
            path: {
                'distribution_setting_id': distributionSettingId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Contact Mapping Association For Audit Record
     * @param contactMappingId
     * @returns AuditRecordContactMapping Successful Response
     * @throws ApiError
     */
    public static readContactMappingAssociationForAuditRecordApiV1ContactmappingassociationAuditRecordContactMappingIdGet(
        contactMappingId: string,
    ): CancelablePromise<AuditRecordContactMapping> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/contactmappingassociation-audit-record/{contact_mapping_id}',
            path: {
                'contact_mapping_id': contactMappingId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
