/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PatrolSiteConfiguration } from '../models/PatrolSiteConfiguration';
import type { PatrolSiteConfigurationCreate } from '../models/PatrolSiteConfigurationCreate';
import type { PatrolSiteConfigurationUpdate } from '../models/PatrolSiteConfigurationUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PatrolSiteConfigurationService {

    /**
     * Get Patrol Site Configurations For Customer
     * @param id
     * @returns PatrolSiteConfiguration Successful Response
     * @throws ApiError
     */
    public static getPatrolSiteConfigurationsForCustomerApiV1CustomerIdPatrolShiftGet(
        id: string,
    ): CancelablePromise<Array<PatrolSiteConfiguration>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/customer/{id}/patrol-shift',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Patrol Site Configuration
     * @param id
     * @param requestBody
     * @returns PatrolSiteConfiguration Successful Response
     * @throws ApiError
     */
    public static createPatrolSiteConfigurationApiV1CustomerIdPatrolShiftPost(
        id: string,
        requestBody: PatrolSiteConfigurationCreate,
    ): CancelablePromise<PatrolSiteConfiguration> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/customer/{id}/patrol-shift',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Patrol Site Configuration
     * @param id
     * @param requestBody
     * @returns PatrolSiteConfiguration Successful Response
     * @throws ApiError
     */
    public static updatePatrolSiteConfigurationApiV1CustomerIdPatrolShiftPut(
        id: string,
        requestBody: PatrolSiteConfigurationUpdate,
    ): CancelablePromise<PatrolSiteConfiguration> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/customer/{id}/patrol-shift',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Patrol Site Configuration
     * @param id
     * @param patrolSiteConfigurationId
     * @returns PatrolSiteConfiguration Successful Response
     * @throws ApiError
     */
    public static deletePatrolSiteConfigurationApiV1CustomerIdPatrolSiteConfigurationPatrolSiteConfigurationIdDelete(
        id: string,
        patrolSiteConfigurationId: string,
    ): CancelablePromise<Array<PatrolSiteConfiguration>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/customer/{id}/patrol-site-configuration/{patrol_site_configuration_id}',
            path: {
                'id': id,
                'patrol_site_configuration_id': patrolSiteConfigurationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
