/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ActivityType } from './ActivityType';
import type { TourInstanceCompletedBackOfficeUpdate } from './TourInstanceCompletedBackOfficeUpdate';

export type TourCompletedUpdate = {
    activity_type?: ActivityType;
    description?: string;
    occurred_at?: string;
    title: string;
    location?: string;
    tour_instance_id: string;
    tour_instance_back_office_update?: TourInstanceCompletedBackOfficeUpdate;
    activity_type_literal?: TourCompletedUpdate.activity_type_literal;
};

export namespace TourCompletedUpdate {

    export enum activity_type_literal {
        TOUR_ENDED = 'TOUR_ENDED',
    }


}

