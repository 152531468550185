/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OfficerGrossPayViewParams } from '../models/OfficerGrossPayViewParams';
import type { OfficerReliefOfficerParams } from '../models/OfficerReliefOfficerParams';
import type { Settings } from '../models/Settings';
import type { SettingsForEmployeePortal } from '../models/SettingsForEmployeePortal';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SettingsService {

    /**
     * Load Firm Settings For Employee Portal
     * @returns SettingsForEmployeePortal Successful Response
     * @throws ApiError
     */
    public static loadFirmSettingsForEmployeePortalApiV1EmployeePortalSettingsGet(): CancelablePromise<SettingsForEmployeePortal> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/employee_portal/settings',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Settings
     * @returns Settings Successful Response
     * @throws ApiError
     */
    public static getSettingsApiV1SettingsGet(): CancelablePromise<Settings> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/settings',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Settings
     * @param requestBody
     * @returns Settings Successful Response
     * @throws ApiError
     */
    public static updateSettingsApiV1SettingsPost(
        requestBody: Settings,
    ): CancelablePromise<Settings> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/settings',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Relief Officer Settings For Officer
     * @returns OfficerReliefOfficerParams Successful Response
     * @throws ApiError
     */
    public static getReliefOfficerSettingsForOfficerApiV1ReliefOfficerSettingsOfficerGet(): CancelablePromise<OfficerReliefOfficerParams> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/relief-officer/settings/officer',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Gross Pay View Settings For Officer
     * @returns OfficerGrossPayViewParams Successful Response
     * @throws ApiError
     */
    public static getGrossPayViewSettingsForOfficerApiV1GrossPayViewSettingsOfficerGet(): CancelablePromise<OfficerGrossPayViewParams> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/gross-pay-view/settings/officer',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
