/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EverifyCaseUpdate = {
    case_number: string;
    case_status?: EverifyCaseUpdate.case_status;
    case_close_ts?: string;
};

export namespace EverifyCaseUpdate {

    export enum case_status {
        OPEN = 'OPEN',
        CLOSED = 'CLOSED',
    }


}

