/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TimekeepingShiftOffer = {
    id: string;
    times_offered: number;
    shift_offer_status: TimekeepingShiftOffer.shift_offer_status;
    shift_instance_id: string;
    last_offered_at: string;
    shift_id: string;
    shift_start_date_time: string;
    shift_end_date_time: string;
};

export namespace TimekeepingShiftOffer {

    export enum shift_offer_status {
        OFFERED = 'OFFERED',
        REOFFERED = 'REOFFERED',
        QUEUED = 'QUEUED',
        CONFIRMED = 'CONFIRMED',
    }


}

