/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { WireDetails } from './WireDetails';

export type WirePayment = {
    wire_details: WireDetails;
    status: WirePayment.status;
};

export namespace WirePayment {

    export enum status {
        DRAFT = 'draft',
        PROCESSING = 'processing',
        PAID = 'paid',
        FAILED = 'failed',
        REFUNDED = 'refunded',
        REFUND_PROCESSING = 'refund_processing',
        REFUND_FAILED = 'refund_failed',
        REVERSAL_PROCESSING = 'reversal_processing',
        REVERSAL_FAILED = 'reversal_failed',
        REVERSED = 'reversed',
        CANCELED = 'canceled',
    }


}

