/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AvailabilityBlock } from '../models/AvailabilityBlock';
import type { AvailabilityBlockCreateForOfficer } from '../models/AvailabilityBlockCreateForOfficer';
import type { AvailabilityBlockUpdate } from '../models/AvailabilityBlockUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AvailabilityBlockService {

    /**
     * Read Availability Blocks
     * @returns AvailabilityBlock Successful Response
     * @throws ApiError
     */
    public static readAvailabilityBlocksApiV1AvailabilityBlockGet(): CancelablePromise<Array<AvailabilityBlock>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/availability_block',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Availability Block
     * @param requestBody
     * @returns AvailabilityBlock Successful Response
     * @throws ApiError
     */
    public static createAvailabilityBlockApiV1AvailabilityBlockPost(
        requestBody: AvailabilityBlockCreateForOfficer,
    ): CancelablePromise<AvailabilityBlock> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/availability_block',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Availability Block
     * @param id
     * @param requestBody
     * @returns AvailabilityBlock Successful Response
     * @throws ApiError
     */
    public static updateAvailabilityBlockApiV1AvailabilityBlockIdPut(
        id: string,
        requestBody: AvailabilityBlockUpdate,
    ): CancelablePromise<AvailabilityBlock> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/availability_block/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Availability Block
     * @param id
     * @returns AvailabilityBlock Successful Response
     * @throws ApiError
     */
    public static deleteAvailabilityBlockApiV1AvailabilityBlockIdDelete(
        id: string,
    ): CancelablePromise<AvailabilityBlock> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/availability_block/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
