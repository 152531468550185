/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Weekdays {
    MONDAY = 0,
    TUESDAY = 1,
    WEDNESDAY = 2,
    THURSDAY = 3,
    FRIDAY = 4,
    SATURDAY = 5,
    SUNDAY = 6,
}
