import { Link, Typography } from '@mui/material'
import { ApiError } from '../../../generated'
import { toast } from 'react-toastify'
import NextLink from 'next/link'

export const officerCollisionToastError = (error: ApiError) => {
    toast.error(
        error?.body?.detail?.message ? (
            <div>
                <Typography>
                    {`${error?.message}: ${error?.body?.detail?.message}`}
                </Typography>

                <NextLink
                    href={error?.body?.detail.link}
                    passHref={true}
                    legacyBehavior
                >
                    <Link fontWeight={700}>Jump to Employees Page</Link>
                </NextLink>
            </div>
        ) : (
            error?.body?.detail || 'Something went wrong'
        ),
        {
            autoClose: false,
        }
    )
}
