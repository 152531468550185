/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConsolidatedActivityReport } from '../models/ConsolidatedActivityReport';
import type { ConsolidatedActivityReportUpdate } from '../models/ConsolidatedActivityReportUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ConsolidatedActivityReportService {

    /**
     * Update Consolidated Activity Report
     * @param id
     * @param customerId
     * @param requestBody
     * @returns ConsolidatedActivityReport Successful Response
     * @throws ApiError
     */
    public static updateConsolidatedActivityReportApiV1CustomerCustomerIdConsolidatedActivityReportsIdPut(
        id: string,
        customerId: string,
        requestBody: ConsolidatedActivityReportUpdate,
    ): CancelablePromise<ConsolidatedActivityReport> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/customer/{customer_id}/consolidated-activity-reports/{id}',
            path: {
                'id': id,
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Archive Consolidated Activity Report
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public static archiveConsolidatedActivityReportApiV1ConsolidatedActivityReportsIdDelete(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/consolidated-activity-reports/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
