/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EverifyCaseCreate = {
    case_number: string;
    case_status?: EverifyCaseCreate.case_status;
    case_close_ts?: string;
    officer_id: string;
};

export namespace EverifyCaseCreate {

    export enum case_status {
        OPEN = 'OPEN',
        CLOSED = 'CLOSED',
    }


}

