/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PaymentRequestForTransaction = {
    status: PaymentRequestForTransaction.status;
    is_pass_through_fees_enabled: boolean;
    id: string;
    firm_id: string;
    invoice_id: string;
    generated_at: string;
    status_last_updated_at: string;
};

export namespace PaymentRequestForTransaction {

    export enum status {
        OPEN = 'Open',
        IN_PROGRESS = 'In Progress',
        CLOSED = 'Closed',
        CLOSED_WITH_PAYMENT = 'Closed with Payment',
    }


}

