/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EmployeeOrContractorPayrollOfficer } from './EmployeeOrContractorPayrollOfficer';
import type { EmployeePayrollEarning } from './EmployeePayrollEarning';
import type { EmployeePayrollReimbursement } from './EmployeePayrollReimbursement';
import type { EmployeePayrollWarning } from './EmployeePayrollWarning';

export type EmployeePayroll = {
    payroll_id: string;
    id: string;
    officer_id: string;
    officer_name?: string;
    amount_cents: number;
    reimbursement_amount_cents?: number;
    net_pay_cents?: number;
    regular_amount_cents?: number;
    regular_hours?: number;
    salaried_amount_cents?: number;
    salaried_hours?: number;
    overtime_amount_cents?: number;
    overtime_hours?: number;
    double_overtime_amount_cents?: number;
    double_overtime_hours?: number;
    pto_amount_cents?: number;
    pto_hours?: number;
    sick_amount_cents?: number;
    sick_hours?: number;
    holiday_amount_cents?: number;
    holiday_hours?: number;
    spb_amount_cents?: number;
    officer: EmployeeOrContractorPayrollOfficer;
    payment_method?: EmployeePayroll.payment_method;
    earnings: Array<EmployeePayrollEarning>;
    reimbursements?: Array<EmployeePayrollReimbursement>;
    warnings?: Array<EmployeePayrollWarning>;
};

export namespace EmployeePayroll {

    export enum payment_method {
        DIRECT_DEPOSIT = 'direct_deposit',
        MANUAL = 'manual',
    }


}

