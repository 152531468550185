/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CHQOffCycleOptions } from './CHQOffCycleOptions';

export type TerminateOfficerOffCycleFinalPayroll = {
    payday_date: string;
    payment_method: TerminateOfficerOffCycleFinalPayroll.payment_method;
    off_cycle_options: CHQOffCycleOptions;
};

export namespace TerminateOfficerOffCycleFinalPayroll {

    export enum payment_method {
        DIRECT_DEPOSIT = 'direct_deposit',
        MANUAL = 'manual',
    }


}

