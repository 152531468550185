/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuditRecordTour } from '../models/AuditRecordTour';
import type { Tour } from '../models/Tour';
import type { TourCreate } from '../models/TourCreate';
import type { TourMetadata } from '../models/TourMetadata';
import type { TourUpdate } from '../models/TourUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TourService {

    /**
     * Read Tours For Audit Record
     * Return the tour with provided id.
     * @param tourId
     * @returns AuditRecordTour Successful Response
     * @throws ApiError
     */
    public static readToursForAuditRecordApiV1TourAuditRecordTourIdGet(
        tourId: string,
    ): CancelablePromise<AuditRecordTour> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/tour-audit-record/{tour_id}',
            path: {
                'tour_id': tourId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Tours
     * Return the tours for a customer.
     * @param customerId
     * @returns Tour Successful Response
     * @throws ApiError
     */
    public static readToursApiV1ToursCustomerIdGet(
        customerId: string,
    ): CancelablePromise<Array<Tour>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/tours/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Tour
     * @param customerId
     * @param requestBody
     * @returns Tour Successful Response
     * @throws ApiError
     */
    public static createTourApiV1ToursCustomerIdPost(
        customerId: string,
        requestBody: TourCreate,
    ): CancelablePromise<Tour> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/tours/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Tour Metadatas
     * Return the tours for a customer.
     * @param customerId
     * @returns TourMetadata Successful Response
     * @throws ApiError
     */
    public static readTourMetadatasApiV1TourMetadatasCustomerIdGet(
        customerId: string,
    ): CancelablePromise<Array<TourMetadata>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/tour-metadatas/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Tour
     * Return the tours for a customer.
     * @param customerId
     * @param tourId
     * @returns Tour Successful Response
     * @throws ApiError
     */
    public static readTourApiV1ToursCustomerIdTourIdGet(
        customerId: string,
        tourId: string,
    ): CancelablePromise<Tour> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/tours/{customer_id}/{tour_id}',
            path: {
                'customer_id': customerId,
                'tour_id': tourId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Tour
     * @param customerId
     * @param tourId
     * @param requestBody
     * @returns Tour Successful Response
     * @throws ApiError
     */
    public static updateTourApiV1ToursCustomerIdTourIdPut(
        customerId: string,
        tourId: string,
        requestBody: TourUpdate,
    ): CancelablePromise<Tour> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/tours/{customer_id}/{tour_id}',
            path: {
                'customer_id': customerId,
                'tour_id': tourId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Tour
     * @param customerId
     * @param tourId
     * @returns Tour Successful Response
     * @throws ApiError
     */
    public static deleteTourApiV1ToursCustomerIdTourIdDelete(
        customerId: string,
        tourId: string,
    ): CancelablePromise<Tour> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/tours/{customer_id}/{tour_id}',
            path: {
                'customer_id': customerId,
                'tour_id': tourId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
