/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuxBillingItem } from '../models/AuxBillingItem';
import type { AuxBillingItemCreate } from '../models/AuxBillingItemCreate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RecurringBillingItemService {

    /**
     * Read Recurring Billing Items
     * Return the billing items for a contract
     * @param contractId
     * @returns AuxBillingItem Successful Response
     * @throws ApiError
     */
    public static readRecurringBillingItemsApiV1RecurringBillingItemContractIdGet(
        contractId: string,
    ): CancelablePromise<Array<AuxBillingItem>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/recurring_billing_item/{contract_id}',
            path: {
                'contract_id': contractId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Recurring Billing Item
     * @param contractId
     * @param requestBody
     * @returns AuxBillingItem Successful Response
     * @throws ApiError
     */
    public static createRecurringBillingItemApiV1RecurringBillingItemContractIdPost(
        contractId: string,
        requestBody: AuxBillingItemCreate,
    ): CancelablePromise<Array<AuxBillingItem>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/recurring_billing_item/{contract_id}',
            path: {
                'contract_id': contractId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Recurring Billing Item
     * @param contractId
     * @param recurringBillingItemId
     * @returns AuxBillingItem Successful Response
     * @throws ApiError
     */
    public static deleteRecurringBillingItemApiV1RecurringBillingItemContractIdRecurringBillingItemIdDelete(
        contractId: string,
        recurringBillingItemId: string,
    ): CancelablePromise<Array<AuxBillingItem>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/recurring_billing_item/{contract_id}/{recurring_billing_item_id}',
            path: {
                'contract_id': contractId,
                'recurring_billing_item_id': recurringBillingItemId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
