/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuditRecordContract } from '../models/AuditRecordContract';
import type { Contract } from '../models/Contract';
import type { ContractCreate } from '../models/ContractCreate';
import type { ContractUpdate } from '../models/ContractUpdate';
import type { ContractWithCustomer } from '../models/ContractWithCustomer';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ContractService {

    /**
     * Read Billing Contract
     * Return the contracts for customer and parent customers enabled for billing
     * @returns ContractWithCustomer Successful Response
     * @throws ApiError
     */
    public static readBillingContractApiV1ContractsBillingGet(): CancelablePromise<Array<ContractWithCustomer>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/contracts/billing',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Contract
     * @param customerId
     * @param requestBody
     * @param isParent
     * @returns Contract Successful Response
     * @throws ApiError
     */
    public static createContractApiV1ContractCustomerIdPost(
        customerId: string,
        requestBody: ContractCreate,
        isParent: boolean = false,
    ): CancelablePromise<Contract> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/contract/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            query: {
                'is_parent': isParent,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Contract
     * Return the contracts for a customer.
     * @param customerId
     * @param isParent
     * @returns Contract Successful Response
     * @throws ApiError
     */
    public static readContractApiV1ContractCustomerIdGet(
        customerId: string,
        isParent: boolean = false,
    ): CancelablePromise<Contract> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/contract/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            query: {
                'is_parent': isParent,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Contract
     * @param customerId
     * @param requestBody
     * @param isParent
     * @returns Contract Successful Response
     * @throws ApiError
     */
    public static updateContractApiV1ContractCustomerIdPut(
        customerId: string,
        requestBody: ContractUpdate,
        isParent: boolean = false,
    ): CancelablePromise<Contract> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/contract/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            query: {
                'is_parent': isParent,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Contract
     * @param customerId
     * @param isParent
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteContractApiV1ContractCustomerIdDelete(
        customerId: string,
        isParent: boolean = false,
    ): CancelablePromise<(Contract | null)> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/contract/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            query: {
                'is_parent': isParent,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Contract Audit Record
     * Return the audit record contract for a given ID.
     * @param contractId
     * @returns AuditRecordContract Successful Response
     * @throws ApiError
     */
    public static readContractAuditRecordApiV1ContractAuditRecordContractIdGet(
        contractId: string,
    ): CancelablePromise<AuditRecordContract> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/contract-audit-record/{contract_id}',
            path: {
                'contract_id': contractId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
