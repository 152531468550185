/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmployeeAsMassAnnouncementRecipient } from '../models/EmployeeAsMassAnnouncementRecipient';
import type { MassAnnouncement } from '../models/MassAnnouncement';
import type { MassAnnouncementCreate } from '../models/MassAnnouncementCreate';
import type { Page_MassAnnouncement_ } from '../models/Page_MassAnnouncement_';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MassAnnouncementService {

    /**
     * Send Mass Announcement
     * Send a mass announcement
     * @param requestBody
     * @returns MassAnnouncement Successful Response
     * @throws ApiError
     */
    public static sendMassAnnouncementApiV1MassAnnouncementPost(
        requestBody: MassAnnouncementCreate,
    ): CancelablePromise<MassAnnouncement> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/mass_announcement',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Eligible Mass Announcement Recipients
     * Endpoint for users to get eligible recipients for Mass Announcements and their group memberships
     * @returns EmployeeAsMassAnnouncementRecipient Successful Response
     * @throws ApiError
     */
    public static getEligibleMassAnnouncementRecipientsApiV1MassAnnouncementRecipientsGet(): CancelablePromise<Array<EmployeeAsMassAnnouncementRecipient>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/mass_announcement/recipients',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Mass Announcements
     * Endpoint for back office users to get all mass announcements ordered by sent time
     * @returns MassAnnouncement Successful Response
     * @throws ApiError
     */
    public static getMassAnnouncementsApiV1MassAnnouncementGet(): CancelablePromise<Array<MassAnnouncement>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/mass_announcement/',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Mass Announcements Paginated
     * Endpoint for back office users to get all mass announcements ordered by sent time in a paginated fashion.
     * Optionally filters announcements based on a search query.
     * @param search
     * @param page Page number
     * @param size Page size
     * @returns Page_MassAnnouncement_ Successful Response
     * @throws ApiError
     */
    public static getMassAnnouncementsPaginatedApiV1MassAnnouncementPaginatedGet(
        search?: (string | null),
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_MassAnnouncement_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/mass_announcement/paginated',
            query: {
                'search': search,
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
