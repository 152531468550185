/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReimbursementPolicy } from '../models/ReimbursementPolicy';
import type { ReimbursementPolicyCreate } from '../models/ReimbursementPolicyCreate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReimbursementPolicyService {

    /**
     * List Reimbursement Policies
     * @returns ReimbursementPolicy Successful Response
     * @throws ApiError
     */
    public static listReimbursementPoliciesApiV1ReimbursementGet(): CancelablePromise<Array<ReimbursementPolicy>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/reimbursement',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Reimbursement Policy
     * @param requestBody
     * @returns ReimbursementPolicy Successful Response
     * @throws ApiError
     */
    public static createReimbursementPolicyApiV1ReimbursementPost(
        requestBody: ReimbursementPolicyCreate,
    ): CancelablePromise<Array<ReimbursementPolicy>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/reimbursement',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Officers To Reimbursement Policy
     * @param reimbursementPolicyId
     * @param requestBody
     * @returns ReimbursementPolicy Successful Response
     * @throws ApiError
     */
    public static addOfficersToReimbursementPolicyApiV1ReimbursementAddOfficersPost(
        reimbursementPolicyId: string,
        requestBody: Array<string>,
    ): CancelablePromise<Array<ReimbursementPolicy>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/reimbursement/add_officers',
            query: {
                'reimbursement_policy_id': reimbursementPolicyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Officers From Reimbursement Policy
     * @param reimbursementPolicyId
     * @param requestBody
     * @returns ReimbursementPolicy Successful Response
     * @throws ApiError
     */
    public static removeOfficersFromReimbursementPolicyApiV1ReimbursementRemoveOfficersDelete(
        reimbursementPolicyId: string,
        requestBody: Array<string>,
    ): CancelablePromise<Array<ReimbursementPolicy>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/reimbursement/remove_officers',
            query: {
                'reimbursement_policy_id': reimbursementPolicyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
