/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum UserNotificationTypes {
    EXPIRED_LICENSES = 'EXPIRED_LICENSES',
    EXPIRED_CERTIFICATIONS = 'EXPIRED_CERTIFICATIONS',
    EXPIRING_CERTIFICATIONS = 'EXPIRING_CERTIFICATIONS',
    SHIFT_OFFER = 'SHIFT_OFFER',
    SHIFT_OFFER_ADMIN = 'SHIFT_OFFER_ADMIN',
    CLOCKED_OUT_EARLY = 'CLOCKED_OUT_EARLY',
    TIME_OFF_REQUESTED = 'TIME_OFF_REQUESTED',
    TIME_OFF_REQUEST_REJECTED = 'TIME_OFF_REQUEST_REJECTED',
    ABANDONED_POST = 'ABANDONED_POST',
    MISSING_CLOCK_IN = 'MISSING_CLOCK_IN',
    CURRENT_SHIFT = 'CURRENT_SHIFT',
    CONTRACT_MANAGEMENT = 'CONTRACT_MANAGEMENT',
    NO_RECENT_LOCATION = 'NO_RECENT_LOCATION',
    NEW_INCIDENT = 'NEW_INCIDENT',
}
