/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CheckpointType {
    QR = 'QR',
    NFC = 'NFC',
    GEOFENCE = 'GEOFENCE',
    MANUAL = 'MANUAL',
}
