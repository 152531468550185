/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GrantedPolicyBehaviorType } from './GrantedPolicyBehaviorType';
import type { OfficerCategory } from './OfficerCategory';
import type { TimeOffPolicyIncreaseCreate } from './TimeOffPolicyIncreaseCreate';
import type { TimeOffPolicyIncreaseUpdate } from './TimeOffPolicyIncreaseUpdate';

export type TimeOffPolicyUpdate = {
    /**
     * Employer defined name for this policy.
     */
    policy_name: string;
    /**
     * Employer defined description for this policy.
     */
    policy_description?: string;
    /**
     * Type of policy: PTO, SICK, or UNPAID.
     */
    policy_type: TimeOffPolicyUpdate.policy_type;
    /**
     * Does the policy apply on a calendar basis or a work anniversary basis.
     */
    policy_structure: TimeOffPolicyUpdate.policy_structure;
    /**
     * If the policy time has to be accrued based on days/hours worked, if not then granted up front.
     */
    balance_structure_accrued: boolean;
    /**
     * Number of hours/days worked per policy hour/day granted. Or amount initially granted.
     */
    granted_or_accrual_rate?: number;
    /**
     * Max number of hours/days that carry over from policy periods. 0 if unset.
     */
    carryover_cap?: number;
    /**
     * Max number of hours/days that can be accrued. Unlimited if unset.
     */
    accrual_cap?: number;
    /**
     * Max number of hours/days that can be banked in total time off balance. Unlimited if unset.
     */
    max_cap?: number;
    /**
     * Number of days after hire before time off can be accrued or granted. If unset, no waiting period
     */
    waiting_period?: number;
    /**
     * Number of days after hire before time off can be requested. If unset, no request waiting period
     */
    usage_wait_period?: number;
    /**
     * States which employees within apply to. Applies to all if none.
     */
    applicable_states?: Array<'AL' | 'AK' | 'AZ' | 'AR' | 'CA' | 'CO' | 'CT' | 'DE' | 'FL' | 'GA' | 'HI' | 'ID' | 'IL' | 'IN' | 'IA' | 'KS' | 'KY' | 'LA' | 'ME' | 'MD' | 'MA' | 'MI' | 'MN' | 'MS' | 'MO' | 'MT' | 'NE' | 'NV' | 'NH' | 'NJ' | 'NM' | 'NY' | 'NC' | 'ND' | 'OH' | 'OK' | 'OR' | 'PA' | 'RI' | 'SC' | 'SD' | 'TN' | 'TX' | 'UT' | 'VT' | 'VA' | 'WA' | 'WV' | 'WI' | 'WY'>;
    /**
     * Employee classifications this policy applies to.
     */
    employee_types: Array<OfficerCategory>;
    /**
     * Start date for this policy to take effect, can not be edited after creation
     */
    effective_date: string;
    /**
     * Does this policy apply to hourly or salaried workers. Can not be edited after creation
     */
    applied_to_hourly: boolean;
    /**
     * Date for this policy to stop applying. Must be after its effective_date
     */
    end_date?: string;
    /**
     * If true, employees can request for time off using this policy on their own.
     */
    can_be_requested?: boolean;
    /**
     * If true, employees will be paid out unused paid time-off/sick when terminated.
     */
    should_pay_out_on_worker_termination?: boolean;
    /**
     * If pro-rate, only allocates a prorated amount to officers who become eligible for a granted policy part of the way into the time off period
     */
    prorate_granted_balance?: GrantedPolicyBehaviorType;
    /**
     * Set of increases to the rate of time off accrual.
     */
    policy_increases: Array<(TimeOffPolicyIncreaseUpdate | TimeOffPolicyIncreaseCreate)>;
};

export namespace TimeOffPolicyUpdate {

    /**
     * Type of policy: PTO, SICK, or UNPAID.
     */
    export enum policy_type {
        PTO = 'PTO',
        SICK = 'SICK',
        UNPAID = 'UNPAID',
    }

    /**
     * Does the policy apply on a calendar basis or a work anniversary basis.
     */
    export enum policy_structure {
        CALENDAR = 'CALENDAR',
        WORK = 'WORK',
    }


}

