/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BilledOTSetting } from './BilledOTSetting';

export type ContractUpdate = {
    date_signed?: string;
    date_expiry?: string;
    billing_frequency?: ContractUpdate.billing_frequency;
    invoice_reminders?: ContractUpdate.invoice_reminders;
    holiday_bill_rate_multiplier?: number;
    include_timesheet_data_as_attach?: boolean;
    is_billing_enabled?: boolean;
    billing_type_is_by_schedule?: boolean;
    timesheet_organization_type?: ContractUpdate.timesheet_organization_type;
    line_item_grouping?: ContractUpdate.line_item_grouping;
    net_pay_term?: number;
    sales_tax_on_sgs_percent?: number;
    billing_invoice_note?: string;
    billing_invoice_prefix?: string;
    billing_invoice_number_start?: number;
    payroll_site_id?: string;
    secondary_payroll_site_id?: string;
    gl_code?: string;
    billing_type?: ContractUpdate.billing_type;
    holiday_pay_rate_multiplier?: number;
    billed_ot_setting?: BilledOTSetting;
    billing_ot?: ContractUpdate.billing_ot;
    billing_ot_by_employee_accrual_at_cust_hours?: number;
    max_contracted_hours?: number;
    ot_bill_rate_multiplier?: number;
    bill_wage_based_daily_ot?: boolean;
    bill_wage_based_weekly_ot?: boolean;
    workweek_ot_hours_limit?: number;
    workweek_per_officer_limit?: number;
    required_certification_ids?: Array<string>;
    required_license_ids?: Array<string>;
};

export namespace ContractUpdate {

    export enum billing_frequency {
        WEEKLY = 'WEEKLY',
        BIWEEKLY = 'BIWEEKLY',
        MONTHLY = 'MONTHLY',
        ADHOC = 'ADHOC',
    }

    export enum invoice_reminders {
        NO = 'NO',
        WEEKLY_AFTER_DUE = 'WEEKLY_AFTER_DUE',
        WEEKLY_AFTER_SENT = 'WEEKLY_AFTER_SENT',
    }

    export enum timesheet_organization_type {
        NONE = 'NONE',
        SHIFT = 'SHIFT',
        SITE = 'SITE',
        BILL_RATE = 'BILL_RATE',
        SITE_BILL_RATE = 'SITE_BILL_RATE',
    }

    export enum line_item_grouping {
        BILL_RATE = 'BILL_RATE',
        SHIFT = 'SHIFT',
        DATE_BILL_RATE = 'DATE_BILL_RATE',
        POSITION = 'POSITION',
    }

    export enum billing_type {
        TIMECARD_APPROVAL = 'TIMECARD_APPROVAL',
        SCHEDULE = 'SCHEDULE',
        FLATRATE = 'FLATRATE',
    }

    export enum billing_ot {
        NO = 'NO',
        ABOVE_CONTRACT_MAX = 'ABOVE_CONTRACT_MAX',
    }


}

