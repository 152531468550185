/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ActivityType } from './ActivityType';

export type BreakOutUpdate = {
    activity_type?: ActivityType;
    description?: string;
    occurred_at?: string;
    title: string;
    location?: string;
    activity_type_literal?: BreakOutUpdate.activity_type_literal;
};

export namespace BreakOutUpdate {

    export enum activity_type_literal {
        BREAK_OUT = 'BREAK_OUT',
    }


}

