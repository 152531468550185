/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ReportTypes {
    INCIDENT_REPORT = 'INCIDENT_REPORT',
    CUSTOM_REPORT = 'CUSTOM_REPORT',
    ACTIVITY_REPORT = 'ACTIVITY_REPORT',
    CONSOLIDATED_ACTIVITY_REPORT = 'CONSOLIDATED_ACTIVITY_REPORT',
}
