/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PaymentUpdate = {
    amount_cents?: number;
    memo?: string;
    occurred_at?: (string | null);
    posted_at?: (string | null);
    method?: PaymentUpdate.method;
    status?: string;
    batch_number?: (string | null);
    vendor_id?: (string | null);
};

export namespace PaymentUpdate {

    export enum method {
        CREDIT_CARD = 'Credit Card',
        ACH = 'ACH',
    }


}

