/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ActivityType {
    ACTIVITY_REPORT_FILED = 'ACTIVITY_REPORT_FILED',
    BREAK_IN = 'BREAK_IN',
    BREAK_OUT = 'BREAK_OUT',
    CHECK_IN = 'CHECK_IN',
    CHECK_OUT = 'CHECK_OUT',
    CLOCK_IN = 'CLOCK_IN',
    CLOCK_OUT = 'CLOCK_OUT',
    CUSTOM_REPORT_FILED = 'CUSTOM_REPORT_FILED',
    INCIDENT_REPORT_FILED = 'INCIDENT_REPORT_FILED',
    TOUR_STARTED = 'TOUR_STARTED',
    CHECKPOINT_SCANNED = 'CHECKPOINT_SCANNED',
    TOUR_ENDED = 'TOUR_ENDED',
    POST_ORDER_ACKNOWLEDGED = 'POST_ORDER_ACKNOWLEDGED',
}
