import { useMemo } from 'react'
import { useLocalStorage } from 'src/hooks'

type VisitState = {
    visitId: string | null
}
export const useFirmVisitationState = (onChange?: () => void) => {
    const { value: visitState, setValue: setVisitState } =
        useLocalStorage<VisitState>('belfry-visit', onChange)

    return useMemo(() => {
        const visitId = visitState?.visitId || null
        return {
            visitId,
            visitActive: !!visitId,
            setVisitId: (id: string | null) => {
                setVisitState({
                    ...(visitState || { visitId: id }),
                    visitId: id,
                })
                setTimeout(() => {
                    window.location.replace('/')
                })
            },
        }
    }, [visitState, setVisitState])
}
