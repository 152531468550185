/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AccountsPayableInvoiceUpdate = {
    amount_due_cents?: number;
    amount_paid_cents?: number;
    date_archived?: (string | null);
    date_due?: string;
    date_issued?: string;
    date_paid?: (string | null);
    date_posted?: (string | null);
    invoice_number?: (string | null);
    status?: AccountsPayableInvoiceUpdate.status;
    invoice_description?: string;
    date_service_end?: (string | null);
    date_service_start?: (string | null);
    vendor_id?: string;
    file_id?: string;
    firm_id?: string;
    line_items?: Array<any>;
};

export namespace AccountsPayableInvoiceUpdate {

    export enum status {
        DRAFT = 'Draft',
        DELETED = 'Deleted',
        OPEN = 'Open',
        VOID = 'Void',
        UNCOLLECTIBLE = 'Uncollectible',
        UNCOLLECTIBLE_PAID = 'Uncollectible Paid',
        SHORT_PAID = 'Short Paid',
        PAID = 'Paid',
    }


}

