/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DispatchStatus {
    CANCELLED = 'CANCELLED',
    OFFERED = 'OFFERED',
    ASSIGNED = 'ASSIGNED',
    ACCEPTED = 'ACCEPTED',
    ON_THE_WAY = 'ON_THE_WAY',
    ONSITE = 'ONSITE',
    CLEARED = 'CLEARED',
    COMPLETED = 'COMPLETED',
    CLOSED = 'CLOSED',
}
