/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Task = {
    id: string;
    type: Task.type;
    estimated_duration?: number;
    customer_id?: string;
    custom_report_template_id?: string;
    instructions?: string;
    task_details?: string;
};

export namespace Task {

    export enum type {
        REPORT = 'Report',
        SITE_VISIT = 'Site Visit',
        ADMINISTRATIVE = 'Administrative',
    }


}

