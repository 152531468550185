/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BasicCustomer } from '../models/BasicCustomer';
import type { FirmInfoForClientPortal } from '../models/FirmInfoForClientPortal';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FirmInfoService {

    /**
     * Get Firm Info
     * @returns FirmInfoForClientPortal Successful Response
     * @throws ApiError
     */
    public static getFirmInfoApiV1ClientPortalFirmInfoGet(): CancelablePromise<FirmInfoForClientPortal> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/client_portal/firm_info',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Client Customers
     * @returns BasicCustomer Successful Response
     * @throws ApiError
     */
    public static getClientCustomersApiV1ClientPortalCustomersGet(): CancelablePromise<Array<BasicCustomer>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/client_portal/customers',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
