/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represent possible actions to be taken for the firm on the
 * payment acceptance configuration.
 */
export enum PaymentSettingCommand {
    ENABLE_ALL = 'Enable All',
    DISABLE_ALL = 'Disable All',
}
