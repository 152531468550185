/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FileUpdate } from './FileUpdate';

export type OnboardingDocumentUpdate = {
    firm_id?: string;
    requires_employee_acknowledgement?: boolean;
    share_group?: OnboardingDocumentUpdate.share_group;
    share_group_role_ids?: Array<string>;
    id: string;
    file_in?: FileUpdate;
    share_group_employee_ids?: Array<string>;
};

export namespace OnboardingDocumentUpdate {

    export enum share_group {
        ALL = 'ALL',
        ROLE = 'ROLE',
        INDIVIDUAL = 'INDIVIDUAL',
        NONE = 'NONE',
    }


}

