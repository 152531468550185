/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuditRecordCustomReportTemplate } from '../models/AuditRecordCustomReportTemplate';
import type { CustomReportTemplate } from '../models/CustomReportTemplate';
import type { CustomReportTemplateCreate } from '../models/CustomReportTemplateCreate';
import type { CustomReportTemplateUpdate } from '../models/CustomReportTemplateUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomReportTemplateService {

    /**
     * Read Custom Report Templates
     * @returns CustomReportTemplate Successful Response
     * @throws ApiError
     */
    public static readCustomReportTemplatesApiV1CustomReportTemplatesGet(): CancelablePromise<Array<CustomReportTemplate>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/custom-report-templates',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Custom Report Template
     * @param requestBody
     * @returns CustomReportTemplate Successful Response
     * @throws ApiError
     */
    public static createCustomReportTemplateApiV1CustomReportTemplatesPost(
        requestBody: CustomReportTemplateCreate,
    ): CancelablePromise<CustomReportTemplate> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/custom-report-templates',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Custom Report Templates For Customer
     * @param customerId
     * @returns CustomReportTemplate Successful Response
     * @throws ApiError
     */
    public static readCustomReportTemplatesForCustomerApiV1CustomReportTemplatesCustomerCustomerIdGet(
        customerId: string,
    ): CancelablePromise<Array<CustomReportTemplate>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/custom-report-templates/customer/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Custom Report Templates For Shift Instance
     * @param shiftInstanceId
     * @returns CustomReportTemplate Successful Response
     * @throws ApiError
     */
    public static readCustomReportTemplatesForShiftInstanceApiV1CustomReportTemplatesShiftShiftInstanceIdGet(
        shiftInstanceId: string,
    ): CancelablePromise<Array<CustomReportTemplate>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/custom-report-templates/shift/{shift_instance_id}',
            path: {
                'shift_instance_id': shiftInstanceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Custom Report Template
     * @param id
     * @returns CustomReportTemplate Successful Response
     * @throws ApiError
     */
    public static readCustomReportTemplateApiV1CustomReportTemplatesIdGet(
        id: string,
    ): CancelablePromise<CustomReportTemplate> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/custom-report-templates/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Custom Report Template
     * @param id
     * @param requestBody
     * @returns CustomReportTemplate Successful Response
     * @throws ApiError
     */
    public static updateCustomReportTemplateApiV1CustomReportTemplatesIdPut(
        id: string,
        requestBody: CustomReportTemplateUpdate,
    ): CancelablePromise<CustomReportTemplate> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/custom-report-templates/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Custom Report Template
     * @param id
     * @returns CustomReportTemplate Successful Response
     * @throws ApiError
     */
    public static deleteCustomReportTemplateApiV1CustomReportTemplatesIdDelete(
        id: string,
    ): CancelablePromise<CustomReportTemplate> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/custom-report-templates/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Duplicate Custom Report Template
     * @param customReportTemplateId
     * @returns CustomReportTemplate Successful Response
     * @throws ApiError
     */
    public static duplicateCustomReportTemplateApiV1DuplicateCustomReportTemplatesPost(
        customReportTemplateId: string,
    ): CancelablePromise<CustomReportTemplate> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/duplicate-custom-report-templates',
            query: {
                'custom_report_template_id': customReportTemplateId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Custom Report Template For Audit Record
     * @param customReportTemplateId
     * @returns AuditRecordCustomReportTemplate Successful Response
     * @throws ApiError
     */
    public static readCustomReportTemplateForAuditRecordApiV1CustomReportTemplateAuditRecordCustomReportTemplateIdGet(
        customReportTemplateId: string,
    ): CancelablePromise<AuditRecordCustomReportTemplate> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/custom-report-template-audit-record/{custom_report_template_id}',
            path: {
                'custom_report_template_id': customReportTemplateId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
