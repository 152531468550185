/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BasicCustomer } from '../models/BasicCustomer';
import type { Schedule } from '../models/Schedule';
import type { ShiftInstanceForSchedule } from '../models/ShiftInstanceForSchedule';
import type { ShiftInstanceUpdate } from '../models/ShiftInstanceUpdate';
import type { ShiftSchedule } from '../models/ShiftSchedule';
import type { ShiftSplit } from '../models/ShiftSplit';
import type { TimekeepingShiftInstance } from '../models/TimekeepingShiftInstance';
import type { TimekeepingUpdateResponse } from '../models/TimekeepingUpdateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ScheduleService {

    /**
     * Get Schedule For Date Range
     * @param startDate
     * @param endDate
     * @param actualTimes
     * @returns Schedule Successful Response
     * @throws ApiError
     */
    public static getScheduleForDateRangeApiV1ScheduleGet(
        startDate: string,
        endDate: string,
        actualTimes: boolean = false,
    ): CancelablePromise<Schedule> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/schedule',
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'actual_times': actualTimes,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Shift Instance Details
     * @param shiftInstanceId
     * @returns ShiftInstanceForSchedule Successful Response
     * @throws ApiError
     */
    public static getShiftInstanceDetailsApiV1ScheduleDetailsShiftInstanceIdGet(
        shiftInstanceId: string,
    ): CancelablePromise<ShiftInstanceForSchedule> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/schedule-details/{shift_instance_id}',
            path: {
                'shift_instance_id': shiftInstanceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Schedule Customers For Date Range
     * @param startDate
     * @param endDate
     * @returns BasicCustomer Successful Response
     * @throws ApiError
     */
    public static getScheduleCustomersForDateRangeApiV1ScheduleCustomersGet(
        startDate: string,
        endDate: string,
    ): CancelablePromise<Array<BasicCustomer>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/schedule/customers',
            query: {
                'start_date': startDate,
                'end_date': endDate,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Schedule Export
     * @param startDate
     * @param endDate
     * @param format
     * @param customerIds
     * @param officerIds
     * @param omitContactInfo
     * @param omitStatus
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getScheduleExportApiV1ScheduleExportGet(
        startDate: string,
        endDate: string,
        format?: ('csv' | 'pdf' | null),
        customerIds?: (Array<string> | null),
        officerIds?: (Array<string> | null),
        omitContactInfo?: (boolean | null),
        omitStatus?: (boolean | null),
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/schedule/export',
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'format': format,
                'customer_ids': customerIds,
                'officer_ids': officerIds,
                'omit_contact_info': omitContactInfo,
                'omit_status': omitStatus,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Shift Instance
     * @param startDate
     * @param endDate
     * @param requestBody
     * @param actualTimes
     * @returns Schedule Successful Response
     * @throws ApiError
     */
    public static setShiftInstanceApiV2ScheduleInstancePut(
        startDate: string,
        endDate: string,
        requestBody: ShiftInstanceUpdate,
        actualTimes: boolean = false,
    ): CancelablePromise<Schedule> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v2/schedule/instance',
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'actual_times': actualTimes,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Shift Instances
     * @param requestBody
     * @returns Schedule Successful Response
     * @throws ApiError
     */
    public static deleteShiftInstancesApiV2ScheduleInstancesDelete(
        requestBody: Array<string>,
    ): CancelablePromise<Schedule> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v2/schedule/instances',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Scheduled Officers
     * @param startDate
     * @param endDate
     * @param requestBody
     * @param actualTimes
     * @returns Schedule Successful Response
     * @throws ApiError
     */
    public static setScheduledOfficersApiV2SchedulePut(
        startDate: string,
        endDate: string,
        requestBody: Array<ShiftInstanceUpdate>,
        actualTimes: boolean = false,
    ): CancelablePromise<Schedule> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v2/schedule',
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'actual_times': actualTimes,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Bulk Set Instance Details
     * @param startDate
     * @param endDate
     * @param requestBody
     * @param actualTimes
     * @returns Schedule Successful Response
     * @throws ApiError
     */
    public static bulkSetInstanceDetailsApiV2BulkSchedulePut(
        startDate: string,
        endDate: string,
        requestBody: Array<ShiftInstanceUpdate>,
        actualTimes: boolean = false,
    ): CancelablePromise<Schedule> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v2/bulk-schedule',
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'actual_times': actualTimes,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Split Shift
     * @param shiftId
     * @param targetDate
     * @param index
     * @param startDate
     * @param endDate
     * @param requestBody
     * @param actualTimes
     * @returns Schedule Successful Response
     * @throws ApiError
     */
    public static splitShiftApiV2ScheduleInstanceShiftIdTargetDateIndexPut(
        shiftId: string,
        targetDate: string,
        index: number,
        startDate: string,
        endDate: string,
        requestBody: Array<ShiftSplit>,
        actualTimes: boolean = false,
    ): CancelablePromise<Schedule> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v2/schedule/instance/{shift_id}/{target_date}/{index}',
            path: {
                'shift_id': shiftId,
                'target_date': targetDate,
                'index': index,
            },
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'actual_times': actualTimes,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Shift Instance Details
     * @param shiftInstanceId
     * @returns ShiftInstanceForSchedule Successful Response
     * @throws ApiError
     */
    public static getShiftInstanceDetailsApiV2ScheduleDetailsShiftInstanceIdGet(
        shiftInstanceId: string,
    ): CancelablePromise<ShiftInstanceForSchedule> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/schedule-details/{shift_instance_id}',
            path: {
                'shift_instance_id': shiftInstanceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Schedule For Date Range
     * @param startDate
     * @param endDate
     * @param actualTimes
     * @param officerId
     * @returns ShiftSchedule Successful Response
     * @throws ApiError
     */
    public static getScheduleForDateRangeApiV3ScheduleGet(
        startDate: string,
        endDate: string,
        actualTimes: boolean = false,
        officerId?: (string | null),
    ): CancelablePromise<ShiftSchedule> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/schedule',
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'actual_times': actualTimes,
                'officer_id': officerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Scheduled Officers
     * @param startDate
     * @param endDate
     * @param requestBody
     * @param actualTimes
     * @returns ShiftSchedule Successful Response
     * @throws ApiError
     */
    public static setScheduledOfficersApiV3SchedulePut(
        startDate: string,
        endDate: string,
        requestBody: Array<ShiftInstanceUpdate>,
        actualTimes: boolean = false,
    ): CancelablePromise<ShiftSchedule> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v3/schedule',
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'actual_times': actualTimes,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Shift Instance
     * @param startDate
     * @param endDate
     * @param requestBody
     * @param actualTimes
     * @returns ShiftSchedule Successful Response
     * @throws ApiError
     */
    public static setShiftInstanceApiV3ScheduleInstancePut(
        startDate: string,
        endDate: string,
        requestBody: ShiftInstanceUpdate,
        actualTimes: boolean = false,
    ): CancelablePromise<ShiftSchedule> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v3/schedule/instance',
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'actual_times': actualTimes,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Shift Instance Ids
     * @param startDate
     * @param endDate
     * @param requestBody
     * @param actualTimes
     * @returns ShiftSchedule Successful Response
     * @throws ApiError
     */
    public static deleteShiftInstanceIdsApiV3ScheduleInstancesDelete(
        startDate: string,
        endDate: string,
        requestBody: Array<string>,
        actualTimes: boolean = false,
    ): CancelablePromise<ShiftSchedule> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v3/schedule/instances',
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'actual_times': actualTimes,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Bulk Set Instance Details
     * @param startDate
     * @param endDate
     * @param requestBody
     * @param actualTimes
     * @returns ShiftSchedule Successful Response
     * @throws ApiError
     */
    public static bulkSetInstanceDetailsApiV3BulkSchedulePut(
        startDate: string,
        endDate: string,
        requestBody: Array<ShiftInstanceUpdate>,
        actualTimes: boolean = false,
    ): CancelablePromise<ShiftSchedule> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v3/bulk-schedule',
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'actual_times': actualTimes,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Split Shift
     * @param shiftId
     * @param targetDate
     * @param index
     * @param startDate
     * @param endDate
     * @param requestBody
     * @param actualTimes
     * @returns ShiftSchedule Successful Response
     * @throws ApiError
     */
    public static splitShiftApiV3ScheduleInstanceShiftIdTargetDateIndexPut(
        shiftId: string,
        targetDate: string,
        index: number,
        startDate: string,
        endDate: string,
        requestBody: Array<ShiftSplit>,
        actualTimes: boolean = false,
    ): CancelablePromise<ShiftSchedule> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v3/schedule/instance/{shift_id}/{target_date}/{index}',
            path: {
                'shift_id': shiftId,
                'target_date': targetDate,
                'index': index,
            },
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'actual_times': actualTimes,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Shift Instance Details
     * @param shiftInstanceId
     * @returns ShiftInstanceForSchedule Successful Response
     * @throws ApiError
     */
    public static getShiftInstanceDetailsApiV3ScheduleDetailsShiftInstanceIdGet(
        shiftInstanceId: string,
    ): CancelablePromise<ShiftInstanceForSchedule> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/schedule-details/{shift_instance_id}',
            path: {
                'shift_instance_id': shiftInstanceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Schedule Customers For Date Range
     * @param startDate
     * @param endDate
     * @returns BasicCustomer Successful Response
     * @throws ApiError
     */
    public static getScheduleCustomersForDateRangeApiV3ScheduleCustomersGet(
        startDate: string,
        endDate: string,
    ): CancelablePromise<Array<BasicCustomer>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/schedule/customers',
            query: {
                'start_date': startDate,
                'end_date': endDate,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Schedule Export
     * @param startDate
     * @param endDate
     * @param format
     * @param customerIds
     * @param officerIds
     * @param omitContactInfo
     * @param omitStatus
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getScheduleExportApiV3ScheduleExportGet(
        startDate: string,
        endDate: string,
        format?: ('csv' | 'pdf' | null),
        customerIds?: (Array<string> | null),
        officerIds?: (Array<string> | null),
        omitContactInfo?: (boolean | null),
        omitStatus?: (boolean | null),
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/schedule/export',
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'format': format,
                'customer_ids': customerIds,
                'officer_ids': officerIds,
                'omit_contact_info': omitContactInfo,
                'omit_status': omitStatus,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Schedule For Date Range
     * @param startDate
     * @param endDate
     * @param officerId
     * @returns TimekeepingShiftInstance Successful Response
     * @throws ApiError
     */
    public static getScheduleForDateRangeApiV4ScheduleGet(
        startDate: string,
        endDate: string,
        officerId?: (string | null),
    ): CancelablePromise<Array<TimekeepingShiftInstance>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v4/schedule',
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'officer_id': officerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Scheduled Officers
     * @param startDate
     * @param endDate
     * @param requestBody
     * @returns TimekeepingShiftInstance Successful Response
     * @throws ApiError
     */
    public static setScheduledOfficersApiV4SchedulePut(
        startDate: string,
        endDate: string,
        requestBody: Array<ShiftInstanceUpdate>,
    ): CancelablePromise<Array<TimekeepingShiftInstance>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v4/schedule',
            query: {
                'start_date': startDate,
                'end_date': endDate,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Shift Instance
     * @param startDate
     * @param endDate
     * @param requestBody
     * @returns TimekeepingUpdateResponse Successful Response
     * @throws ApiError
     */
    public static setShiftInstanceApiV4ScheduleInstancePut(
        startDate: string,
        endDate: string,
        requestBody: ShiftInstanceUpdate,
    ): CancelablePromise<TimekeepingUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v4/schedule/instance',
            query: {
                'start_date': startDate,
                'end_date': endDate,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Shift Instance Ids
     * @param startDate
     * @param endDate
     * @param requestBody
     * @returns TimekeepingShiftInstance Successful Response
     * @throws ApiError
     */
    public static deleteShiftInstanceIdsApiV4ScheduleInstancesDelete(
        startDate: string,
        endDate: string,
        requestBody: Array<string>,
    ): CancelablePromise<Array<TimekeepingShiftInstance>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v4/schedule/instances',
            query: {
                'start_date': startDate,
                'end_date': endDate,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Bulk Set Instance Details
     * @param startDate
     * @param endDate
     * @param requestBody
     * @returns TimekeepingShiftInstance Successful Response
     * @throws ApiError
     */
    public static bulkSetInstanceDetailsApiV4BulkSchedulePut(
        startDate: string,
        endDate: string,
        requestBody: Array<ShiftInstanceUpdate>,
    ): CancelablePromise<Array<TimekeepingShiftInstance>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v4/bulk-schedule',
            query: {
                'start_date': startDate,
                'end_date': endDate,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Split Shift
     * @param shiftId
     * @param targetDate
     * @param index
     * @param startDate
     * @param endDate
     * @param requestBody
     * @returns TimekeepingUpdateResponse Successful Response
     * @throws ApiError
     */
    public static splitShiftApiV4ScheduleInstanceShiftIdTargetDateIndexPut(
        shiftId: string,
        targetDate: string,
        index: number,
        startDate: string,
        endDate: string,
        requestBody: Array<ShiftSplit>,
    ): CancelablePromise<TimekeepingUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v4/schedule/instance/{shift_id}/{target_date}/{index}',
            path: {
                'shift_id': shiftId,
                'target_date': targetDate,
                'index': index,
            },
            query: {
                'start_date': startDate,
                'end_date': endDate,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Schedule Export
     * @param startDate
     * @param endDate
     * @param format
     * @param customerIds
     * @param officerIds
     * @param omitContactInfo
     * @param omitStatus
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getScheduleExportApiV4ScheduleExportGet(
        startDate: string,
        endDate: string,
        format?: ('csv' | 'pdf' | null),
        customerIds?: (Array<string> | null),
        officerIds?: (Array<string> | null),
        omitContactInfo?: (boolean | null),
        omitStatus?: (boolean | null),
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v4/schedule/export',
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'format': format,
                'customer_ids': customerIds,
                'officer_ids': officerIds,
                'omit_contact_info': omitContactInfo,
                'omit_status': omitStatus,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
