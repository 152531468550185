/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_create_daily_time_off_api_v1_time_off_daily_post } from '../models/Body_create_daily_time_off_api_v1_time_off_daily_post';
import type { Body_create_daily_time_off_multi_api_v1_time_off_daily_multi_post } from '../models/Body_create_daily_time_off_multi_api_v1_time_off_daily_multi_post';
import type { Body_create_hourly_time_off_api_v1_time_off_hourly_post } from '../models/Body_create_hourly_time_off_api_v1_time_off_hourly_post';
import type { Body_create_hourly_time_off_multi_api_v1_time_off_hourly_multi_post } from '../models/Body_create_hourly_time_off_multi_api_v1_time_off_hourly_multi_post';
import type { Body_update_daily_time_off_api_v1_time_off_daily__time_off_id__update_post } from '../models/Body_update_daily_time_off_api_v1_time_off_daily__time_off_id__update_post';
import type { Body_update_hourly_time_off_api_v1_time_off_hourly__time_off_id__update_post } from '../models/Body_update_hourly_time_off_api_v1_time_off_hourly__time_off_id__update_post';
import type { TimeOff } from '../models/TimeOff';
import type { TimeOffAdjustment } from '../models/TimeOffAdjustment';
import type { TimeOffAdjustmentCreate } from '../models/TimeOffAdjustmentCreate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TimeOffService {

    /**
     * Create Hourly Time Off Multi
     * Creates multiple new time off objects and returns a list of all of the time off object based on the return form.
     * @param requestBody
     * @returns TimeOff Successful Response
     * @throws ApiError
     */
    public static createHourlyTimeOffMultiApiV1TimeOffHourlyMultiPost(
        requestBody: Body_create_hourly_time_off_multi_api_v1_time_off_hourly_multi_post,
    ): CancelablePromise<Array<TimeOff>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/time_off_hourly_multi',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Hourly Time Off
     * Creates a new time off object and returns a list of all of the time off object based on the return form.
     * @param requestBody
     * @returns TimeOff Successful Response
     * @throws ApiError
     */
    public static createHourlyTimeOffApiV1TimeOffHourlyPost(
        requestBody: Body_create_hourly_time_off_api_v1_time_off_hourly_post,
    ): CancelablePromise<Array<TimeOff>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/time_off_hourly',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Daily Time Off Multi
     * Creates multiple new time off entry for a daily policy and returns a list of all of the time off object based on the return form.
     * @param requestBody
     * @returns TimeOff Successful Response
     * @throws ApiError
     */
    public static createDailyTimeOffMultiApiV1TimeOffDailyMultiPost(
        requestBody: Body_create_daily_time_off_multi_api_v1_time_off_daily_multi_post,
    ): CancelablePromise<Array<TimeOff>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/time_off_daily_multi',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Daily Time Off
     * Creates a new time off entry for a daily policy and returns a list of all of the time off object based on the return form.
     * @param requestBody
     * @returns TimeOff Successful Response
     * @throws ApiError
     */
    public static createDailyTimeOffApiV1TimeOffDailyPost(
        requestBody: Body_create_daily_time_off_api_v1_time_off_daily_post,
    ): CancelablePromise<Array<TimeOff>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/time_off_daily',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Hourly Time Off
     * @param timeOffId
     * @param requestBody
     * @returns TimeOff Successful Response
     * @throws ApiError
     */
    public static updateHourlyTimeOffApiV1TimeOffHourlyTimeOffIdUpdatePost(
        timeOffId: string,
        requestBody: Body_update_hourly_time_off_api_v1_time_off_hourly__time_off_id__update_post,
    ): CancelablePromise<Array<TimeOff>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/time_off_hourly/{time_off_id}/update',
            path: {
                'time_off_id': timeOffId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Daily Time Off
     * @param timeOffId
     * @param requestBody
     * @returns TimeOff Successful Response
     * @throws ApiError
     */
    public static updateDailyTimeOffApiV1TimeOffDailyTimeOffIdUpdatePost(
        timeOffId: string,
        requestBody: Body_update_daily_time_off_api_v1_time_off_daily__time_off_id__update_post,
    ): CancelablePromise<Array<TimeOff>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/time_off_daily/{time_off_id}/update',
            path: {
                'time_off_id': timeOffId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Upcoming Time Off
     * Creates a new time off object and returns a list of all of the time off object based on the return form.
     * @returns TimeOff Successful Response
     * @throws ApiError
     */
    public static getUpcomingTimeOffApiV1TimeOffUpcomingGet(): CancelablePromise<Array<TimeOff>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/time_off/upcoming',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Past Time Off
     * Creates a new time off object and returns a list of all of the time off object based on the return form.
     * @returns TimeOff Successful Response
     * @throws ApiError
     */
    public static getPastTimeOffApiV1TimeOffPastGet(): CancelablePromise<Array<TimeOff>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/time_off/past',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Time Off For Dates
     * Return time off falling within this time range
     * @param startDate
     * @param endDate
     * @param payGroupId
     * @returns TimeOff Successful Response
     * @throws ApiError
     */
    public static getTimeOffForDatesApiV1TimeOffRangeGet(
        startDate: string,
        endDate: string,
        payGroupId?: (string | null),
    ): CancelablePromise<Array<TimeOff>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/time_off/range',
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'pay_group_id': payGroupId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Time Off For Dates And Employee
     * Return time off falling within this time range
     * @param employeeId
     * @param startDate
     * @param endDate
     * @param payGroupId
     * @returns TimeOff Successful Response
     * @throws ApiError
     */
    public static getTimeOffForDatesAndEmployeeApiV1TimeOffRangeEmployeeEmployeeIdGet(
        employeeId: string,
        startDate: string,
        endDate: string,
        payGroupId?: (string | null),
    ): CancelablePromise<Array<TimeOff>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/time_off/range/employee/{employee_id}',
            path: {
                'employee_id': employeeId,
            },
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'pay_group_id': payGroupId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Time Off For Employee
     * @param employeeId
     * @returns TimeOff Successful Response
     * @throws ApiError
     */
    public static getTimeOffForEmployeeApiV1TimeOffEmployeeIdGet(
        employeeId: string,
    ): CancelablePromise<Array<TimeOff>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/time_off/{employee_id}',
            path: {
                'employee_id': employeeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Time Off For Employee Balance
     * @param employeeId
     * @param policyId
     * @returns number Successful Response
     * @throws ApiError
     */
    public static getTimeOffForEmployeeBalanceApiV1TimeOffEmployeeIdPolicyIdBalanceGet(
        employeeId: string,
        policyId: string,
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/time_off/{employee_id}/{policy_id}/balance',
            path: {
                'employee_id': employeeId,
                'policy_id': policyId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Time Off
     * @param timeOffId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static deleteTimeOffApiV1TimeOffTimeOffIdDelete(
        timeOffId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/time_off/{time_off_id}',
            path: {
                'time_off_id': timeOffId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Time Off Adjustment
     * Creates a new time off adjustment and returns it.
     * @param requestBody
     * @returns TimeOffAdjustment Successful Response
     * @throws ApiError
     */
    public static createTimeOffAdjustmentApiV1TimeOffAdjustmentPost(
        requestBody: TimeOffAdjustmentCreate,
    ): CancelablePromise<TimeOffAdjustment> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/time_off_adjustment',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Time Offs For Employee And Policy
     * @param policyId
     * @param employeeId
     * @returns TimeOff Successful Response
     * @throws ApiError
     */
    public static getTimeOffsForEmployeeAndPolicyApiV1TimeOffsPolicyIdEmployeeEmployeeIdGet(
        policyId: string,
        employeeId: string,
    ): CancelablePromise<Array<TimeOff>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/time_offs/{policy_id}/employee/{employee_id}',
            path: {
                'policy_id': policyId,
                'employee_id': employeeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
