/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DisplayableSubActivity } from './DisplayableSubActivity';

export type DisplayableActivityDetails = {
    header?: string;
    description?: string;
    additional_description?: string;
    sub_activities?: Array<DisplayableSubActivity>;
    type?: DisplayableActivityDetails.type;
};

export namespace DisplayableActivityDetails {

    export enum type {
        TOUR_SUMMARY = 'TOUR_SUMMARY',
        CUSTOM_REPORT = 'CUSTOM_REPORT',
        ACTIVITY_REPORT = 'ACTIVITY_REPORT',
        OTHER = 'OTHER',
    }


}

