/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuditRecordRunsheetTemplate } from '../models/AuditRecordRunsheetTemplate';
import type { RunsheetTemplate } from '../models/RunsheetTemplate';
import type { RunsheetTemplateCreate } from '../models/RunsheetTemplateCreate';
import type { RunsheetTemplateUpdate } from '../models/RunsheetTemplateUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RunsheetTemplateService {

    /**
     * Read Runsheet Template Audit Record
     * @param runsheetTemplateId
     * @returns AuditRecordRunsheetTemplate Successful Response
     * @throws ApiError
     */
    public static readRunsheetTemplateAuditRecordApiV1AuditRecordRunsheetTemplatesRunsheetTemplateIdGet(
        runsheetTemplateId: string,
    ): CancelablePromise<AuditRecordRunsheetTemplate> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/audit-record-runsheet_templates/{runsheet_template_id}',
            path: {
                'runsheet_template_id': runsheetTemplateId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Runsheet Templates For Firm
     * @returns RunsheetTemplate Successful Response
     * @throws ApiError
     */
    public static readRunsheetTemplatesForFirmApiV1RunsheetTemplatesGet(): CancelablePromise<Array<RunsheetTemplate>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/runsheet_templates',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Runsheet Templates For Customer
     * @param id
     * @returns RunsheetTemplate Successful Response
     * @throws ApiError
     */
    public static readRunsheetTemplatesForCustomerApiV1CustomersIdRunsheetTemplatesGet(
        id: string,
    ): CancelablePromise<Array<RunsheetTemplate>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/customers/{id}/runsheet_templates/',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Runsheet Template
     * @param id
     * @param requestBody
     * @returns RunsheetTemplate Successful Response
     * @throws ApiError
     */
    public static createRunsheetTemplateApiV1CustomerIdRunsheetTemplatePost(
        id: string,
        requestBody: RunsheetTemplateCreate,
    ): CancelablePromise<RunsheetTemplate> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/customer/{id}/runsheet_template',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Runsheet Template
     * @param runsheetTemplateId
     * @param requestBody
     * @returns RunsheetTemplate Successful Response
     * @throws ApiError
     */
    public static updateRunsheetTemplateApiV1RunsheetTemplateRunsheetTemplateIdPut(
        runsheetTemplateId: string,
        requestBody: RunsheetTemplateUpdate,
    ): CancelablePromise<RunsheetTemplate> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/runsheet_template/{runsheet_template_id}',
            path: {
                'runsheet_template_id': runsheetTemplateId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Runsheet Template
     * @param id
     * @param runsheetTemplateId
     * @returns RunsheetTemplate Successful Response
     * @throws ApiError
     */
    public static deleteRunsheetTemplateApiV1CustomerIdRunsheetTemplateRunsheetTemplateIdDelete(
        id: string,
        runsheetTemplateId: string,
    ): CancelablePromise<RunsheetTemplate> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/customer/{id}/runsheet-template/{runsheet_template_id}',
            path: {
                'id': id,
                'runsheet_template_id': runsheetTemplateId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
