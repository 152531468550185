/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { OnboardingDocument } from './OnboardingDocument';

export type OnboardingDocumentWithEmployeeAction = {
    document: OnboardingDocument;
    status?: OnboardingDocumentWithEmployeeAction.status;
    last_update_ts?: string;
    completed_ts?: string;
    share_method?: OnboardingDocumentWithEmployeeAction.share_method;
};

export namespace OnboardingDocumentWithEmployeeAction {

    export enum status {
        NOT_STARTED = 'NOT_STARTED',
        IN_PROGRESS = 'IN_PROGRESS',
        SUBMITTED = 'SUBMITTED',
        COMPLETE = 'COMPLETE',
        CANCELLED = 'CANCELLED',
    }

    export enum share_method {
        ONBOARDING = 'ONBOARDING',
        POST_ONBOARDING = 'POST_ONBOARDING',
    }


}

