/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { OfficerMetadata } from './OfficerMetadata';

export type TimeOffRequest = {
    /**
     * Officer note associated with the time off.
     */
    note?: string;
    /**
     * Note set by back office to be viewed by back office only.
     */
    note_for_back_office?: string;
    /**
     * Note set by back office to be viewed by officer.
     */
    note_for_officer?: string;
    id: string;
    /**
     * Requested time that the time off starts.
     */
    start_date_time: string;
    /**
     * Requested time that the time off ends.
     */
    end_date_time: string;
    /**
     * Date that the request was made.
     */
    created_at: string;
    /**
     * Employee taking the time off
     */
    employee: OfficerMetadata;
    /**
     * Number of minutes this time off counts towards as. Factors in overrides, policy type, etc.
     */
    minutes_for_time_off: number;
    /**
     * Type of policy: PTO, SICK, or UNPAID.
     */
    policy_type: TimeOffRequest.policy_type;
    /**
     * Does this time off apply to hourly or salaried workers.
     */
    applied_to_hourly: boolean;
    /**
     * Name of policy the time off is associated with.
     */
    policy_name: string;
    /**
     * ID of policy the time off is associated with.
     */
    policy_id: string;
    /**
     * If true, the time off details were edited by admin.
     */
    edited_by_back_office?: boolean;
    status: TimeOffRequest.status;
};

export namespace TimeOffRequest {

    /**
     * Type of policy: PTO, SICK, or UNPAID.
     */
    export enum policy_type {
        PTO = 'PTO',
        SICK = 'SICK',
        UNPAID = 'UNPAID',
    }

    export enum status {
        PENDING = 'PENDING',
        APPROVED = 'APPROVED',
        REJECTED = 'REJECTED',
        CANCELED = 'CANCELED',
    }


}

