/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NotificationSettings } from '../models/NotificationSettings';
import type { NotificationSettingsUpdate } from '../models/NotificationSettingsUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NotificationSettingsService {

    /**
     * Get Notification Settings For Firm
     * @returns NotificationSettings Successful Response
     * @throws ApiError
     */
    public static getNotificationSettingsForFirmApiV1NotificationSettingsGet(): CancelablePromise<NotificationSettings> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/notification_settings',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Notification Settings For Firm
     * @param requestBody
     * @returns NotificationSettings Successful Response
     * @throws ApiError
     */
    public static updateNotificationSettingsForFirmApiV1NotificationSettingsPut(
        requestBody: NotificationSettingsUpdate,
    ): CancelablePromise<NotificationSettings> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/notification_settings',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
