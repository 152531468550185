/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { OfficerJustPreferredScheduleUpdate } from './OfficerJustPreferredScheduleUpdate';

export type OnboardingAvailabilityActionUpdate = {
    status?: OnboardingAvailabilityActionUpdate.status;
    next_actor?: OnboardingAvailabilityActionUpdate.next_actor;
    form_type?: OnboardingAvailabilityActionUpdate.form_type;
    document_id?: string;
    preferred_schedule: OfficerJustPreferredScheduleUpdate;
};

export namespace OnboardingAvailabilityActionUpdate {

    export enum status {
        NOT_STARTED = 'NOT_STARTED',
        IN_PROGRESS = 'IN_PROGRESS',
        SUBMITTED = 'SUBMITTED',
        COMPLETE = 'COMPLETE',
        CANCELLED = 'CANCELLED',
    }

    export enum next_actor {
        EMPLOYEE = 'EMPLOYEE',
        EMPLOYER = 'EMPLOYER',
        EXTERNAL = 'EXTERNAL',
    }

    export enum form_type {
        I9 = 'I9',
        W4 = 'W4',
        WOTC = 'WOTC',
        PROFILE = 'PROFILE',
        AVAILABILITY = 'AVAILABILITY',
        EEO1 = 'EEO1',
    }


}

