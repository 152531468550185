/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_get_dispatches_api_v1_dispatch_get } from '../models/Body_get_dispatches_api_v1_dispatch_get';
import type { Dispatch } from '../models/Dispatch';
import type { DispatchCreate } from '../models/DispatchCreate';
import type { DispatchUpdate } from '../models/DispatchUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DispatchService {

    /**
     * Get Dispatches
     * @param requestBody
     * @returns Dispatch Successful Response
     * @throws ApiError
     */
    public static getDispatchesApiV1DispatchGet(
        requestBody?: Body_get_dispatches_api_v1_dispatch_get,
    ): CancelablePromise<Array<Dispatch>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/dispatch',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Dispatch
     * @param requestBody
     * @returns Dispatch Successful Response
     * @throws ApiError
     */
    public static createDispatchApiV1DispatchPost(
        requestBody: DispatchCreate,
    ): CancelablePromise<Dispatch> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/dispatch',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Active Dispatch
     * @returns Dispatch Successful Response
     * @throws ApiError
     */
    public static getActiveDispatchApiV1DispatchActiveGet(): CancelablePromise<Dispatch> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/dispatch/active',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Dispatch
     * @param id
     * @returns Dispatch Successful Response
     * @throws ApiError
     */
    public static getDispatchApiV1DispatchIdGet(
        id: string,
    ): CancelablePromise<Dispatch> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/dispatch/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Patch Dispatch
     * @param id
     * @param requestBody
     * @returns Dispatch Successful Response
     * @throws ApiError
     */
    public static patchDispatchApiV1DispatchIdPatch(
        id: string,
        requestBody: DispatchUpdate,
    ): CancelablePromise<Dispatch> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/dispatch/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
